import map from 'lodash/map';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import reject from 'lodash/reject';

import * as actions from '../actions/actionTypes';
import * as stores from '../../shared/stores';
import {updateAccountPersonaCount} from './accountActions';
import { logSearchIndex } from '../../shared/search'
import { asyncForEach } from '../../shared/utility';
import { getAffectedDiscussions } from './labelActions';
import { addActivity } from './activityActions';

export const createNewDemographicsDataStart = ( key ) => {
  return {
    type: actions.CREATE_NEW_DEMOGRAPHICS_DATA_START,
    selectKey: key
  }
}

export const createNewDemographicsDataSuccess = ( key, id, newVal ) => {
  return {
    type: actions.CREATE_NEW_DEMOGRAPHICS_DATA_SUCCESS,
    selectKey: key,
    id,
    newVal
  }
}

export const createNewDemographicsDataFail = ( key, error ) => {
  return {
    type: actions.CREATE_NEW_DEMOGRAPHICS_DATA_FAIL,
    error: error,
    selectKey: key
  }
}

export const createNewDemographicsData = newData => {
  return (dispatch, getState, {getFirestore}) => {
    dispatch(createNewDemographicsDataStart(newData.selectKey));
    const firestore = getFirestore();
    let db;
    const curAcct = newData.acctId;
    switch (newData.selectKey) {
      case 'relationshipStatus':
        db = `${curAcct}_relationshipStatuses`;
        break;
      case 'householdComposition':
        db = `${curAcct}_householdCompositions`;
        break;
      case 'highestLevelOfEducation':
        db = `${curAcct}_educationLevels`;
        break;
      case 'work':
        db = `${curAcct}_works`;
        break;
      case 'religion':
        db = `${curAcct}_religions`;
        break;
      default:
        db = `${curAcct}_relationshipStatuses`;
    }
    firestore.collection(db).add({
      accountId: newData.acctId,
      value: newData.value,
      label: newData.label
    }).then(docRef => {
      docRef.get()
      .then(doc => {
        dispatch(createNewDemographicsDataSuccess(newData.selectKey, doc.id, doc.data()));
      })
      .catch(err => { 
        dispatch(createNewDemographicsDataFail(newData.selectKey, err)); 
      })
    }).catch(err => {
      dispatch(createNewDemographicsDataFail(newData.selectKey, err));
    });
  }
}

export const savePersonaStart = () => {
  return {
    type: actions.SAVE_PERSONA_START,
  }
}

export const savePersonaSuccess = () => {
  return {
    type: actions.SAVE_PERSONA_SUCCESS,
  }
}

export const savePersonaFail = (error) => {
  return {
    type: actions.SAVE_PERSONA_FAIL,
    error: error,
  }
}

export const savePersona = (data) => {
  return async (dispatch, getState, {getFirestore}) => {
    dispatch(savePersonaStart());
    const firestore = getFirestore();
    const personasCol = `${data.currentAccountId}_personas`;
    let persona, originalPersona;
    if (data.personaId) {
      persona = firestore.collection(personasCol).doc(data.personaId)
      originalPersona = await persona.get().then(doc => ({...doc.data(), id: doc.id}))
    } else {
      persona = firestore.collection(personasCol).doc();
    }

    const personaData = {
      accountId: data.currentAccountId,
      name: data.step1.controls.name.value,
      description: data.step1.controls.description.value,
      pictureFilename: data.step1.picture.name,
      pictureURL: data.step1.picture.picURL,
      ...data.step2,
      ...data.step3,
      ...data.step4,
    }

    const personaMetaData = data.personaId ? { lastUpdated: firestore.FieldValue.serverTimestamp() } : {
      createdOn: firestore.FieldValue.serverTimestamp(),
      lastUpdated: firestore.FieldValue.serverTimestamp(),
      deleted: false,
      archived: false,
      creator: data.creator
    }

    persona.set({...personaData, ...personaMetaData}, { merge: true })
    .then(async () => { 
      dispatch(savePersonaSuccess())
      const updatedPersona = await persona.get().then(doc => ({...doc.data(), id: doc.id}))
      if (data.personaId) {
        // Remove interests that are removed from editing persona
        const removedInterests = difference(map(originalPersona.interests, 'id'), map(updatedPersona.interests, 'id'))
        removeFromPersonaConvosRemovedLabels(firestore, removedInterests, 'interests', updatedPersona.accountId, updatedPersona.id, getState, dispatch)
        // Remove challenges that are removed from editing persona
        const removedChallenges = difference(map(originalPersona.challenges, 'id'), map(updatedPersona.challenges, 'id'))
        removeFromPersonaConvosRemovedLabels(firestore, removedChallenges, 'challenges', updatedPersona.accountId, updatedPersona.id, getState, dispatch)
      }
      // Update number of personas in accounts
      await updateAccountPersonaCount(firestore, personasCol, data.currentAccountId)
      logSearchIndex(data.currentAccountId, 'personas');
    })
    .catch(err => { dispatch(savePersonaFail(err)) });
  }
}

export const createNewPersonaDevtDataStart = ( key ) => {
  return {
    type: actions.CREATE_NEW_PERSONADEVT_DATA_START,
    selectKey: key
  }
}

export const createNewPersonaDevtDataSuccess = ( key, id, newVal ) => {
  return {
    type: actions.CREATE_NEW_PERSONADEVT_DATA_SUCCESS,
    selectKey: key,
    id,
    newVal
  }
}

export const createNewPersonaDevtDataFail = ( key, error ) => {
  return {
    type: actions.CREATE_NEW_PERSONADEVT_DATA_FAIL,
    error: error,
    selectKey: key
  }
}

export const createNewPersonaDevtData = newData => {
  return (dispatch, getState, {getFirestore}) => {
    const selectKey = newData.selectKey;
    const curAcct = newData.acctId;
    dispatch(createNewPersonaDevtDataStart(selectKey));
    const firestore = getFirestore();
    let db;
    switch (selectKey) {
      case 'roles':
        db = `${curAcct}_roles`;
        break;
      case 'interests':
        db = `${curAcct}_interests`;
        break;
      case 'challenges':
        db = `${curAcct}_challenges`;
        break;
      case 'solutions':
        db = `${curAcct}_solutions`;
        break;
      default:
        db = `${curAcct}_roles`;
    }
    firestore.collection(db).add({
      accountId: newData.acctId,
      value: newData.value,
      label: newData.label,
      description: newData.description || null,
      parent: newData.parent || null,
      createdOn: firestore.FieldValue.serverTimestamp(),
      lastUpdated: firestore.FieldValue.serverTimestamp(),
      deleted: false
    }).then(docRef => {
      docRef.get().then(doc => {
        if (doc.exists) {
          dispatch(createNewPersonaDevtDataSuccess(selectKey, doc.id, doc.data()));
          if (selectKey !== 'roles') {
            logSearchIndex(curAcct, selectKey);
          }
        } else {
          dispatch(createNewPersonaDevtDataFail(selectKey, { message: 'Document does not exist' }));
        }
      }).catch(err => { dispatch(createNewPersonaDevtDataFail(selectKey, err)); })
    }).catch(err => {
      dispatch(createNewPersonaDevtDataFail(selectKey, err));
    });
  }
}

export const selectPersona = personaId => {
  return (dispatch, getState) => {
    const persona = getState().firestore.data.personas[personaId];
    const labels = [stores.INTERESTS, stores.CHALLENGES, stores.SOLUTIONS];
    let personaLabels = [], postLabels = [];
    labels.forEach(label => {
      // Get persona labels (Interests and Challenges)
      if (persona[label]) {
        personaLabels = [...personaLabels, ...persona[label]]
      }
      // Get labels from posts
      if (persona.discussions) {
        persona.discussions.forEach(discussionId => {
          const discussion = getState().firestore.data.discussions[discussionId];
          if (discussion && discussion.tags && discussion.tags[label]) {
            postLabels = [...postLabels, ...discussion.tags[label]]
          }
        })
      }
    });
    dispatch({
      type: actions.SELECTED_POST_LABEL_SET,
      id: null,
      // related: uniq(map([...personaLabels, ...postLabels], 'id')),
      related: uniq(map([...personaLabels, ...postLabels], label => { 
        return label.negative ? `${label.id}-negative` : label.id
      })),
      posts: persona.discussions || [],
      postId: null,
      personaId,
      personas: [personaId]
    });
  }
}

export const activatePersona = personaId => {
  return dispatch => {
    dispatch({
      type: actions.ACTIVATE_SELECTED_PERSONA,
      personaId,
    });
  }
}

export const getAffectedPersona = async (collection, account, id, firestore) => {
  let persona;
  await firestore.collection(`${account}_${collection}`).doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        const document = doc.data();
        persona = document.persona
      } else {
        persona = null;
      }
    })
    .catch(error => {
      persona = null;
    })
  return persona;
}

const archivePersonaStart = persona => {
  return {
      type: actions.ARCHIVE_PERSONA_START,
      persona
  }
}

const archivePersonaSuccess = persona => {
  return {
      type: actions.ARCHIVE_PERSONA_SUCCESS,
      persona
  }
}

const archivePersonaFail = (persona, err) => {
  return {
      type: actions.ARCHIVE_PERSONA_FAIL,
      persona,
      err
  }
}

export const archivePersona = ({
  personaId, 
  archiveVal,
  acct
}) => {
  return async (dispatch, getState, {getFirestore}) => {
    dispatch(archivePersonaStart(personaId));
    const firestore = getFirestore();
    const personasCol = `${acct}_personas`;
    // const currentAcctPlan = getState().subscription.data
    // try {
    //   // Get count of archived personas
    //   const archivedPersonas = await countArchivedPersonas(firestore, personasCol)
    //   if ((archiveVal && currentAcctPlan.archivePersona && archivedPersonas < currentAcctPlan.archivedPersonasLimit) ||
    //   !archiveVal && currentAcctPlan.restorePersona && )
    // } catch (error) {
      
    // }

    const persona = firestore.collection(personasCol).doc(personaId);
    persona.set({ archived: archiveVal }, { merge: true })
      .then(async () => { 
        dispatch(archivePersonaSuccess(personaId))
        // Update number of personas in accounts
        await updateAccountPersonaCount(firestore, personasCol, acct)
      })
      .catch(err => { dispatch(archivePersonaFail(personaId, err)) });
  }
}


const deletePersonaStart = persona => {
  return {
      type: actions.DELETE_PERSONA_START,
      persona
  }
}

const deletePersonaSuccess = persona => {
  return {
      type: actions.DELETE_PERSONA_SUCCESS,
      persona
  }
}

const deletePersonaFail = (persona, err) => {
  return {
      type: actions.DELETE_PERSONA_FAIL,
      persona,
      err
  }
}

export const deletePersona = ({
  personaId, 
  acct
}) => {
  return (dispatch, getState, {getFirestore}) => {
    dispatch(deletePersonaStart(personaId));
    const firestore = getFirestore();
    const personasCol = `${acct}_personas`;
    const persona = firestore.collection(personasCol).doc(personaId);
    persona.set({ deleted: true }, { merge: true })
      .then(async () => { 
        dispatch(deletePersonaSuccess(personaId));
        // Update number of personas in accounts
        await updateAccountPersonaCount(firestore, personasCol, acct)
      })
      .catch(err => { dispatch(deletePersonaFail(personaId, err)) });
  }
}

export const countPersonas = async (firestore, personasCol) => {
  try {
    const personasRef = firestore.collection(personasCol);
    const activePersonasQuery = personasRef.where('deleted', '==', false).where('archived', '==', false);
    return await activePersonasQuery.get().then(querySnapshot => querySnapshot.size)
  } catch (error) {
    return 0;
  }
}

export const countArchivedPersonas = async (firestore, personasCol) => {
  try {
    const personasRef = firestore.collection(personasCol);
    const archivedPersonasQuery = personasRef.where('archived', '==', true);
    return await archivedPersonasQuery.get().then(querySnapshot => querySnapshot.size)
  } catch (error) {
    return 0;
  }
}

export const countDeletedPersonas = async (firestore, personasCol) => {
  try {
    const personasRef = firestore.collection(personasCol);
    const deletedPersonasQuery = personasRef.where('deleted', '==', true);
    return await deletedPersonasQuery.get().then(querySnapshot => querySnapshot.size)
  } catch (error) {
    return 0;
  }
}

export const removeFromPersonaConvosRemovedLabels = async (
  db, 
  labels, 
  labelType, 
  account,
  persona,
  getState, 
  dispatch
) => {
  try {
    const discussionCol = `${account}_discussions`
    const createActivity = async (labelId, labelType, convoId) => {
      const labelCol = `${account}_${labelType}`
      const labelRef = db.collection(labelCol).doc(labelId)
      const labelDoc = await labelRef.get()
      if (labelDoc.exists) {
        const state = getState();
        dispatch(addActivity({
          account,
          type: 'removeLabelFromPersona',
          by: state.firebase.auth.uid,
          activityData: {
            label: {...labelDoc.data(), id: labelDoc.id},
            labelType,
            persona,
            discussion: convoId
          },
          notify: true,
          toNotify: Object.keys(state.firestore.data.users[account])
        }))
      }
    }
    const processRemovedLabel = async (labels, labelType) => {
      if (labels.length > 0) {
        await asyncForEach(labels, async labelId => {
          const convos = await getAffectedDiscussions(labelId, account, labelType, db, persona)
          await asyncForEach(convos, async convoId => {
            await removeLabelFromDiscussion(db, convoId, labelId, labelType, discussionCol)
            // log search index
            logSearchIndex(account, 'discussions');
            // create activity
            createActivity(labelId, labelType, convoId)
          })
        })
      }
    }
    await processRemovedLabel(labels, labelType)
  } catch (error) {
    console.log(error)
  }
}

export const removeLabelFromDiscussion = async (db, convoId, labelId, labelType, discussionCol) => {
  const convoRef = db.collection(discussionCol).doc(convoId)
  const convoSnap = await convoRef.get()
  const convo = convoSnap.data()
  return await convoRef.update({
    lastUpdated: db.FieldValue.serverTimestamp(),
    [`tags.${labelType}`]: reject(convo.tags[labelType], {'id': labelId}),
  })
}