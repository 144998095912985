import React, { Component } from 'react';

const env = process.env.REACT_APP_FIREBASE_ENV;

class Erxes extends Component {
	componentDidMount() {
		if (env === 'production') {
			window.erxesSettings = {
				messenger: {
					brand_id: 'dzGfqw'
				}
			};

			const script = document.createElement('script');
			script.src = 'https://intrio.app.erxes.io/widgets/build/messengerWidget.bundle.js';
			script.async = true;
			const entry = document.getElementsByTagName('script')[0];
			entry.parentNode.insertBefore(script, entry);
		}
	}

	render() {
		return <React.Fragment />;
	}
}

export default Erxes;
