import React from 'react';

import Wrap from '../../Wrap/Wrap';
import Navbar from '../../../components/Nav/NavBar/NavBar'
import classes from './MainLayout.module.css';

const mainLayout = ( props ) => {
	return (
			<Wrap>
				<Navbar/>
				<main className={classes.MainLayout}>
					{props.children}
				</main>
			</Wrap>
		)
}

export default mainLayout;