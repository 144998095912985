import Loadable from 'react-loadable';

import Preloader from '../components/UI/Preloader/Preloader'

const split = loc => {
  return Loadable({
    loader: () => import('../' + loc),
    loading: Preloader,
  });
}

export default split;