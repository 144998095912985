import firebase from '../config/firebase';
import { updateAccount } from '../store/actions/accountActions';

export const updateSubscriptionQuantity = async (acct) => {
	try {
		if (acct.subscription && acct.subscription.plan !== 'egg' && acct.subscription.subscriptionId) {
			const numUsers = acct.users.length,
				numSuspended = acct.suspended.length || 0;
			const activeUsers = numUsers - numSuspended;
			const functions = firebase.functions();
			const updateCustomerSubscriptionQty = functions.httpsCallable('updateCustomerSubscriptionQty');
			await updateCustomerSubscriptionQty({
				subscriptionId: acct.subscription.subscriptionId,
				quantity: activeUsers
			});
			console.log('Successfully updated subscription');
		}
	} catch (error) {
		console.log(error.message);
	}
};

export const getInvitationsToAccount = async (acctId, db) => {
	try {
		const invitationsRef = db.collection('invitations');
		const invitationsQuery = invitationsRef.where('accountId', '==', acctId).where('accepted', '==', false);
		const invitationsSnap = await invitationsQuery.get();
		let invitations = [];
		if (!invitationsSnap.empty) {
			invitationsSnap.forEach((inv) => {
				invitations.push(inv.id);
			});
		}
		return invitations;
	} catch (error) {
		console.log(error.message);
	}
};

export const addCustomerProfileToPayingAccounts = async (customerId, accountId, accountName, functions, db) => {
	try {
		const getCustomerData = functions.httpsCallable('getCustomerData');
		const customerData = await getCustomerData(customerId);
		const customerProfile = {
			name: customerData.data.name || '',
			contactNumber: customerData.data.phone || '',
			email: customerData.data.email || ''
		};
		await updateAccount(db, { 'subscription.customerProfile': customerProfile }, accountId);
		console.log(`Updated ${accountName} successfully`);
	} catch (error) {
		console.error(`Failed to update ${accountName}`, error);
	}
};
