import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
  add: {},
  setPrimary: {},
}

const suggestionReducer = (state = initState, action) => {
  let addedSuggestion, setToPrimary;
  switch ( action.type ) {
    case actions.ADD_SUGGESTION_START:
      addedSuggestion = updateObject( state.add, {
        [action.postId]: updateObject( state.add[action.postId], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        add: addedSuggestion
      }
    case actions.ADD_SUGGESTION_SUCCESS:
      addedSuggestion = updateObject( state.add, {
        [action.postId]: updateObject( state.add[action.postId], {
          adding: false,
          added: true,
          error: null
        })
      });
      return {
        ...state,
        add: addedSuggestion
      }
    case actions.ADD_SUGGESTION_FAIL:
      addedSuggestion = updateObject( state.add, {
        [action.postId]: updateObject( state.add[action.postId], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        add: addedSuggestion
      }
    case actions.SET_SUGGESTION_AS_PRIMARY_START:
      setToPrimary = updateObject( state.setPrimary, {
        [action.suggestId]: updateObject( state.setPrimary[action.suggestId], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        setPrimary: setToPrimary
      }
    case actions.SET_SUGGESTION_AS_PRIMARY_SUCCESS:
      setToPrimary = updateObject( state.setPrimary, {
        [action.suggestId]: updateObject( state.setPrimary[action.suggestId], {
          adding: false,
          added: true,
          error: null
        })
      });
      return {
        ...state,
        setPrimary: setToPrimary
      }
    case actions.SET_SUGGESTION_AS_PRIMARY_FAIL:
      setToPrimary = updateObject( state.setPrimary, {
        [action.suggestId]: updateObject( state.setPrimary[action.suggestId], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        setPrimary: setToPrimary
      }
    default:
      return state;
  }
}

export default suggestionReducer;