import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
  newTag: {},
  newPost: {},
  edit: {},
  changeOrder: {},
  addElements: {}
}

const discussionReducer = (state = initState, action) => {
  let addedTag, addedPost, updatedPostElements, updatedPost, addedElements;
  switch ( action.type ) {
    case actions.DISCUSSION_CREATE_NEW_TAG_START:
      addedTag = updateObject( state.newTag, {
        [action.tagType]: updateObject( state.newTag[action.tagType], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        newTag: addedTag
      }
    case actions.DISCUSSION_CREATE_NEW_TAG_SUCCESS:
      addedTag = updateObject( state.newTag, {
        [action.tagType]: updateObject( state.newTag[action.tagType], {
          adding: false,
          added: true,
          newTagData: action.newData,
          error: null
        })
      });
      return {
        ...state,
        newTag: addedTag
      }
    case actions.DISCUSSION_CREATE_NEW_TAG_FAIL:
      addedTag = updateObject( state.newTag, {
        [action.tagType]: updateObject( state.newTag[action.tagType], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        newTag: addedTag
      }
    case actions.ADD_POST_START:
      addedPost = updateObject( state.newPost, {
        adding: true,
        added: false,
        error: null
      });
      return {
        ...state,
        newPost: addedPost
      }
    case actions.ADD_POST_SUCCESS:
      addedPost = updateObject( state.newPost, {
        adding: false,
        added: true,
        error: null,
        discussion: action.newPost
      });
      return {
        ...state,
        newPost: addedPost
      }
    case actions.ADD_POST_FAIL:
      addedPost = updateObject( state.newPost, {
        adding: false,
        added: false,
        error: action.error.message,
      });
      return {
        ...state,
        newPost: addedPost
      }
    case actions.EDIT_POST_ELEMENT_START:
      updatedPostElements = updateObject( state.edit, {
        [action.postElementId]: updateObject( state.edit[action.postElementId], {
          editing: true,
          edited: false,
          error: null
        })
      });
      return {
        ...state,
        edit: updatedPostElements
      }
    case actions.EDIT_POST_ELEMENT_SUCCESS:
      updatedPostElements = updateObject( state.edit, {
        [action.postElementId]: updateObject( state.edit[action.postElementId], {
          editing: false,
          edited: true,
          error: null
        })
      });
      return {
        ...state,
        edit: updatedPostElements
      }
    case actions.EDIT_POST_ELEMENT_FAIL:
      updatedPostElements = updateObject( state.edit, {
        [action.postElementId]: updateObject( state.edit[action.postElementId], {
          editing: false,
          edited: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        edit: updatedPostElements
      }
    case actions.EDIT_POST_TAGS_START:
      updatedPost = updateObject( state.edit, {
        [action.postId]: updateObject( state.edit[action.postId], {
          editing: true,
          edited: false,
          error: null
        })
      });
      return {
        ...state,
        edit: updatedPost
      }
    case actions.EDIT_POST_TAGS_SUCCESS:
      updatedPost = updateObject( state.edit, {
        [action.postId]: updateObject( state.edit[action.postId], {
          editing: false,
          edited: true,
          error: null
        })
      });
      return {
        ...state,
        edit: updatedPost
      }
    case actions.EDIT_POST_TAGS_FAIL:
      updatedPost = updateObject( state.edit, {
        [action.postId]: updateObject( state.edit[action.postId], {
          editing: false,
          edited: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        edit: updatedPost
      }
    case actions.REORDER_POSTELS_START:
      updatedPost = updateObject( state.changeOrder, {
        [action.postId]: updateObject( state.changeOrder[action.postId], {
          reordering: true,
          reordered: false,
          error: null
        })
      });
      return {
        ...state,
        changeOrder: updatedPost
      }
    case actions.REORDER_POSTELS_SUCCESS:
      updatedPost = updateObject( state.changeOrder, {
        [action.postId]: updateObject( state.changeOrder[action.postId], {
          reordering: false,
          reordered: true,
          error: null
        })
      });
      return {
        ...state,
        changeOrder: updatedPost
      }
    case actions.REORDER_POSTELS_FAIL:
      updatedPost = updateObject( state.changeOrder, {
        [action.postId]: updateObject( state.changeOrder[action.postId], {
          reordering: true,
          reordered: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        changeOrder: updatedPost
      }
    case actions.ADD_POST_ELEMENTS_START:
      addedElements = updateObject( state.addElements, {
        [action.postId]: updateObject( state.addElements[action.postId], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        addElements: addedElements
      }
    case actions.ADD_POST_ELEMENTS_SUCCESS:
      addedElements = updateObject( state.addElements, {
        [action.postId]: updateObject( state.addElements[action.postId], {
          adding: false,
          added: true,
          error: null
        })
      });
      return {
        ...state,
        addElements: addedElements
      }
    case actions.ADD_POST_ELEMENTS_FAIL:
      addedElements = updateObject( state.addElements, {
        [action.postId]: updateObject( state.addElements[action.postId], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        addElements: addedElements
      }
    default:
      return state;
  }
}

export default discussionReducer;