import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './RouteOnModalBody.module.scss';

import { joinClasses } from '../../../../shared/utility';

class RouteOnModalBody extends Component {
  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }
  escFunction = event => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handlerOnModalClose()
    }
  }
  handlerOnModalClose = () => {
    this.props.closeModal();
    // this.props.reset();
  }
  render() {
    return (
      <div
        className={joinClasses([
          "modal-content",
          classes.ModalContent,
          this.props.size ? classes[this.props.sizeClass] : null,
          // this.props.showOverflowVisible ? 'menu-is-open' : null
          ])} >
        { this.props.noHeader ?
          <span className={classes.ModalCloseNoHeader} onClick={this.handlerOnModalClose}>
            <FontAwesomeIcon icon='times' size="2x"/>
          </span> :
          <div className={joinClasses(["modal-header", classes.ModalHeader])}>
              <div className={joinClasses(["modal-title h5", classes.ModalTitle])}>{this.props.title}</div>
              <span className={classes.ModalClose} onClick={this.handlerOnModalClose}>
                <FontAwesomeIcon icon='times' size="lg"/>
              </span>
          </div>
        }
        <div className={joinClasses([
          "modal-body",
          classes.ModalBody,
          // this.props.showOverflowVisible ? classes.OverflowVisible : null
          ])}>
          {this.props.bodyComponent}
        </div>
      </div>
    );
  }
}

export default RouteOnModalBody;