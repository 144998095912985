import firebase from '../config/firebase';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

export const getAccountById = async acctId => {
    const db = firebase.firestore()
    const accountRef = db.collection("accounts").doc(acctId)
    return await accountRef.get().then(doc => {
        if (doc.exists) {
            return doc.data()
        } else {
            throw Error(`${acctId} is not a valid account ID!`)
        }
    }).catch(error => {
        throw error
    })
}

export const getUserAccountById = async uid => {
    const db = firebase.firestore()
    const userRef = db.collection("users").doc(uid)
    return await userRef.get().then(doc => {
        if (doc.exists) {
            return doc.data()
        } else {
            throw Error(`${uid} is not a valid user ID!`)
        }
    }).catch(error => {
        throw error
    })
}

export const getUserAccountByReferralID = async refId => {
    const db = firebase.firestore()
    const userRef = db.collection("users")
    const userQuery = userRef.where("referralId", "==", refId)
    return await userQuery.get().then(querySnapshot => {
        if (querySnapshot.empty) {
            throw Error(`${refId} is not an existing referral ID!`)
        } else {
            return querySnapshot.docs[0].data()
        }
    }).catch(error => {
        throw error
    })
}

export const getRegistrationById = async regId => {
    const db = firebase.firestore()
    const userRef = db.collection("registrations").doc(regId)
    return await userRef.get().then(doc => {
        if (doc.exists) {
            return doc.data()
        } else {
            throw Error(`${regId} is not a valid registration ID!`)
        }
    }).catch(error => {
        throw error
    })
}

export const getInvitationById = async invId => {
    const db = firebase.firestore()
    const userRef = db.collection("invitations").doc(invId)
    return await userRef.get().then(doc => {
        if (doc.exists) {
            return doc.data()
        } else {
            throw Error(`${invId} is not a valid invitation ID!`)
        }
    }).catch(error => {
        throw error
    })
}

export const getAccountPersonas = async acct => {
    try {
        const db = firebase.firestore()
        const personasRef = db.collection(`${acct}_personas`);
        return await personasRef.get().then(querySnapshot => querySnapshot)
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getAllNegativeLabelsInConvos = async (discussionCol, personaId, labelType = 'interests') => {
    let negativeLabels = []
    try {
        const db = firebase.firestore()
        const discussionRef = db.collection(discussionCol)
        const discussionsQuerySnapshot = personaId ? await discussionRef.where("persona", "==", personaId).get() : await discussionRef.get()
        if (discussionsQuerySnapshot.size > 0) {
            discussionsQuerySnapshot.forEach(docSnapshot => {
                const discussion = docSnapshot.data()
                if (discussion.tags && !isEmpty(discussion.tags[labelType])) {
                    const taggedLabels = filter(discussion.tags[labelType], ['negative', true])
                    negativeLabels = [...negativeLabels, ...taggedLabels]
                }
            })
        } else {
            throw Error(`No discussions.`)
        }
        return negativeLabels
    } catch (error) {
        return negativeLabels
    }
}