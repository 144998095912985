import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
  demographics: {},
  roles: {},
  interests: {},
  challenges: {},
  savingPersona: false,
  savingPersonaError: null,
  archive: {},
  delete: {},
  active: null
}

const personaReducer = (state = initState, action) => {
  let newDemographics, newPersonaData, archivePersona, deletePersona;
  switch ( action.type ) {
    case actions.CREATE_NEW_DEMOGRAPHICS_DATA_START:
      newDemographics = updateObject( state.demographics, {
        [action.selectKey]: updateObject( state.demographics[action.selectKey], {
          creating: true,
          created: false,
          error: null
        })
      });
      return {
        ...state,
        demographics: newDemographics
      }
    case actions.CREATE_NEW_DEMOGRAPHICS_DATA_SUCCESS:
      newDemographics = updateObject( state.demographics, {
        [action.selectKey]: updateObject( state.demographics[action.selectKey], {
          creating: false,
          created: true,
          error: null,
          newValue: {...action.newVal, id: action.id}
        })
      });
      return {
        ...state,
        demographics: newDemographics
      }
    case actions.CREATE_NEW_DEMOGRAPHICS_DATA_FAIL:
      newDemographics = updateObject( state.demographics, {
        [action.selectKey]: updateObject( state.demographics[action.selectKey], {
          creating: false,
          created: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        demographics: newDemographics
      }
    case actions.SAVE_PERSONA_START:
      return {
        ...state,
        savingPersona: true
      }
    case actions.SAVE_PERSONA_SUCCESS:
      return {
        ...state,
        savingPersona: false
      }
    case actions.SAVE_PERSONA_FAIL:
      return {
        ...state,
        savingPersona: false,
        savingPersonaError: action.error.message
      }
    case actions.CREATE_NEW_PERSONADEVT_DATA_START:
      newPersonaData = updateObject( state[action.selectKey], {
        creating: true,
        created: false,
        error: null
      });
      return {
        ...state,
        [action.selectKey]: newPersonaData
      }
    case actions.CREATE_NEW_PERSONADEVT_DATA_SUCCESS:
      newPersonaData = updateObject( state[action.selectKey], {
        creating: false,
        created: true,
        error: null,
        newValue: {...action.newVal, id: action.id}
      });
      return {
        ...state,
        [action.selectKey]: newPersonaData
      }
    case actions.CREATE_NEW_PERSONADEVT_DATA_FAIL:
      newPersonaData = updateObject( state[action.selectKey], {
        creating: false,
        created: false,
        error: action.error.message
      });
      return {
        ...state,
        [action.selectKey]: newPersonaData
      }
    case actions.ARCHIVE_PERSONA_START:
        archivePersona = updateObject( state.archive, {
          [action.persona]: updateObject( state.archive[action.persona], {
            archiving: true,
            archived: false,
            error: null
          })
        });
        return {
          ...state,
          archive: archivePersona
        }
    case actions.ARCHIVE_PERSONA_SUCCESS:
        archivePersona = updateObject( state.archive, {
          [action.persona]: updateObject( state.archive[action.persona], {
            archiving: false,
            archived: true,
            error: null
          })
        });
        return {
          ...state,
          archive: archivePersona
        }
    case actions.ARCHIVE_PERSONA_FAIL:
        archivePersona = updateObject( state.archive, {
          [action.persona]: updateObject( state.archive[action.persona], {
            archiving: false,
            archived: false,
            error: action.error.message
          })
        });
        return {
          ...state,
          archive: archivePersona
        }
    case actions.DELETE_PERSONA_START:
        deletePersona = updateObject( state.delete, {
          [action.persona]: updateObject( state.delete[action.persona], {
            deleting: true,
            deleted: false,
            error: null
          })
        });
        return {
          ...state,
          delete: deletePersona
        }
    case actions.DELETE_PERSONA_SUCCESS:
        deletePersona = updateObject( state.delete, {
          [action.persona]: updateObject( state.delete[action.persona], {
            deleting: false,
            deleted: true,
            error: null
          })
        });
        return {
          ...state,
          delete: deletePersona
        }
    case actions.DELETE_PERSONA_FAIL:
        deletePersona = updateObject( state.delete, {
          [action.persona]: updateObject( state.delete[action.persona], {
            deleting: false,
            deleted: false,
            error: action.error.message
          })
        });
        return {
          ...state,
          delete: deletePersona
        }
    case actions.ACTIVATE_SELECTED_PERSONA:
        return {
          ...state,
          active: action.personaId
        }
    default:
      return state;
  }
}

export default personaReducer;