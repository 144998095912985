export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS';
export const SET_USER_PROFILE_FAILED = 'SET_USER_PROFILE_FAILED';
export const SET_USERS_DATA_SUCCESS = 'SET_USERS_DATA_SUCCESS';
export const SET_USERS_DATA_FAILED = 'SET_USERS_DATA_FAILED';
export const REG_COMPLETED_SUCCESS = 'REG_COMPLETED_SUCCESS';
export const REG_COMPLETED_FAILED = 'REG_COMPLETED_FAILED';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAIL = 'CREATE_ACCOUNT_FAIL';

export const SEND_INVITATION_START = 'SEND_INVITATION_START';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAIL= 'SEND_INVITATION_FAIL';
export const REG_ADDED_SUCCESS = 'REG_ADDED_SUCCESS';
export const REG_ADDED_FAIL = 'REG_ADDED_FAIL';

export const ACCEPT_INVITATION_START = 'ACCEPT_INVITATION_START';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAIL = 'ACCEPT_INVITATION_FAIL';

export const CREATE_NEW_DEMOGRAPHICS_DATA_START = 'CREATE_NEW_DEMOGRAPHICS_DATA_START';
export const CREATE_NEW_DEMOGRAPHICS_DATA_SUCCESS = 'CREATE_NEW_DEMOGRAPHICS_DATA_SUCCESS';
export const CREATE_NEW_DEMOGRAPHICS_DATA_FAIL = 'CREATE_NEW_DEMOGRAPHICS_DATA_FAIL';

export const SAVE_PERSONA_START = 'CREATE_NEW_PERSONA_START';
export const SAVE_PERSONA_SUCCESS = 'CREATE_NEW_PERSONA_SUCCESS';
export const SAVE_PERSONA_FAIL = 'CREATE_NEW_PERSONA_FAIL';

export const CREATE_NEW_PERSONADEVT_DATA_START = 'CREATE_NEW_PERSONADEVT_DATA_START';
export const CREATE_NEW_PERSONADEVT_DATA_SUCCESS = 'CREATE_NEW_PERSONADEVT_DATA_SUCCESS';
export const CREATE_NEW_PERSONADEVT_DATA_FAIL = 'CREATE_NEW_PERSONADEVT_DATA_FAIL';

export const EDIT_LABEL_START = 'EDIT_LABEL_START';
export const EDIT_LABEL_SUCCESS = 'EDIT_LABEL_SUCCESS';
export const EDIT_LABEL_FAIL = 'EDIT_LABEL_FAIL';

export const CURRENT_ACCOUNT_SET = 'CURRENT_ACCOUNT_SET';
export const CURRENT_ACCOUNT_RESET = 'CURRENT_ACCOUNT_RESET';

// export const SELECTED_LABEL_SET = 'SELECTED_LABEL_SET';
export const SELECTED_POST_LABEL_SET = 'SELECTED_POST_LABEL_SET';
export const SELECTED_POST_LABEL_RESET = 'SELECTED_POST_LABEL_RESET';

export const PROMPTED_DELETE_LABEL = 'PROMPTED_DELETE_LABEL';

export const ADD_LABEL_TO_PERSONA_START = 'ADD_LABEL_TO_PERSONA_START';
export const ADD_LABEL_TO_PERSONA_SUCCESS = 'ADD_LABEL_TO_PERSONA_SUCCESS';
export const ADD_LABEL_TO_PERSONA_FAIL = 'ADD_LABEL_TO_PERSONA_FAIL';

export const DISCUSSION_CREATE_NEW_TAG_START = 'DISCUSSION_CREATE_NEW_TAG_START';
export const DISCUSSION_CREATE_NEW_TAG_SUCCESS = 'DISCUSSION_CREATE_NEW_TAG_SUCCESS';
export const DISCUSSION_CREATE_NEW_TAG_FAIL = 'DISCUSSION_CREATE_NEW_TAG_FAIL';

export const ADD_POST_START = 'ADD_POST_START';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
export const ADD_POST_FAIL = 'ADD_POST_FAIL';

export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const COMMENT_SEEN_BY_USER = 'COMMENT_SEEN_BY_USER';
export const NEW_SUGGESTION_SEEN_BY_USER = 'NEW_SUGGESTION_SEEN_BY_USER';

export const ADD_SUGGESTION_START = 'ADD_SUGGESTION_START';
export const ADD_SUGGESTION_SUCCESS = 'ADD_SUGGESTION_SUCCESS';
export const ADD_SUGGESTION_FAIL = 'ADD_SUGGESTION_FAIL';

export const SET_SUGGESTION_AS_PRIMARY_START = 'SET_SUGGESTION_AS_PRIMARY_START';
export const SET_SUGGESTION_AS_PRIMARY_SUCCESS = 'SET_SUGGESTION_AS_PRIMARY_SUCCESS';
export const SET_SUGGESTION_AS_PRIMARY_FAIL = 'SET_SUGGESTION_AS_PRIMARY_FAIL';

export const EDIT_POST_ELEMENT_START = 'EDIT_POST_ELEMENT_START';
export const EDIT_POST_ELEMENT_SUCCESS = 'EDIT_POST_ELEMENT_SUCCESS';
export const EDIT_POST_ELEMENT_FAIL = 'EDIT_POST_ELEMENT_FAIL';

export const EDIT_POST_TAGS_START = 'EDIT_POST_TAGS_START';
export const EDIT_POST_TAGS_SUCCESS = 'EDIT_POST_TAGS_SUCCESS';
export const EDIT_POST_TAGS_FAIL = 'EDIT_POST_TAGS_FAIL';

export const REORDER_POSTELS_START = 'REORDER_POSTELS_START';
export const REORDER_POSTELS_SUCCESS = 'REORDER_POSTELS_SUCCESS';
export const REORDER_POSTELS_FAIL = 'CHANGE_POSTEL_FAIL';

export const ADD_POST_ELEMENTS_START = 'ADD_POST_ELEMENTS_START';
export const ADD_POST_ELEMENTS_SUCCESS = 'ADD_POST_ELEMENTS_SUCCESS';
export const ADD_POST_ELEMENTS_FAIL = 'ADD_POST_ELEMENTS_FAIL';

export const DELETE_LABEL_START = 'DELETE_LABEL_START';
export const DELETE_LABEL_SUCCESS = 'DELETE_LABEL_SUCCESS';
export const DELETE_LABEL_FAIL = 'DELETE_LABEL_FAIL';

export const UNDO_DELETE_LABEL_START = 'UNDO_DELETE_LABEL_START';
export const UNDO_DELETE_LABEL_SUCCESS = 'UNDO_DELETE_LABEL_SUCCESS';
export const UNDO_DELETE_LABEL_FAIL = 'UNDO_DELETE_LABEL_FAIL';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';

export const IMPORT_LABELS_START = 'IMPORT_LABELS_START';
export const IMPORT_LABELS_SUCCESS = 'IMPORT_LABELS_SUCCESS';
export const IMPORT_LABELS_FAIL = 'IMPORT_LABELS_FAIL';

export const INDEX_ACCOUNT_DATA_START = 'INDEX_ACCOUNT_DATA_START';
export const INDEX_ACCOUNT_DATA_SUCCESS = 'INDEX_ACCOUNT_DATA_SUCCESS';
export const INDEX_ACCOUNT_DATA_FAIL = 'INDEX_ACCOUNT_DATA_FAIL';

export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAIL = 'SEARCH_FAIL';

export const TOGGLE_SEARCH_FILTER = 'TOGGLE_SEARCH_FILTER';
export const CLOSE_SEARCH_FILTER = 'CLOSE_SEARCH_FILTER';
export const INITIALIZE_ACCT_SEARCH = 'INITIALIZE_ACCT_SEARCH';
export const INPUT_SEARCH_KEYWORD = 'INPUT_SEARCH_KEYWORD';
export const INPUT_SEARCH_FILTER = 'INPUT_SEARCH_FILTER';
export const CLEAR_SEARCH_FORM = 'CLEAR_SEARCH_FORM';

export const ARCHIVE_PERSONA_START = 'ARCHIVE_PERSONA_START';
export const ARCHIVE_PERSONA_SUCCESS = 'ARCHIVE_PERSONA_SUCCESS';
export const ARCHIVE_PERSONA_FAIL = 'ARCHIVE_PERSONA_FAIL';

export const DELETE_PERSONA_START = 'DELETE_PERSONA_START';
export const DELETE_PERSONA_SUCCESS = 'DELETE_PERSONA_SUCCESS';
export const DELETE_PERSONA_FAIL = 'DELETE_PERSONA_FAIL';

export const REGISTER_NEGATIVE_TAG = 'REGISTER_NEGATIVE_TAG';

export const FORGOT_PASSWORD_REQ_START = 'FORGOT_PASSWORD_REQ_START';
export const FORGOT_PASSWORD_REQ_SUCCESS = 'FORGOT_PASSWORD_REQ_SUCCESS';
export const FORGOT_PASSWORD_REQ_FAIL = 'FORGOT_PASSWORD_REQ_FAIL';

export const RESET_PASSCODE_VERIFY_START = 'RESET_PASSCODE_VERIFY_START';
export const RESET_PASSCODE_VERIFY_SUCCESS = 'RESET_PASSCODE_VERIFY_SUCCESS';
export const RESET_PASSCODE_VERIFY_FAIL = 'RESET_PASSCODE_VERIFY_FAIL';

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL';

export const SAVE_ACCT_START = 'SAVE_ACCT_START';
export const SAVE_ACCT_SUCCESS = 'SAVE_ACCT_SUCCESS';
export const SAVE_ACCT_FAIL = 'SAVE_ACCT_FAIL';

export const TEAM_MANAGEMENT_INIT = 'TEAM_MANAGEMENT_INIT';

export const CHANGE_USER_ROLE_START = 'CHANGE_USER_ROLE_START';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_FAIL = 'CHANGE_USER_ROLE_FAIL';

export const SUSPEND_ACCOUNT_USER_START = 'SUSPEND_ACCOUNT_USER_START';
export const SUSPEND_ACCOUNT_USER_SUCCESS = 'SUSPEND_ACCOUNT_USER_SUCCESS';
export const SUSPEND_ACCOUNT_USER_FAIL = 'SUSPEND_ACCOUNT_USER_FAIL';

export const CANCEL_ACCOUNT_INVITE_START = 'CANCEL_ACCOUNT_INVITE_START';
export const CANCEL_ACCOUNT_INVITE_SUCCESS = 'CANCEL_ACCOUNT_INVITE_SUCCESS';
export const CANCEL_ACCOUNT_INVITE_FAIL = 'CANCEL_ACCOUNT_INVITE_FAIL';

export const SAVE_APPLIED_LABEL = 'SAVE_APPLIED_LABEL';
export const SAVE_NEGATIVE_TAGS = 'SAVE_NEGATIVE_TAGS';

export const ACTIVATE_SELECTED_PERSONA = 'ACTIVATE_SELECTED_PERSONA'