import moment from 'moment-timezone'
import firebase from '../config/firebase';

import { 
    getAccountById, 
    getUserAccountById, 
    getUserAccountByReferralID,
    getRegistrationById,
    getInvitationById
} from './queries'
const notifEmailTo = process.env.REACT_APP_ADMIN_EMAIL

export const emailAdminOnNewAccountCreated = async newAccount => {
    try {
        const account = await getAccountById(newAccount)
        const accountData = {
            name: account.name,
            initials: account.initials,
            description: account.description,
            created: moment(account.createdAt.toDate()).tz('America/Los_Angeles').format('ll')
        }

        const creator = await getUserAccountById(account.creator || account.owners[0])
        const creatorData = {
            name: creator.screenName,
            email: creator.email,
            referralId: creator.referralId
        }

        let creatorReferrer = creator.referrerCode
        let creatorReferrerData = {
            name: creatorReferrer === 'sus2019' ? 'Startup School 2019' : 'None',
            email: 'N/A',
            referralId: 'N/A'
        }

        if (creatorReferrer !== null && creatorReferrer !== 'sus2019') {
            creatorReferrer = await getUserAccountByReferralID(creator.referrerCode)
            creatorReferrerData = {
                name: creatorReferrer.screenName,
                email: creatorReferrer.email,
                referralId: creatorReferrer.referralId
            }
        }

        const emailData = {
            account: accountData,
            creator: creatorData,
            creatorRef: creatorReferrerData,
            to: notifEmailTo
        }

        const functions = firebase.functions();
        const notifyNewCreatedAccount = functions.httpsCallable('notifyNewCreatedAccount');
        await notifyNewCreatedAccount(emailData)
        console.log('Notification email sent!')
    } catch (error) {
        console.error('Unable to send email due to an error:', error.message)
        return;
    }
}

export const emailAdminOnNewUserCreated = async (uid, regId) => {
    try {
        // Get NEW USER info
        const newUser = await getUserAccountById(uid)
        const newUserData = {
            name: newUser.screenName,
            email: newUser.email,
            referralId: newUser.referralId
        }

        // Get NEW USER REFERRING USER info, if there is
        let newUserReferrer = newUser.referrerCode
        let newUserReferrerData = {
            name: newUserReferrer === 'sus2019' ? 'Startup School 2019' : 'None',
            email: 'N/A',
            referralId: 'N/A'
        }

        if (newUserReferrer !== null && newUserReferrer !== 'sus2019') {
            newUserReferrer = await getUserAccountByReferralID(newUser.referrerCode)
            newUserReferrerData = {
                name: newUserReferrer.screenName,
                email: newUserReferrer.email,
                referralId: newUserReferrer.referralId
            }
        }

        // Get REGISTRATION data of the NEW USER
        // Determines: invitor, registration completed, account invited to
        const userReg = await getRegistrationById(regId)
        
        // Get NEW USER INVITOR'S info
        const invitor = userReg.invited ? await getUserAccountById(userReg.invitedBy) : null;
        let invitorData;
        if (invitor) {
            invitorData = {
                name: invitor.screenName,
                email: invitor.email,
                referralId: invitor.referralId
            }
        } else {
            invitorData = {
                name: 'None',
                email: 'N/A',
                referralId: 'N/A'
            }
        }

        // Get ACCOUNT info to which new user is invited to
        const account = userReg.invited ? await getAccountById(userReg.invitedToAcct) : null
        let accountData;
        if (account) {
            accountData = {
                name: account.name,
                initials: account.initials,
                description: account.description,
                created: moment(account.createdAt.toDate()).tz('America/Los_Angeles').format('ll')
            }
        } else {
            accountData = {
                name: 'N/A',
                initials: 'N/A',
                description: 'N/A',
                created: 'N/A'
            }
        }

        const emailData = {
            newUser: newUserData,
            newUserRef: newUserReferrerData,
            invitor: invitorData,
            account: accountData,
            regCompleted: moment(userReg.completedAt.toDate()).tz('America/Los_Angeles').format('ll'),
            userIsInvited: userReg.invited ? 'Yes' : 'No',
            to: notifEmailTo
        }

        const functions = firebase.functions();
        const notifyNewUserAccount = functions.httpsCallable('notifyNewUserAccount');
        await notifyNewUserAccount(emailData)
        console.log('Notification email sent!')
    } catch (error) {
        console.error('Unable to send email due to an error:', error.message)
        return;
    }
}

export const emailAdminOnInvitationAccepted = async (uid, invId) => {
    try {
        // Get ACCEPTING USER info
        const acceptingUser = await getUserAccountById(uid)
        const acceptingUserData = {
            name: acceptingUser.screenName,
            email: acceptingUser.email,
            referralId: acceptingUser.referralId
        }

        // Get INVITATION info
        // Determines: account owner, invitor and account info
        const invitation = await getInvitationById(invId)
        
        // Get ACCOUNT OWNER info
        const accountOwnerInfo = invitation.accountInfo.owner
        const acctOwnerData = {
            name: accountOwnerInfo ? accountOwnerInfo.screenName : 'N/A',
            email: accountOwnerInfo ? accountOwnerInfo.email : 'N/A',
            referralId: accountOwnerInfo ? accountOwnerInfo.referralId : 'N/A'
        }

        // Get INVITOR'S info
        const invitor = invitation.invitedBy ? await getUserAccountById(invitation.invitedBy) : null;
        let invitorData;
        if (invitor) {
            invitorData = {
                name: invitor.screenName,
                email: invitor.email,
                referralId: invitor.referralId
            }
        } else {
            invitorData = {
                name: 'None',
                email: 'N/A',
                referralId: 'N/A'
            }
        }

        // Get ACCOUNT INFO
        const accountInfo = invitation.accountInfo
        const accountData = {
            name: invitation.accountName,
            initials: accountInfo.initials,
            description: accountInfo.description,
            personas: accountInfo.personas || 'N/A'
        }
        
        const emailData = {
            acceptingUser: acceptingUserData,
            accountOwner: acctOwnerData,
            invitor: invitorData,
            account: accountData,
            invitationAcceptedAt: moment(invitation.acceptedAt.toDate()).tz('America/Los_Angeles').format('ll'),
            to: notifEmailTo
        }

        const functions = firebase.functions();
        const notifyInvitationAccepted = functions.httpsCallable('notifyInvitationAccepted');
        await notifyInvitationAccepted(emailData)
        console.log('Notification email sent!')
    } catch (error) {
        console.error('Unable to send email due to an error:', error.message)
        return;
    }
}