import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
  add: {}
}

const commentReducer = (state = initState, action) => {
  let addedComment;
  switch ( action.type ) {
    case actions.ADD_COMMENT_START:
      addedComment = updateObject( state.add, {
        [action.elementId]: updateObject( state.add[action.elementId], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        add: addedComment
      }
    case actions.ADD_COMMENT_SUCCESS:
      addedComment = updateObject( state.add, {
        [action.elementId]: updateObject( state.add[action.elementId], {
          adding: false,
          added: true,
          error: null
        })
      });
      return {
        ...state,
        add: addedComment
      }
    case actions.ADD_COMMENT_FAIL:
      addedComment = updateObject( state.add, {
        [action.elementId]: updateObject( state.add[action.elementId], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        add: addedComment
      }
    default:
      return state;
  }
}

export default commentReducer;