import React from 'react';
import { ModalContainer } from 'react-router-modal';
import 'react-router-modal/css/react-router-modal.css';

import classes from './ModalContainer.module.scss';

const modalRouteContainer = () => (
  <ModalContainer
    wrapperClassName={classes.RouteOnModalWrapper}
    containerClassName={classes.RouteOnModalContainer}
    bodyModalOpenClassName={classes.RouteOnModalBodyOpen}/>
);

export default modalRouteContainer;