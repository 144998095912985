export const userIsOwnerOrEditor = (uid, currentAccount) => {
    if (!currentAccount)
      return false;
    else if ( !currentAccount.owners && !currentAccount.editors )
      return false;
    else if (currentAccount.owners && currentAccount.owners.indexOf(uid) !== -1)
      return true;
    else if (currentAccount.editors && currentAccount.editors.indexOf(uid) !== -1)
      return true;
    else return false;
}

export const userIsAccountOwner = (uid, currentAccount) => {
    if (!currentAccount)
      return false;
    else if ( !currentAccount.owners )
      return false;
    else if (currentAccount.owners && currentAccount.owners.indexOf(uid) !== -1)
      return true;
    else return false;
}

export const userIsSuspended = (uid, currentAccount) => {
  if (currentAccount.suspended && currentAccount.suspended.indexOf(uid) !== -1)
      return true;
  else return false;
}

export const userRoles = [
  // { name: 'owner', title: 'Owner' },
  { name: 'editor', title: 'Editor', field: 'editors' },
  { name: 'member', title: 'Member', field: 'members' },
  // { name: 'guest', title: 'Guest' },
  // { name: 'viewer', title: 'Viewer' },
];

export const suspendedUser = {
  name: 'suspended',
  title: 'Suspended',
  field: 'suspended'
}