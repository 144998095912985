import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faUsers,
	faPaperPlane,
	faMeh,
	faTimes,
	faFlag,
	faBullseye,
	faPuzzlePiece,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faComments,
	faLightbulb,
	faHome,
	faCog,
	faExclamation,
	faAngleDown,
	faAngleUp,
	faCheckCircle,
	faTimesCircle,
	faPlus,
	faMinus,
	faHeart,
	faComment,
	faAngleLeft,
	faAngleRight,
	faGripVertical,
	faGripHorizontal,
	faCommentAlt,
	faTrashAlt,
	faBroom,
	faExclamationCircle,
	faSearch,
	faFilter,
	faEllipsisV,
	faQuestion,
	faArrowUp,
	faArrowDown,
	faUser,
	faInfoCircle,
	faCreditCard,
	faInfo,
	faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash, faCircle } from '@fortawesome/free-regular-svg-icons';

import AppRouterSwitch from './hoc/AppRouteSwitcher/AppRouteSwitcher';
import ModalRouteContainer from './components/UI/Modal/ModalRouteContainer';
import ErrorBoundary from './components/UI/Error/ErrorBoundary';
import Erxes from './components/Erxes/Erxes';
import Segment from './components/Segment/Segment';
class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<Segment />
					<ErrorBoundary>
						<Route component={AppRouterSwitch} />
						<ModalRouteContainer />
					</ErrorBoundary>
					<Erxes />
				</div>
			</BrowserRouter>
		);
	}
}

library.add(
	faUsers,
	faPaperPlane,
	faMeh,
	faEye,
	faEyeSlash,
	faTimes,
	faFlag,
	faBullseye,
	faPuzzlePiece,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faComments,
	faLightbulb,
	faHome,
	faCog,
	faCircle,
	faExclamation,
	faAngleDown,
	faAngleUp,
	faCheckCircle,
	faTimesCircle,
	faPlus,
	faMinus,
	faHeart,
	faComment,
	faAngleLeft,
	faAngleRight,
	faGripVertical,
	faGripHorizontal,
	faCommentAlt,
	faTrashAlt,
	faBroom,
	faExclamationCircle,
	faFilter,
	faSearch,
	faEllipsisV,
	faQuestion,
	faArrowUp,
	faArrowDown,
	faUser,
	faInfoCircle,
	faCreditCard,
	faInfo,
	faArrowLeft
);

export default App;
