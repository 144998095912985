import React from 'react';
import { NavLink } from 'react-router-dom';
import includes from 'lodash/includes';

import { personaAccountPaths } from '../../../shared/links';
import classes from './NavBar.module.scss';
import { joinClasses } from '../../../shared/utility';

const labelsMngtLink = props => {
    if (props.match.path === "/persona-file/:id/manage-labels" && props.isAdmin) {
        return (
            <NavLink
                activeClassName={joinClasses([classes.DropdownMenuItemActive])}
                to={props.match.url}
                className={joinClasses(["nav-link dropdown-item"])}>Label Management
            </NavLink>
        )
    }
    else if (includes(personaAccountPaths, props.match.path) && props.isAdmin) {
        return (
            <NavLink
                activeClassName={joinClasses([classes.DropdownMenuItemActive])}
                to={`/persona-file/${props.match.params.id}/manage-labels`}
                className={joinClasses(["nav-link dropdown-item", classes.DropdownMenuItem])}>Label Management
            </NavLink>
        )
    }
    else return null;
}

export default labelsMngtLink;