import React from 'react';

const env = process.env.REACT_APP_FIREBASE_ENV;

class Segment extends React.Component {
	componentDidMount() {
		if (env === 'production') {
			window.analytics.load('5EqrtvRXPBsM2wF9EbFEPc40vKkvvlyA');
		}
	}

	render() {
		return <></>;
	}
}

export default Segment;
