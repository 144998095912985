import React, { Component } from "react";
import { ModalRoute } from 'react-router-modal';

import ModalBody from './RouteOnModalBody.js';
import classes from './RouteOnModal.module.css';
import { joinClasses } from '../../../../shared/utility';

class RouteOnModal extends Component {
  state = {
    modalTitle: null,
    selectIsOpen: false,
  }

  handleReset = () => {
    this.setState({ modalTitle: null });
  }

  handleChangeTitle = (title) => {
    this.setState({ modalTitle: title });
  }

  handleSelectIsOpened = (set) => {
    this.setState({ selectIsOpen: set });
  }

  render () {
    const { 
      component: Component, 
      title, 
      parentPath: close, 
      size, 
      componentProps, 
      noHeader,
      ...rest
    } = this.props;
    return (
      <ModalRoute
        component={ ModalBody }
        parentPath={close}
        props={{
          bodyComponent: (
            <Component
              updateTitle={this.handleChangeTitle}
              parentPath={close}
              setSelectIsOpen={this.handleSelectIsOpened}
              currentPath={rest.path}
              {...componentProps}/> 
          ),
          title: this.state.modalTitle || title,
          reset: this.handleReset,
          sizeClass: `ModalContent--${size}`,
          size: size,
          // showOverflowVisible: this.state.selectIsOpen,
          noHeader
        }}
        onBackdropClick={() => false}
        className={joinClasses([classes.Modal, size ? classes[size] : ''])}
        inClassName={classes.ModalIn}
        outClassName={classes.ModalOut}
        backdropClassName={classes.Backdrop}
        backdropInClassName={classes.BackdropIn}
        backdropOutClassName={classes.BackdropOut}
        outDelay={150}
        {...rest} />
    );
  }
}

export default RouteOnModal;