import React from 'react';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import '@gouch/to-title-case';
import map from 'lodash/map';
import compact from 'lodash/compact';
import trim from 'lodash/trim';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import forOwn from 'lodash/forOwn'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import isNaN from 'lodash/isNaN'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import pull from 'lodash/pull'
import cloneDeep from 'lodash/cloneDeep'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import PasswordValidator from 'password-validator'

const passwordSchema = new PasswordValidator()
passwordSchema
    .is().min(8)
    .has().letters()
    .has().digits()
    .has().symbols()
    .has().not().spaces()

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }
    
    if ( rules.validPassword ) {
        isValid = passwordSchema.validate(value) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isPhone && value.length) {
        const phone = parsePhoneNumberFromString(value, 'US')
        isValid = phone ? phone.isValid() : false
    }

    return isValid;
}

export const characterCounter = ( value, rules ) => {
    let charCounterData = {
        show: false
    };

    if ( rules.maxLength ) {
        charCounterData.show = value.length >= rules.maxLength - 20
        charCounterData.charsLeft = rules.maxLength - value.length
    }

    return charCounterData
}

export const addFormFeedback = ( value, rules, customMessage = null ) => {
    let feedback = null;
    if ( !rules ) {
        return feedback;
    }

    if ( rules.required && value.trim() === '' ) {
        feedback = (customMessage && customMessage.required) || 'This field is required.';
    }

    
    else if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (!pattern.test( value )) {
            feedback = (customMessage && customMessage.isEmail) || 'Please provide a valid email.';
        }
    }
    
    else if ( rules.minLength && value.length <= rules.minLength) {
        feedback = (customMessage && customMessage.minLength) || 'Should not be less than ' + rules.minLength + ' characters.';
    }
    
    else if (rules.validPassword) {
        if (!passwordSchema.validate(value))  {
            feedback = (customMessage && customMessage.validPassword) || 'Please provide a valid password.';
        }
    }
    
    else if (rules.isPhone) {
        const phone = parsePhoneNumberFromString(value, 'US')
        if ((phone && !phone.isValid()) || (value.length && !phone)) {
            feedback = (customMessage && customMessage.isPhone) || 'Phone number is invalid. Please use this format <+country code><phone number>.'
        }
    }

    else if ( rules.maxLength && value.length >= rules.maxLength ) {
        feedback = (customMessage && customMessage.maxLength) || 'Should not be more than ' + rules.maxLength + ' characters.';
    }

    else if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        if (!pattern.test( value ) ) {
            feedback = 'Please provide a number.';
        }
    }

    else if (rules.existingName) {
        feedback = customMessage.existingName || 'This name already exist.';
    }

    else if (rules.serverHasErrorFeedback) {
        feedback = customMessage.serverErrorResponse || 'Server error.';
    }

    else if (rules.customError) {
        feedback = customMessage || 'This is invalid.';
    }

    return (
        <span className="d-flex">
            <span><FontAwesomeIcon icon="exclamation-circle" size="sm"/></span>
            <span className="ml-1">{feedback}</span>
        </span>
    );
}

export const addCounterTxt = () => {
    return true
}

export const formDataIsValid = ( formData ) => {
    let data = true;
    for ( let key in formData ) {
        if (formData[key].validation.required) {
            data = data && formData[key].valid;
        } else if (formData[key].touched) {
            data = data && formData[key].valid;
        }
    }
    return data;
}

export const formIsBlank = ( formData ) => {
    let data = true;
    for ( let key in formData ) {
        data = data && isEmpty(formData[key].value.trim());
    }
    return data;
}

export const capitalize = string  => startCase(toLower(string));

export const getInitials = str => {
    if (str === undefined || str.length === 0) {
        return '';
    }
    else if (str === 'Unknown User') {
        return '?';
    }

    const matches = str.match(/\b(\w)/g);
    if (matches) {
        return matches.join('').slice(0,3);
    } else {
        return str;
    }
}

export const sumChars = str => {
    let sum = 0;
    for(let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }
    return sum;
}

// export const toTitle = string => string.toTitleCase();
export const toTitle = string => trim(string).replace(/\s\s+/g, ' ').toTitleCase();

export const joinClasses = classNames => compact(classNames).join(' ');

export const addIdToData = (object) => map(object, (val, key) => ({...val, id: key}));

export const removeWhitespace = str => str.replace(/ /g,'');

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const makeId = (length = 5) => {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export const hashCode = str => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export const intToRGB = i => {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

export const getColorByString = str => {
    let newStr = str;

    if (str === undefined || str.length === 0) {
        newStr = '?';
    }

    return intToRGB(hashCode(newStr))
}

export const fromNowOrTimestamp = date => {
    if (date) {
        // If date is less than or equal to 7 days ago display fromNow else display date.
        const daysAgo = moment().diff(date, 'days');
        if (daysAgo <= 7) {
            return date.fromNow();
        } else {
            return date.format("D MMM Y");
        }
    } else {
        return ''
    }
}

export const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export const getMoment = date => {
    switch(typeof date) {
        case "number":
            return moment.unix(date)
        default:
            return moment(date)
    }
}

export const pruneEmpty = obj => {
    return function prune(current) {
      forOwn(current, function (value, key) {
        if (isUndefined (value) || isNull(value) || isNaN(value) ||
          (isString(value) && isEmpty(value)) ||
          (isObject(value) && isEmpty(prune(value)))) {
  
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete 
      // operation on an array
      if (isArray(current)) pull(current, undefined);
  
      return current;
  
    }(cloneDeep(obj));  // Do not modify the original object, create a clone instead
}