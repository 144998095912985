import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
    changeRole: {},
    suspend: {},
    cancelInvite: {}
}

const userManagementReducer = (state = initState, action) => {
    let roleChanges, suspendUser, cancelInviteUser;
    switch ( action.type ) {
        case actions.TEAM_MANAGEMENT_INIT:
            roleChanges = updateObject( state.changeRole, {
                [action.account]: updateObject( state.changeRole[action.account], {})
            });
            suspendUser = updateObject( state.suspend, {
                [action.account]: updateObject( state.suspend[action.account], {})
            });
            cancelInviteUser = updateObject( state.cancelInvite, {
                [action.account]: updateObject( state.cancelInvite[action.account], {})
            });
            return {
                ...state,
                changeRole: roleChanges,
                suspend: suspendUser,
                cancelInvite: cancelInviteUser
            }
        case actions.CHANGE_USER_ROLE_START:
            roleChanges = updateObject( state.changeRole, {
                [action.account]: updateObject( state.changeRole[action.account], {
                    [action.email]: ( state.changeRole[action.account][action.email], {
                        changing: true,
                        changed: false,
                        error: null
                    })
                })
            });
            return {
                ...state,
                changeRole: roleChanges
            }
        case actions.CHANGE_USER_ROLE_SUCCESS:
            roleChanges = updateObject( state.changeRole, {
                [action.account]: updateObject( state.changeRole[action.account], {
                    [action.email]: ( state.changeRole[action.account][action.email], {
                        changing: false,
                        changed: true,
                        error: null
                    })
                })
            });
            return {
                ...state,
                changeRole: roleChanges
            }
        case actions.CHANGE_USER_ROLE_FAIL:
            roleChanges = updateObject( state.changeRole, {
                [action.account]: updateObject( state.changeRole[action.account], {
                    [action.email]: ( state.changeRole[action.account][action.email], {
                        changing: false,
                        changed: false,
                        error: action.error
                    })
                })
            });
            return {
                ...state,
                changeRole: roleChanges
            }
        case actions.SUSPEND_ACCOUNT_USER_START:
            suspendUser = updateObject( state.suspend, {
                [action.account]: updateObject( state.suspend[action.account], {
                    [action.email]: ( state.suspend[action.account][action.email], {
                        suspending: true,
                        suspended: false,
                        error: null
                    })
                })
            });
            return {
                ...state,
                suspend: suspendUser
            }
        case actions.SUSPEND_ACCOUNT_USER_SUCCESS:
            suspendUser = updateObject( state.suspend, {
                [action.account]: updateObject( state.suspend[action.account], {
                    [action.email]: ( state.suspend[action.account][action.email], {
                        suspending: false,
                        suspended: true,
                        error: null
                    })
                })
            });
            return {
                ...state,
                suspend: suspendUser
            }
        case actions.SUSPEND_ACCOUNT_USER_FAIL:
            suspendUser = updateObject( state.suspend, {
                [action.account]: updateObject( state.suspend[action.account], {
                    [action.email]: ( state.suspend[action.account][action.email], {
                        suspending: false,
                        suspended: false,
                        error: action.error
                    })
                })
            });
            return {
                ...state,
                suspend: suspendUser
            }
        case actions.CANCEL_ACCOUNT_INVITE_START:
            cancelInviteUser = updateObject( state.cancelInvite, {
                [action.account]: updateObject( state.cancelInvite[action.account], {
                    [action.email]: ( state.cancelInvite[action.account][action.email], {
                        canceling: true,
                        canceled: false,
                        error: null
                    })
                })
            });
            return {
                ...state,
                cancelInvite: cancelInviteUser
            }
        case actions.CANCEL_ACCOUNT_INVITE_SUCCESS:
            cancelInviteUser = updateObject( state.cancelInvite, {
                [action.account]: updateObject( state.cancelInvite[action.account], {
                    [action.email]: ( state.cancelInvite[action.account][action.email], {
                        canceling: false,
                        canceled: true,
                        error: null
                    })
                })
            });
            return {
                ...state,
                cancelInvite: cancelInviteUser
            }
        case actions.CANCEL_ACCOUNT_INVITE_FAIL:
            cancelInviteUser = updateObject( state.cancelInvite, {
                [action.account]: updateObject( state.cancelInvite[action.account], {
                    [action.email]: ( state.cancelInvite[action.account][action.email], {
                        canceling: false,
                        canceled: false,
                        error: action.error
                    })
                })
            });
            return {
                ...state,
                cancelInvite: cancelInviteUser
            }
        default:
            return state;
    }
}

export default userManagementReducer;