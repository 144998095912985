import * as actions from '../actions/actionTypes';
import { countPersonas } from './personaActions';
import { countDiscussions } from './discussionActions';
import { 
  emailAdminOnNewAccountCreated, 
  emailAdminOnInvitationAccepted 
} from '../../shared/emailNotifications';
import { asyncForEach } from '../../shared/utility';
import plan from '../../config/plan'
import {updateSubscriptionQuantity} from '../../shared/accounts'

export const RESEND_INVITATION_START = 'RESEND_INVITATION_START',
  RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAIL = 'RESEND_INVITATION_FAIL',
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_START = 'CHECK_ALLOW_CREATE_EGG_ACCOUNT_START',
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_SUCCESS = 'CHECK_ALLOW_CREATE_EGG_ACCOUNT_SUCCESS',
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_FAIL = 'CHECK_ALLOW_CREATE_EGG_ACCOUNT_FAIL',
  TOGGLE_SHOW_CREATE_FREE_ACCOUNT_NOT_ALLOWED = 'TOGGLE_SHOW_CREATE_FREE_ACCOUNT_NOT_ALLOWED'

export const createAccountStart = () => {
  return {
    type: actions.CREATE_ACCOUNT_START
  }
}

export const createAccountSuccess = newAccount => {
  return {
    type: actions.CREATE_ACCOUNT_SUCCESS,
    newAccount
  }
}

export const createAccountFail = ( error ) => {
  return {
    type: actions.CREATE_ACCOUNT_FAIL,
    error: error
  }
}

export const registrationSuccess = () => {
  return {
    type: actions.REG_ADDED_SUCCESS
  }
}

export const registrationFail = ( error ) => {
  return {
    type: actions.REG_ADDED_FAIL,
    error: error
  }
}

export const createAccount = (newAccount) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    dispatch(createAccountStart());

    firestore.collection('accounts').add({
      ...newAccount,
      createdAt: new Date()
    }).then(doc => {
      dispatch(createAccountSuccess(doc.id));
      if (process.env.NODE_ENV === 'production') {
        emailAdminOnNewAccountCreated(doc.id)
      }
    }).catch(err => {
      dispatch(createAccountFail(err));
    });
  }
}

export const sendInvitationStart = () => {
  return {
    type: actions.SEND_INVITATION_START
  }
}

export const sendInvitationSuccess = () => {
  return {
    type: actions.SEND_INVITATION_SUCCESS
  }
}

export const sendInvitationFail = ( error ) => {
  return {
    type: actions.SEND_INVITATION_FAIL,
    error: error
  }
}

export const sendInvitation = (data) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    dispatch(sendInvitationStart());
    // Check email is being used by existing user
    const firebase = getFirebase();
    const firestore = getFirestore();
    const auth = firebase.auth();
    auth.fetchSignInMethodsForEmail(data.email)
    .then((res) => {
      // If not empty, meaning this is an existing user
      if (res.length !== 0) {
        // Create invitations entry
        firestore.collection('invitations').add({
          accountId: data.acctId,
          accountName: data.accountName,
          accountInfo: data.accountInfo,
          name: data.name,
          email: data.email,
          role: data.role,
          accepted: false,
          createdAt: new Date(),
          invitedBy: data.invitor,
        }).then(() => {
          // Send email
          dispatch(sendInvitationSuccess());
          const regLink = `${process.env.REACT_APP_DOMAIN}`;
          const firebase = getFirebase();
          const functions = firebase.functions();
          const sendInviteEmail = functions.httpsCallable('sendEmailInvite');
          sendInviteEmail({
            account: data.accountName,
            completeRegLink: regLink,
            email: data.email
          }).then(() => {
            dispatch(sendInvitationSuccess());
          }).catch(err => {
            dispatch(sendInvitationFail(err));
          });
        }).catch(err => {
          dispatch(sendInvitationFail(err));
        });
      }
      else {
        // Non-existing user
        // Create registrations entry
        firestore.collection('registrations').add({
          email: data.email,
          name: data.name,
          completed: false,
          createdAt: new Date(),
          invited: true,
          invitedBy: data.invitor,
          invitedToAcct: data.acctId
        }).then(docRef => {
          dispatch(registrationSuccess());
          const regLink = `${process.env.REACT_APP_DOMAIN}/complete-registration/${docRef.id}`
          const firebase = getFirebase();
          const functions = firebase.functions();
          const sendInviteEmail = functions.httpsCallable('sendEmailInvite');
          // Send invitation
          sendInviteEmail({
            account: data.accountName,
            completeRegLink: regLink,
            email: data.email
          }).then(() => {
            // Create invitations entry
            firestore.collection('invitations').add({
              accountId: data.acctId,
              accountName: data.accountName,
              accountInfo: data.accountInfo,
              email: data.email,
              name: data.name,
              role: data.role,
              accepted: false,
              createdAt: new Date(),
              invitedBy: data.invitor,
            }).then(() => {
              dispatch(sendInvitationSuccess());
            });
          }).catch(err => {
            dispatch(registrationFail(err));
          })
        }).catch(err => {
          dispatch(registrationFail(err));
        });
      }
    })
    .catch((err) => {
      dispatch(sendInvitationFail(err));
    });
  }
}

export const acceptInvitationStart = () => {
  return {
    type: actions.ACCEPT_INVITATION_START
  }
}

export const acceptInvitationSuccess = () => {
  return {
    type: actions.ACCEPT_INVITATION_SUCCESS
  }
}

export const acceptInvitationFail = (error, data = {}) => {
  return {
    type: actions.ACCEPT_INVITATION_FAIL,
    error,
    data
  }
}

export const acceptInvitation = (inv) => {
  return async (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(acceptInvitationStart());
    const firestore = getFirestore();
    const firebase = getFirebase();
    const accountRef = firestore.collection('accounts').doc(inv.accountId);
    let account = await accountRef.get().then(doc => ({...doc.data(), id: doc.id}))
    // Check if accepting is within account's plan limit
    const accountSubscription = account.subscription
    let accountPlanFeatures = plan[accountSubscription.pricingCode][accountSubscription.plan]
    const acctUsers = account.users.length
    const acctSuspendedUsers = account.suspended ? account.suspended.length : 0
    if (acctUsers - acctSuspendedUsers >= accountPlanFeatures.usersLimit) {
      // Don't continue accepting invitation, prompt the "you've reached limit" modal
      const userRef = firestore.collection('users').doc(inv.invitedBy)    
      const inviter = await userRef.get().then(doc => ({...doc.data(), id: doc.id}))
      dispatch(acceptInvitationFail('subscription-limit', {
        inviter,
        inviteAcct: inv.accountName
      }))
    } else {
      let updateData;
      if (inv.role === 'editor') {
        updateData = {
          users: firebase.firestore.FieldValue.arrayUnion(inv.invitedId),
          editors: firebase.firestore.FieldValue.arrayUnion(inv.invitedId)
        }
      }
      else {
        updateData = {
          members: firebase.firestore.FieldValue.arrayUnion(inv.invitedId),
          users: firebase.firestore.FieldValue.arrayUnion(inv.invitedId),
        }
      }
      accountRef.update(updateData)
        .then(async () => {
          // Update customer subscription quantity
          account = await accountRef.get().then(doc => ({...doc.data(), id: doc.id}))
          updateSubscriptionQuantity(account)

          const invitationsRef = firestore.collection('invitations').doc(inv.id);
          invitationsRef.set({
            accepted: true,
            acceptedAt: firestore.FieldValue.serverTimestamp()
          }, { merge: true })
          .then(() => { 
            dispatch(acceptInvitationSuccess())
            if (process.env.NODE_ENV === 'production') {
              emailAdminOnInvitationAccepted(inv.invitedId, inv.id)
            }
          })
          .catch(err => { dispatch(acceptInvitationFail(err)) })
        })
        .catch(err => { dispatch(acceptInvitationFail(err)) })
    }

  }
}

export const setCurrentAccount = (accountId) => {
  return (dispatch) => {
    if (accountId) {
      // Set
      dispatch({type: actions.CURRENT_ACCOUNT_SET, account: accountId});
    }
    else {
      // Reset
      dispatch({type: actions.CURRENT_ACCOUNT_RESET});
    }
  }
}

const saveAccountStart = () => {
  return {
    type: actions.SAVE_ACCT_START
  }
}

const saveAccountSuccess = () => {
  return {
    type: actions.SAVE_ACCT_SUCCESS
  }
}

const saveAccountFail = error => {
  return {
    type: actions.SAVE_ACCT_FAIL,
    error: error
  }
}

export const saveEditAccount = ({
  name,
  desc,
  id
}) => {
  return async (dispatch, getState, {getFirestore}) => {
    dispatch(saveAccountStart());
    const firestore = getFirestore();
    try {
      const updateData = {
        name,
        description: desc
      }
      await updateAccount(firestore, updateData, id);
      dispatch(saveAccountSuccess())
    } catch (error) {
      dispatch(saveAccountFail(error.message))
    }
  }
}

export const updateAccount = async (firestore, data, id) => {
  const acct = firestore.collection('accounts').doc(id);
  return await acct.update(data);
}

export const updateAccountPersonaCount = async (firestore, personasCol, acct) => {
  const count = await countPersonas(firestore, personasCol);
  try {
    const updateData = {
      personas: count
    }
    await updateAccount(firestore, updateData, acct);
  } catch (error) {
    console.log(error)
  }
}

export const updateAccountConversationsCount = async (firestore, discussionsCol, acct) => {
  const count = await countDiscussions(firestore, discussionsCol);
  try {
    const updateData = {
      conversations: count
    }
    await updateAccount(firestore, updateData, acct);
  } catch (error) {
    console.log(error)
  }
}

const resendInvitationStart = email => {
  return {
    type: RESEND_INVITATION_START,
    email
  }
}

const resendInvitationSuccess = () => {
  return {
    type: RESEND_INVITATION_SUCCESS
  }
}

const resendInvitationFail = error => {
  return {
    type: RESEND_INVITATION_FAIL,
    error
  }
}

export const resendInvitation = data => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {
    dispatch(resendInvitationStart(data.email));
    try {
      const firebase = getFirebase()
      const db = getFirestore()
      const auth = firebase.auth()
      const functions = firebase.functions()
      const sendInviteEmail = functions.httpsCallable('sendEmailInvite')
      const inviteeData = {
        account: data.accountName,
        completeRegLink: `${process.env.REACT_APP_DOMAIN}`,
        email: data.email
      }
      const users = await auth.fetchSignInMethodsForEmail(data.email)
      if (users.length !== 0) {
        // 'This is an existing user'
        await sendInviteEmail(inviteeData)
        dispatch(resendInvitationSuccess())
      } else {
        // This is a user with pending invitation
        const registrationRef = db.collection("registrations")
        const queryRegistration = registrationRef.where("email", "==", data.email)
        const resultRegistration = await queryRegistration.get()
        let registrations = []
        resultRegistration.forEach(doc => {
          registrations.push(doc.id)
        })
        await asyncForEach(registrations, async regId => {
          inviteeData.completeRegLink = `${process.env.REACT_APP_DOMAIN}/complete-registration/${regId}`
          await sendInviteEmail(inviteeData)
          dispatch(resendInvitationSuccess())
        })
      }
    } catch (error) {
      dispatch(resendInvitationFail(error));
    }
  }
}

const checkAllowCreateFreeAccountStart = () => {
  return {
    type: CHECK_ALLOW_CREATE_EGG_ACCOUNT_START,
  }
}

const checkAllowCreateFreeAccountSuccess = (allow, freeAccounts) => {
  return {
    type: CHECK_ALLOW_CREATE_EGG_ACCOUNT_SUCCESS,
    allow,
    freeAccounts
  }
}

const checkAllowCreateFreeAccountFail = error => {
  return {
    type: CHECK_ALLOW_CREATE_EGG_ACCOUNT_FAIL,
    error
  }
}

export const checkAllowCreateFreeAccount = uid =>
  async (dispatch, getState, {getFirestore}) => {
    dispatch(checkAllowCreateFreeAccountStart())
    const db = getFirestore()
    try {
      let allow = true, freeAccounts = 0
      // const userAccounts = getState().firestore.data.currentUserAccounts
      const accountsRef = db.collection('accounts')
      const accountsQuery = accountsRef.where('owners', 'array-contains', uid)
      const ownedAccounts = await accountsQuery.get()
      if (ownedAccounts.size > 0) {
        ownedAccounts.forEach(ownedAccount => {
          const account = ownedAccount.data()
          if ((!account.subscription || account.subscription.plan === 'egg')) {
            freeAccounts++
          }
        })
        allow = freeAccounts < 3
      }
      dispatch(checkAllowCreateFreeAccountSuccess(allow, freeAccounts))
    } catch (error) {
      console.log(error)
      dispatch(checkAllowCreateFreeAccountFail(error))
    }
  }

export const displayCreateFreeAccountNotAllowedPrompt = () => 
  dispatch => {
    dispatch({ type: TOGGLE_SHOW_CREATE_FREE_ACCOUNT_NOT_ALLOWED })
  }