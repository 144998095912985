import * as actions from '../actions/actionTypes';

const initState = {
  authError: null,
  registrationaAddError: null,
  signingUp: false,
  signingIn: false,
  forgotPassword: {},
  resetPasswordCodeVerify: {},
  passwordReset: {},
  successResetPassword: false
}

const authReducer = (state = initState, action) => {
  switch(action.type){
    case actions.LOGIN_START:
      return {
        ...state,
        signingIn: true,
        authError: null
      }
    case actions.LOGIN_ERROR:
      return {
        ...state,
        signingIn: false,
        authError: action.error.message
      }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        signingIn: false,
        authError: null
      }
    case actions.SIGNOUT_SUCCESS:
      return state;
      
    case actions.SIGNUP_START:
      return {
        ...state,
        authError: null,
        signingUp: true,
      }
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        authError: null,
        signingUp: false,
      }
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        signingUp: false,
        authError: action.error.message
      }
    case actions.SET_USER_PROFILE_SUCCESS:
      console.log('User profile set successful.')
      return state;

    case actions.SET_USER_PROFILE_FAILED:
      console.log('User profile set failed.', action.err.message)
      return state;

    case actions.SET_USERS_DATA_FAILED:
      console.log('SET_USERS_DATA_FAILED', action.err.message)
      return state;

    case actions.SET_USERS_DATA_SUCCESS:
      console.log('SET_USERS_DATA_SUCCESS')
      return state;

    case actions.REG_COMPLETED_SUCCESS:
      console.log('Registration is completed.')
      return state;

    case actions.REG_COMPLETED_FAILED:
      console.log('Registration is incomplete.', action.err.message);
      return state;

    case actions.REG_ADDED_SUCCESS:
      return {
        ...state,
        registrationaAddError: null
      }
    case actions.REG_ADDED_FAIL:
      return {
        ...state,
        registrationaAddError: action.error
      }
    case actions.FORGOT_PASSWORD_REQ_START:
      return {
        ...state,
        forgotPassword: {
          requesting: true,
          requested: false,
          error: null
        }
      }
    case actions.FORGOT_PASSWORD_REQ_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          requesting: false,
          requested: true,
          error: null
        }
      }
    case actions.FORGOT_PASSWORD_REQ_FAIL:
      return {
        ...state,
        forgotPassword: {
          requesting: false,
          requested: false,
          error: action.error
        }
      }
    case actions.RESET_PASSCODE_VERIFY_START:
        return {
          ...state,
          resetPasswordCodeVerify: {
            verifying: true,
            verified: false,
            error: null
          }
        }
    case actions.RESET_PASSCODE_VERIFY_SUCCESS:
        return {
          ...state,
          resetPasswordCodeVerify: {
            verifying: false,
            verified: true,
            error: null
          }
        }
    case actions.RESET_PASSCODE_VERIFY_FAIL:
        return {
          ...state,
          resetPasswordCodeVerify: {
            verifying: false,
            verified: false,
            error: action.error
          }
        }
    case actions.PASSWORD_RESET_START:
        return {
          ...state,
          passwordReset: {
            resetting: true,
            reset: false,
            error: null
          }
        }
    case actions.PASSWORD_RESET_SUCCESS:
        return {
          ...state,
          passwordReset: {
            resetting: false,
            reset: true,
            error: null
          }
        }
    case actions.PASSWORD_RESET_FAIL:
        return {
          ...state,
          passwordReset: {
            resetting: false,
            reset: false,
            error: action.error
          }
        }
    case actions.RESET_PASSWORD_SUCCESSFUL:
        return {
          ...state,
          successResetPassword: action.set
        }
    default:
      return state
  }
};

export default authReducer;