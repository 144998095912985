import * as actions from '../actions/actionTypes';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import * as stores from '../../shared/stores';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniqBy';
import reject from 'lodash/reject';

import { updateObject } from '../../shared/utility';
import concat from 'lodash/concat';
import { getAffectedPersona } from './personaActions';
import { logSearchIndex } from '../../shared/search'
import {updateAccountConversationsCount} from './accountActions'
import uniqBy from 'lodash/uniqBy';
import { getAllNegativeLabelsInConvos } from '../../shared/queries';

const createNewTagStart = key => {
  return {
    type: actions.DISCUSSION_CREATE_NEW_TAG_START,
    tagType: key
  }
}

const createNewTagSuccess = (key, newData) => {
  return {
    type: actions.DISCUSSION_CREATE_NEW_TAG_SUCCESS,
    tagType: key,
    newData
  }
}

const createNewTagFail = (key, error) => {
  return {
    type: actions.DISCUSSION_CREATE_NEW_TAG_FAIL,
    tagType: key,
    error
  }
}

export const createNewTag = newTagData => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    const tagType = newTagData.tagType;
    dispatch(createNewTagStart(tagType));
    const firestore = getFirestore();
    let db;
    const curAcct = newTagData.acctId;
    switch (tagType) {
      case 'interests':
        db = `${curAcct}_interests`;
        break;
      case 'challenges':
        db = `${curAcct}_challenges`;
        break;
      case 'solutions':
        db = `${curAcct}_solutions`;
        break;
      default:
        db = `${curAcct}_interests`;
    }
    firestore.collection(db).add({
      accountId: newTagData.acctId,
      value: newTagData.value,
      label: newTagData.label,
      description: newTagData.description || null,
      parent: newTagData.parent || null,
      createdOn: firestore.FieldValue.serverTimestamp(),
      lastUpdated: firestore.FieldValue.serverTimestamp(),
      deleted: false
    }).then(docRef => {
      docRef.get().then(doc => {
        if (doc.exists) {
          const newTag = {...doc.data(), id: doc.id};
          dispatch(createNewTagSuccess(tagType, newTag));
          // Log search index
          logSearchIndex(curAcct, tagType);
        }
        else dispatch(createNewTagFail(tagType, { message: 'Document does not exist' }));
      }).catch(err => { dispatch(createNewTagFail(tagType, err)); })
    }).catch(err => {
      dispatch(createNewTagFail(tagType, err));
    });
  }
}

const addPostStart = () => {
  return {
    type: actions.ADD_POST_START,
  }
}

const addPostSuccess = newPost => {
  return {
    type: actions.ADD_POST_SUCCESS,
    newPost
  }
}

const addPostFail = error => {
  return {
    type: actions.ADD_POST_FAIL,
    error
  }
}

export const addPostOld = newPostData => {
  return (dispatch, getState, {getFirestore}) => {
    dispatch(addPostStart());
    const curAcct = newPostData.acctId,
      personaId = newPostData.currentPersona || 'unassigned';
    const firestore = getFirestore();
    const addPostBatch = firestore.batch();
    /**
     * Programmatically create write batch per field specified in the array below
     * If context field has value, a field which is required
     * create a document with submitted data, add it to the batch
     * and assign the resulting document id to a variable.
     * Also true with quotes and influences if each is present.
     */
    let postFields = {
      context: {
        collection: `${curAcct}_contexts`,
        value: null,
        type: 'context'
      },
      quoteAction: {
        collection: `${curAcct}_quotes`,
        value: null,
        type: 'action'
      },
      quotePerception: {
        collection: `${curAcct}_quotes`,
        value: null,
        type: 'perception'
      },
      influenceAction: {
        collection: `${curAcct}_influences`,
        value: null,
        type: 'action'
      },
      influencePerception: {
        collection: `${curAcct}_influences`,
        value: null,
        type: 'perception'
      }
    };

    Object.keys(postFields).forEach(field => {
      if (!isEmpty(newPostData[field])) {
        const fieldDocRef = firestore.collection(postFields[field].collection).doc();
        const fieldData = {
          type: postFields[field].type,
          value: newPostData[field],
          author: newPostData.userId,
          hearts: [],
          comments: [],
          createdOn: firestore.FieldValue.serverTimestamp(),
          lastUpdated: firestore.FieldValue.serverTimestamp(),
        }
        addPostBatch.set(fieldDocRef, fieldData);
        postFields = updateObject(postFields, {
          [field]: updateObject(postFields[field], {
            value: fieldDocRef.id
          })
        })
      }
    })
    /**
     * Create a transaction to get the count of all discussions assigned to current persona.
     * After the transaction is done, pass the count value and perform batch.
     * Data will be used to compute the id number of post created after batch write
     * */

    firestore.collection(`${curAcct}_discussions`)
      .where("persona", "==", personaId)
      .get()
        .then(querySnapshot => {
          const discussionCount = querySnapshot ? querySnapshot.size : 0
          // Execute batch
          addPostBatch.commit()
            .then(() => {
              const discussionRef = firestore.collection(`${curAcct}_discussions`);
              const discussionData = {
                tags: newPostData.tags,
                context: postFields.context.value,
                persona: personaId,
                postId: discussionCount + 1,
                author: newPostData.userId,
                quotes: {
                  primary: {
                    action: postFields.quoteAction.value,
                    perception: postFields.quotePerception.value
                  },
                  all: compact([
                    postFields.quoteAction.value,
                    postFields.quotePerception.value
                  ])
                },
                influences: {
                  primary: {
                    action: postFields.influenceAction.value,
                    perception: postFields.influencePerception.value
                  },
                  all: compact([
                    postFields.influenceAction.value,
                    postFields.influencePerception.value
                  ])
                },
                elementsOrder: newPostData.elementsOrder,
                createdOn: firestore.FieldValue.serverTimestamp(),
                lastUpdated: firestore.FieldValue.serverTimestamp(),
                lastActivity: firestore.FieldValue.serverTimestamp(),
              };
              discussionRef.add(discussionData)
                .then(docRef => {
                  docRef.get().then(doc => {
                    if (doc.exists) {
                      const newPostDoc = doc.id;
                      // Create batch to add new post id to elements
                      const updateDiscussionElements = firestore.batch();
                      Object.keys(postFields).forEach(field => {
                        const curField = postFields[field];
                        if (!isEmpty(curField.value)) {
                          updateDiscussionElements.update(firestore.collection(curField.collection).doc(curField.value), {
                            discussionId: newPostDoc,
                          });
                        }
                      });
                      // Add discussion ID to persona document
                      if (personaId !== 'unassigned') {
                        updateDiscussionElements.update(firestore.collection(`${curAcct}_personas`).doc(personaId), {
                          discussions: firestore.FieldValue.arrayUnion(newPostDoc),
                        })
                      }
                      updateDiscussionElements.commit()
                        .then(() => {
                          dispatch(addPostSuccess({...doc.data(), id: newPostDoc}));
                        })
                        .catch(err => {
                          dispatch(addPostFail(err));
                        })
                    }
                    else dispatch(addPostFail({ message: 'Document does not exist' }));
                  }).catch(err => { dispatch(addPostFail(err)); })
                })
                .catch(err => { dispatch(addPostFail(err)); });
            })
            .catch(err => { dispatch(addPostFail(err)); });
        })
        .catch(err => { dispatch(addPostFail(err)); });
  }
}

export const addPost = newPostData => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(addPostStart());
    const curAcct = newPostData.acctId,
      personaId = newPostData.currentPersona || 'unassigned';
    const firestore = getFirestore();
    const addPostBatch = firestore.batch();
    /**
     * Programmatically create write batch per field specified in the array below
     * If context field has value, a field which is required
     * create a document with submitted data, add it to the batch
     * and assign the resulting document id to a variable.
     * Also true with quotes and influences if each is present.
     */
    // Initialize a variable to keep IDs of elements in order
    let elementsIds = [], postElements = {}, newPostElements = [];
    
    // Loop elementsOrder + context from form data submitted
    const contextElement = {
      id: 'context', 
      collection: 'contexts',
      type: 'context'
    };
    const newElementsOrder = [contextElement, ...newPostData.elementsOrder];
    newElementsOrder.forEach((element, idx) => {
      if (newPostData.elementsValue[element.id]) {
        const collectionToSave = `${curAcct}_${element.collection}`;
        const fieldDocRef = firestore.collection(collectionToSave).doc();
        const fieldData = {
          value: newPostData.elementsValue[element.id],
          author: newPostData.userId,
          hearts: [],
          comments: [],
          createdOn: firestore.FieldValue.serverTimestamp(),
          lastUpdated: firestore.FieldValue.serverTimestamp(),
          seenBy: [newPostData.userId]
        }
        addPostBatch.set(fieldDocRef, fieldData);
        // Add the ID to the elementsId, our basis for elementsOrder, except context
        if (element.type !== 'context') {
          elementsIds.push({
            id: fieldDocRef.id,
            type: element.type
          }); 
        }
        // Add the ID to postElements Object to be used in creating Discussion
        if (!postElements[element.type]) {
          postElements[element.type] = []
        }
        postElements[element.type].push({
          primary: fieldDocRef.id,
          suggestions: []
        })
        // Edit the newPostData.elementsOrder from form data so that
        // id is no longer the random generated id but the new document ID
        // This will be used later in editing each element with the new Discussion ID
        newPostElements.push({
          ...newElementsOrder[idx],
          id: fieldDocRef.id
        })
      }
      else {
        newPostElements.push(null)
      }
    })
    /**
     * Create a transaction to get the count of all discussions assigned to current persona.
     * After the transaction is done, pass the count value and perform batch.
     * Data will be used to compute the id number of post created after batch write
     * */
    const discussionsCol = `${curAcct}_discussions`
    firestore.collection(discussionsCol)
      .where("persona", "==", personaId)
      .get()
        .then(querySnapshot => {
          const discussionCount = querySnapshot ? querySnapshot.size : 0
          // Execute batch
          addPostBatch.commit()
            .then(() => {
              const discussionRef = firestore.collection(discussionsCol);
              const discussionData = {
                tags: newPostData.tags,
                context: postElements.context[0].primary,
                persona: personaId,
                postId: discussionCount + 1,
                author: newPostData.userId,
                thoughts: postElements.thought || [],
                actions: postElements.action || [],
                influences: postElements.influence || [],
                elementsOrder: elementsIds,
                createdOn: firestore.FieldValue.serverTimestamp(),
                lastUpdated: firestore.FieldValue.serverTimestamp(),
                lastActivity: firestore.FieldValue.serverTimestamp(),
              };
              discussionRef.add(discussionData)
                .then(docRef => {
                  docRef
                  .get()
                  .then(async doc => {
                    if (doc.exists) {
                      // const newPostDoc = doc.id;
                      const newPost = {...doc.data(), id: doc.id}
                      // Create batch to add new post id to elements
                      const updateDiscussionElements = firestore.batch();
                      compact(newPostElements).forEach(element => {
                        const collectionToSave = `${curAcct}_${element.collection}`;
                        updateDiscussionElements.update(firestore.collection(collectionToSave).doc(element.id), {
                          discussionId: newPost.id,
                        });
                      })

                      // Add discussion ID to persona document
                      if (personaId !== 'unassigned') {
                        let personaRef = firestore.collection(`${curAcct}_personas`).doc(personaId), 
                        interests, challenges, solutions, interestsNegative
                        if (newPost.tags && (newPost.tags.challenges || newPost.tags.interests)) {
                          const selectedPersona = await personaRef.get().then(doc => ({...doc.data(), id: doc.id}))
                          interests = [...(reject(newPost.tags.interests, ['negative', true]) || []), ...(selectedPersona.interests || [])]
                          interestsNegative = await getAllNegativeLabelsInConvos(discussionsCol, personaId, 'interests')
                          challenges = [...(newPost.tags.challenges || []), ...(selectedPersona.challenges || [])]
                          solutions = [...(newPost.tags.solutions || []), ...(selectedPersona.solutions || [])]
                        }
                        updateDiscussionElements.update(personaRef, {
                          challenges: uniqBy(challenges, 'id'),
                          interests: uniqBy(interests, 'id'),
                          interestsNegative: uniqBy(interestsNegative, 'id'),
                          solutions: uniqBy(solutions, 'id'),
                          discussions: firestore.FieldValue.arrayUnion(newPost.id),
                          lastUpdated: firestore.FieldValue.serverTimestamp(),
                        })
                      }
                      updateDiscussionElements.commit()
                        .then(() => {
                          dispatch(addPostSuccess(newPost))
                          // Log search index
                          logSearchIndex(curAcct, 'discussions');
                          uniq(compact(newPostElements), 'collection').forEach(element => {
                            logSearchIndex(curAcct, element.collection);
                          })
                          // Update conversations count for this account
                          updateAccountConversationsCount(firestore, discussionsCol, curAcct)
                        })
                        .catch(err => {
                          dispatch(addPostFail(err));
                        })
                    }
                    else dispatch(addPostFail({ message: 'Document does not exist' }));
                  })
                  .catch(err => { dispatch(addPostFail(err)); })
                })
                .catch(err => { dispatch(addPostFail(err)); });
            })
            .catch(err => { dispatch(addPostFail(err)); });
        })
        .catch(err => { dispatch(addPostFail(err)); });
  }
}

const addPostElementsStart = postId => {
  return {
    type: actions.ADD_POST_ELEMENTS_START,
    postId
  }
}

const addPostElementsSuccess = postId => {
  return {
    type: actions.ADD_POST_ELEMENTS_SUCCESS,
    postId
  }
}

const addPostElementsFail = (postId, error) => {
  return {
    type: actions.ADD_POST_ELEMENTS_FAIL,
    error,
    postId
  }
}

export const addPostElements = ({
  acctId,
  currentPersona,
  userId,
  elementsValue,
  elementsOrder,
  discussionId,
}) => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(addPostElementsStart(discussionId));
    const firestore = getFirestore();
    const addPostElementsBatch = firestore.batch();
    /**
     * Programmatically create write batch per field specified in the array below
     * If context field has value, a field which is required
     * create a document with submitted data, add it to the batch
     * and assign the resulting document id to a variable.
     * Also true with quotes and influences if each is present.
     */
    // Initialize a variable to keep IDs of elements in order
    let elementsIds = [], addedElements = {};

    elementsOrder.forEach((element, idx) => {
      if (elementsValue[element.id]) {
        const collectionToSave = `${acctId}_${element.collection}`;
        const fieldDocRef = firestore.collection(collectionToSave).doc();
        const fieldData = {
          value: elementsValue[element.id],
          author: userId,
          hearts: [],
          comments: [],
          createdOn: firestore.FieldValue.serverTimestamp(),
          lastUpdated: firestore.FieldValue.serverTimestamp(),
          seenBy: [userId],
          discussionId
        }
        addPostElementsBatch.set(fieldDocRef, fieldData);
        // Add the ID to the elementsId, our basis for elementsOrder, except context
        elementsIds.push({
          id: fieldDocRef.id,
          type: element.type
        });
        // Add the ID to postElements Object to be used in creating Discussion
        if (!addedElements[element.type]) {
          addedElements[element.type] = []
        }
        addedElements[element.type].push({
          primary: fieldDocRef.id,
          suggestions: []
        })
      }
    })

    addPostElementsBatch.commit()
      .then(() => {
        // Log search index
        uniq(compact(elementsOrder), 'collection').forEach(element => {
          logSearchIndex(acctId, element.collection);
        })
        const discussionRef = firestore.collection(`${acctId}_discussions`).doc(discussionId);
        discussionRef.get()
          .then(doc => {
            if (!doc.exists) {
              dispatch(addPostElementsFail(discussionId, { message: 'Document doesn\'t exist.'}));
            }
            const discussion = doc.data();
            const updatedActions = compact(concat(discussion.actions, addedElements.action));
            const updatedInfluences = compact(concat(discussion.influences, addedElements.influence));
            const updatedThoughts = compact(concat(discussion.thoughts, addedElements.thought));
            const updatedElementsOrder = concat(discussion.elementsOrder, elementsIds)

            discussionRef.update({
              actions: updatedActions,
              influences: updatedInfluences,
              thoughts: updatedThoughts,
              elementsOrder: updatedElementsOrder,
              lastActivity: firestore.FieldValue.serverTimestamp(),
              lastUpdated: firestore.FieldValue.serverTimestamp(),
            })
            .then(async () => {
              dispatch(addPostElementsSuccess(discussionId));
              // Log search index
              logSearchIndex(acctId, 'discussions');
              const commentedPersona = await getAffectedPersona('discussions', acctId, discussionId, firestore);
              if (commentedPersona && commentedPersona !== 'unassigned') {
                firestore.collection(`${acctId}_personas`).doc(commentedPersona).update({
                  lastUpdated: firestore.FieldValue.serverTimestamp(),
                })
              }
            })
            .catch(error => {
              dispatch(addPostElementsFail(discussionId, error));
            })
          })
          .catch(err => {
            dispatch(addPostElementsFail(discussionId, err));
          })
      })
      .catch(err => {
        dispatch(addPostElementsFail(discussionId, err));
      })
  }
}

export const selectPost = ({id, post}) => {
  return dispatch => {
    if (id) {
      let relatedTags = [], tagTypes = [stores.INTERESTS, stores.CHALLENGES, stores.SOLUTIONS];
      tagTypes.forEach(type => {
        relatedTags.push(map(post.tags[type], label => { 
          return label.negative ? `${label.id}-negative` : label.id
        }))
      });
      dispatch({
        type: actions.SELECTED_POST_LABEL_SET,
        id: null,
        related: flatten(relatedTags),
        posts: [],
        postId: id,
        personas: [post.persona]
      });
    }
  }
}

const editPostElementStart = postElementId => {
  return {
    type: actions.EDIT_POST_ELEMENT_START,
    postElementId
  }
}

const editPostElementSuccess = postElementId => {
  return {
    type: actions.EDIT_POST_ELEMENT_SUCCESS,
    postElementId
  }
}

const editPostElementFail = (postElementId, error) => {
  return {
    type: actions.EDIT_POST_ELEMENT_FAIL,
    postElementId,
    error
  }
}

export const editPostElement = ({
  postElementId,
  acctId,
  postElementCol,
  newValue,
  discussionId
}) => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(editPostElementStart(postElementId));
    const db = getFirestore();
    const editPostRef = db.collection(`${acctId}_${postElementCol}`).doc(postElementId);
    editPostRef.update({
      value: newValue,
      lastUpdated: db.FieldValue.serverTimestamp(),
      discussionId
    }).then(() => {
      const postRef = db.collection(`${acctId}_discussions`).doc(discussionId);
      postRef.update({
        lastActivity: db.FieldValue.serverTimestamp(),
      }).then(async () => {
        dispatch(editPostElementSuccess(postElementId));
        // Log search index
        logSearchIndex(acctId, postElementCol);
        const commentedPersona = await getAffectedPersona('discussions', acctId, discussionId, db);
        if (commentedPersona && commentedPersona !== 'unassigned') {
          db.collection(`${acctId}_personas`).doc(commentedPersona).update({
            lastUpdated: db.FieldValue.serverTimestamp(),
          })
        }
      }).catch(err => {
        dispatch(editPostElementFail(postElementId, err));
      })
    }).catch(err => {
      dispatch(editPostElementFail(postElementId, err));
    })
  }
}

const editPostTagsStart = postId => {
  return {
    type: actions.EDIT_POST_TAGS_START,
    postId
  }
}

const editPostTagsSuccess = postId => {
  return {
    type: actions.EDIT_POST_TAGS_SUCCESS,
    postId
  }
}

const editPostTagsFail = (postId, error) => {
  return {
    type: actions.EDIT_POST_TAGS_FAIL,
    postId,
    error
  }
}

export const editPostTags = ({
  postId,
  acctId,
  tags
}) => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(editPostTagsStart(postId));
    const db = getFirestore();
    const discussionsCol = `${acctId}_discussions`
    const editPostRef = db.collection(discussionsCol).doc(postId);
    editPostRef.update({
      tags: tags,
      lastUpdated: db.FieldValue.serverTimestamp(),
      lastActivity: db.FieldValue.serverTimestamp(),
    }).then(async () => {
      dispatch(editPostTagsSuccess(postId));
      // Log search index
      logSearchIndex(acctId, 'discussions');
      // Update persona
      const editedPersona = await getAffectedPersona('discussions', acctId, postId, db);
      if (editedPersona && editedPersona !== 'unassigned') {
        let personaRef = db.collection(`${acctId}_personas`).doc(editedPersona), 
          interests, challenges, solutions, interestsNegative
        if (tags && (tags.challenges || tags.interests || tags.solutions)) {
          const selectedPersona = await personaRef.get().then(doc => ({...doc.data(), id: doc.id}))
          interests = [...(reject(tags.interests, ['negative', true]) || []), ...(selectedPersona.interests || [])]
          interestsNegative = await getAllNegativeLabelsInConvos(discussionsCol, editedPersona, 'interests')
          challenges = [...(tags.challenges || []), ...(selectedPersona.challenges || [])]
          solutions = [...(tags.solutions || []), ...(selectedPersona.solutions || [])]
        }
        await personaRef.update({
          challenges: uniqBy(challenges, 'id'),
          interests: uniqBy(interests, 'id'),
          interestsNegative: uniqBy(interestsNegative, 'id'),
          solutions: uniqBy(solutions, 'id'),
          lastUpdated: db.FieldValue.serverTimestamp(),
        })
        // Log search index
        logSearchIndex(acctId, 'personas');
      }
    }).catch(err => {
      dispatch(editPostTagsFail(postId, err));
    })
  }
}

const reorderPostElementsStart = postId => {
  return {
    type: actions.REORDER_POSTELS_START,
    postId
  }
}

const reorderPostElementsSuccess = postId => {
  return {
    type: actions.REORDER_POSTELS_SUCCESS,
    postId
  }
}

const reorderPostElementsFail = (postId, error) => {
  return {
    type: actions.REORDER_POSTELS_FAIL,
    postId,
    error
  }
}

export const reorderPostElements = ({
  postId,
  acctId,
  newOrder
}) => {
  return (dispatch, getState, {getFirestore}) => {
    dispatch(reorderPostElementsStart(postId));
    const db = getFirestore();
    const editPostRef = db.collection(`${acctId}_discussions`).doc(postId);
    editPostRef.update({
      elementsOrder: newOrder,
      lastUpdated: db.FieldValue.serverTimestamp(),
      lastActivity: db.FieldValue.serverTimestamp(),
    }).then(async () => {
      dispatch(reorderPostElementsSuccess(postId));
      const commentedPersona = await getAffectedPersona('discussions', acctId, postId, db);
      if (commentedPersona && commentedPersona !== 'unassigned') {
        db.collection(`${acctId}_personas`).doc(commentedPersona).update({
          lastUpdated: db.FieldValue.serverTimestamp(),
        })
      }
    }).catch(err => {
      dispatch(reorderPostElementsFail(postId, err));
    })
  }
}

export const countDiscussions = async (firestore, discussionsCol) => {
  try {
    const discussionsRef = firestore.collection(discussionsCol);
    return await discussionsRef.get().then(querySnapshot => querySnapshot.size)
  } catch (error) {
    console.log(error);
    return 0;
  }
}