import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import AppRoute from '../Layout/AppRoute';
import MainLayout from '../Layout/MainLayout/MainLayout';
import AltLayout from '../Layout/AltLayout/AltLayout';
import routerSplit from '../../shared/codeSplit';
import Wrap from '../Wrap/Wrap';
import RouteOnModal from '../../components/UI/Modal/RouteOnModal/RouteOnModal';
import { PhoneScreen, PhoneScreenHidden } from 'react-responsive-redux';

const CreateAccount = routerSplit('containers/Accounts/CreateAccount/CreateAccount');
const SignIn = routerSplit('containers/Auth/Signin/Signin');
const Forgot = routerSplit('containers/Auth/Password/ForgotPass');
const Reset = routerSplit('containers/Auth/Password/ResetPass');
const Registration = routerSplit('containers/Auth/Registration/Registration');
const Dashboard = routerSplit('containers/Dashboard/Dashboard');
const Main = routerSplit('containers/Main/Main');
const Demo = routerSplit('containers/Demo/Demo');
const PremiumSubscription = routerSplit('components/Subscription/PremiumSubscription');
const AccountsManagement = routerSplit('containers/Accounts/AccountsManagement/AccountsManagement');
const Example = routerSplit('components/Example/Example.js')

// const AccountPage = routerSplit('containers/Accounts/AccountPage');
const demoLink = process.env.REACT_APP_DEMO_LINK;

const appRouteSwitcher = props => {
  if (props.isAuthenticated) {
    const { location } = props;
    const isModal = (!!(location.state && location.state.modal));
    return (
      <Wrap>
        <Switch location={isModal ? { pathname: '/' } : location}>
          <AppRoute exact path='/example' layout={ MainLayout } component={Example} />
          <AppRoute exact path='/accounts' layout={ MainLayout } component={AccountsManagement} />
          {/* <AppRoute path='/accounts/:id' layout={ MainLayout } component={AccountsManagement} /> */}
          {/* <AppRoute path='/persona-file/:id/manage-features' layout={ MainLayout } component={ManageFeatures} /> */}
          <AppRoute path="/persona-file/:id" layout={ MainLayout } component={ Main } />
          <AppRoute path="/premium-subscription" layout={ AltLayout } component={ PremiumSubscription }  layoutProps={{wide: true}}/>
          <AppRoute path={demoLink} layout={ AltLayout } component={ Demo } />
          <AppRoute exact path="/" layout={ MainLayout } component={ Dashboard } />
          <Redirect to="/" />
        </Switch>
        <PhoneScreen>
          <RouteOnModal
            path={`/account/create`}
            parentPath='/'
            size='full'
            component={ CreateAccount }
            title="Create New Account"/>
        </PhoneScreen>
        <PhoneScreenHidden>
          <RouteOnModal
            path={`/account/create`}
            parentPath='/'
            component={ CreateAccount }
            title="Create New Account"/>
        </PhoneScreenHidden>

      </Wrap>
    );
  }
  else {
    return (
      <Wrap>
        <Switch location={props.location}>
          <AppRoute path="/signin" layout={ AltLayout } component={ SignIn } />
          <AppRoute path="/forgot-password" layout={ AltLayout } component={ Forgot } />
          <AppRoute path="/reset-password/:code" layout={ AltLayout } component={ Reset } />
          <AppRoute path="/complete-registration/:id" layout={ AltLayout } component={ Registration } />
          <AppRoute path={demoLink} layout={ AltLayout } component={ Demo } />
          <Redirect to="/signin" />
        </Switch>
      </Wrap>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps)(appRouteSwitcher);