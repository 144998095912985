import * as actions from '../actions/actionTypes';
import {
  RESEND_INVITATION_START,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAIL,
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_START,
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_SUCCESS,
  CHECK_ALLOW_CREATE_EGG_ACCOUNT_FAIL,
  TOGGLE_SHOW_CREATE_FREE_ACCOUNT_NOT_ALLOWED
} from '../actions/accountActions'

const initState = {
  creatingAccount: false,
  createAccountError: null,
  newAccountCreated: null,
  sendingInvitation: false,
  sendInvitationError: null,
  acceptingInvitation: false,
  acceptInvitationError: null,
  account: null,
  editingAccount: false,
  editAccountError: null,
  resendingInvitation: false,
  resendInvitationError: null,
  resentInvitation: false,
  resentInviteEmail: null,
  acceptInvitationData: null,
  fetchingAccounts: false,
  fetchedAccounts: false,
  fetchAccountsError: null,
  accountsFetched: [],
  billingInfosFetched: [],
  customersData: [],
  totalNumAccts: 0,
  // accountsPages: 0
  checkingAllowCreate: false,
  checkedAllowCreate: false,
  allowCreateFreeAccount: false,
  checkAllowCreateError: null,
  freeAccountsOwned: 0,
  showCreateFreeAccountNotAllowedPrompt: false
}

const accountReducer = ( state = initState, action ) => {
  switch (action.type) {
    case actions.CREATE_ACCOUNT_START:
      return {
        ...state,
        newAccountCreated: null,
        creatingAccount: true
      }
    case actions.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        newAccountCreated: action.newAccount,
        creatingAccount: false
      }
    case actions.CREATE_ACCOUNT_FAIL:
      return {
        ...state,
        creatingAccount: false,
        createAccountError: action.error
      }
    case actions.SEND_INVITATION_START:
      return {
        ...state,
        sendingInvitation: true,
        sendInvitationError: null
      }
    case actions.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        sendingInvitation: false,
        sendInvitationError: null
      }
    case actions.SEND_INVITATION_FAIL:
      return {
        ...state,
        sendingInvitation: false,
        sendInvitationError: action.error.message,
      }
    case RESEND_INVITATION_START:
      return {
        ...state,
        resendingInvitation: true,
        resentInvitation: false,
        resendInvitationError: null,
        resentInviteEmail: action.email
      }
    case RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        resendingInvitation: false,
        resentInvitation: true,
        resendInvitationError: null
      }
    case RESEND_INVITATION_FAIL:
      return {
        ...state,
        resendingInvitation: false,
        resentInvitation: false,
        resendInvitationError: action.error.message,
      }
    case actions.ACCEPT_INVITATION_START:
      return {
        ...state,
        acceptingInvitation: true,
        acceptInvitationError: null,
        acceptInvitationData: null
      }
    case actions.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptingInvitation: false
      }
    case actions.ACCEPT_INVITATION_FAIL:
      return {
        ...state,
        acceptingInvitation: false,
        acceptInvitationError: action.error,
        acceptInvitationData: action.data
      }
    case actions.CURRENT_ACCOUNT_SET:
      return {
        ...state,
        account: action.account
      }
    case actions.CURRENT_ACCOUNT_RESET:
      return {
        ...state,
        account: null
      }
    case actions.SAVE_ACCT_START:
      return {
        ...state,
        editingAccount: true,
        editAccountError: null
      }
    case actions.SAVE_ACCT_SUCCESS:
      return {
        ...state,
        editingAccount: false,
        editAccountError: null
      }
    case actions.SAVE_ACCT_FAIL:
      return {
        ...state,
        editingAccount: false,
        editAccountError: action.error
      }
    case CHECK_ALLOW_CREATE_EGG_ACCOUNT_START:
      return {
        ...state,
        checkingAllowCreate: true,
        checkedAllowCreate: false,
        allowCreateFreeAccount: false,
      }
    case CHECK_ALLOW_CREATE_EGG_ACCOUNT_SUCCESS:
      return {
        ...state,
        checkingAllowCreate: false,
        checkedAllowCreate: true,
        allowCreateFreeAccount: action.allow,
        freeAccountsOwned: action.freeAccounts
      }
    case CHECK_ALLOW_CREATE_EGG_ACCOUNT_FAIL:
      return {
        ...state,
        checkingAllowCreate: false,
        checkedAllowCreate: false,
        checkAllowCreateError: action.error.message,
      }
    case TOGGLE_SHOW_CREATE_FREE_ACCOUNT_NOT_ALLOWED:
      return {
        ...state,
        showCreateFreeAccountNotAllowedPrompt: !state.showCreateFreeAccountNotAllowedPrompt
      }
    default:
      return state
  }
}

export default accountReducer;