import authReducer from './authReducer';
import accountReducer from './accountReducer';
import personaReducer from './personaReducer';
import labelReducer from './labelReducer';
import discussionReducer from './discussionReducer';
import commentReducer from './commentReducer';
import suggestionReducer from './suggestionReducer';
import searchReducer from './searchReducer';
import userManagementReducer from './userManagementReducer';
import messageReducer from './messageReducer';
import subscriptionReducer from './subscriptionReducer';
import accountsMgntReducer from './accountsMgntReducer';
import { reducer as responsiveReducer } from 'react-responsive-redux'
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  accountManagement: accountsMgntReducer,
  persona: personaReducer,
  label: labelReducer,
  discussion: discussionReducer,
  comment: commentReducer,
  suggestion: suggestionReducer,
  search: searchReducer,
  responsive: responsiveReducer,
  userManagement: userManagementReducer,
  message: messageReducer,
  subscription: subscriptionReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default rootReducer