import * as actions from '../actions/actionTypes';
import * as stores from '../../shared/stores';
import find from 'lodash/find';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import reject from 'lodash/reject';
import Papa from 'papaparse';
import camelize from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes'

import { asyncForEach } from '../../shared/utility';
import { addActivity } from './activityActions';
import { logSearchIndex } from '../../shared/search'
import { getAccountPersonas } from '../../shared/queries';
import { removeFromPersonaConvosRemovedLabels } from './personaActions';

export const editLabelStart = (id) => {
  return {
    type: actions.EDIT_LABEL_START,
    labelId: id
  }
}

export const editLabelSuccess = (id) => {
  return {
    type: actions.EDIT_LABEL_SUCCESS,
    labelId: id
  }
}

export const editLabelFail = (id, err) => {
  return {
    type: actions.EDIT_LABEL_FAIL,
    labelId: id,
    error: err
  }
}

export const editLabel = labelData => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(editLabelStart(labelData.labelId));
    const firestore = getFirestore();
    const labelCol = `${labelData.currentAccountId}_${labelData.personaLane}`;
    const label = firestore.collection(labelCol).doc(labelData.labelId);
    const labelUpdates = labelData.labelMngt ? {
      description: labelData.desc,
      label: labelData.label,
      value: labelData.value,
      parent: labelData.parent
    } : {
      description: labelData.desc
    }

    label.update(labelUpdates)
    .then(() => {
      const acctId = labelData.currentAccountId;
      const personaLane = labelData.personaLane;
      try {
        label
        .get()
        .then(async doc => {
          const editedLabel = {...doc.data(), id: doc.id};
          if (labelData.titleChanged) {
            // Get all children and batch update all with new data.
            await firestore.collection(labelCol).where('parent.id', '==', labelData.labelId)
              .get()
              .then(async querySnapshot => {
                if (querySnapshot.size > 0) {
                  const editChildrenLabels = firestore.batch();
                  querySnapshot.forEach(childDoc => {
                    editChildrenLabels.update(firestore.collection(labelCol).doc(childDoc.id), {
                      lastUpdated: firestore.FieldValue.serverTimestamp(),
                      parent: editedLabel
                    });
                  })
                  await editChildrenLabels.commit()
                } 
              })
          }
          // Apply label to persona/s if there are
          if (!isEmpty(labelData.personas) || !isEmpty(labelData.removeFromPersonas)) {
            const addRemoveLabelToPersonaBatch = firestore.batch();
            const getUpdatedPersonaLaneData = async personaRef => {
              const personaDoc = await personaRef.get()
              const personaData = personaDoc.data()
              return reject(personaData[personaLane], ['id', editedLabel.id])
            }
            
            await asyncForEach(labelData.personas, async persona => {
              const personaRef = firestore.collection(`${acctId}_personas`).doc(persona.value)
              const updatedPersonaLane = await getUpdatedPersonaLaneData(personaRef)
              addRemoveLabelToPersonaBatch.update(personaRef, {
                [personaLane]: [...updatedPersonaLane, editedLabel],
                lastUpdated: firestore.FieldValue.serverTimestamp()
              });
            })

            await asyncForEach(labelData.removeFromPersonas, async persona => {
              const personaRef = firestore.collection(`${acctId}_personas`).doc(persona)
              const updatedPersonaLane = await getUpdatedPersonaLaneData(personaRef)
              addRemoveLabelToPersonaBatch.update(personaRef, {
                [personaLane]: updatedPersonaLane,
                lastUpdated: firestore.FieldValue.serverTimestamp()
              });
              // Remove the label as tag from the same personas' conversations
              removeFromPersonaConvosRemovedLabels(firestore, [editedLabel.id], labelData.personaLane, labelData.currentAccountId, persona, getState, dispatch)
            })

            await addRemoveLabelToPersonaBatch.commit()
            logSearchIndex(acctId, 'personas');
          }
        })
        logSearchIndex(acctId, personaLane);
        dispatch(editLabelSuccess(labelData.labelId));
      } catch (error) {
        dispatch(editLabelFail(labelData.labelId, error));
      }
    })
    .catch((err) => {
      dispatch(editLabelFail(labelData.labelId, err));
    })
  }
}

export const selectLabel = ({id, type, acct, isNegative}) => {
  return async (dispatch, getState, {getFirestore}) => {
    if (id) {
      let allPosts = [], relatedTags = [], tagTypes = [stores.INTERESTS, stores.CHALLENGES, stores.SOLUTIONS], relatedPersonas = [];
      let findLabel = isNegative ? { id, negative: true } : tag => tag.id === id && !tag.negative;
      const db = getFirestore();
      try {
        const discussionsSnap = await db.collection(`${acct}_discussions`).get().then(querySnapshot => querySnapshot);
        // Get related via discussions
        discussionsSnap.forEach(doc => {
          const post = doc.data();
          if (find(post.tags[type], findLabel)) {
            allPosts.push(doc.id);
            relatedPersonas.push(post.persona);
            tagTypes.forEach(type => {
              relatedTags.push(map(post.tags[type], 'id'))
            });
          }
        })
        // Get related personas
        const accountPersonas = await getAccountPersonas(acct);
        accountPersonas.forEach(doc => {
            const persona = doc.data()
            if (persona[type]) {
              persona[type].forEach(label => {
                if (includes(label, id) && !!label.negative === isNegative) {
                  relatedPersonas = [...relatedPersonas, doc.id]
                }
              })
            }
        })
        // Dispatch
        dispatch({
          type: actions.SELECTED_POST_LABEL_SET,
          id,
          related: without(flatten(relatedTags), id),
          posts: allPosts,
          postId: null,
          personas: uniq(relatedPersonas),
          isNegative
        });
      } catch (error) {
          console.log(error.message); 
      }
    }
    else
      dispatch({ type: actions.SELECTED_POST_LABEL_RESET, id });
  }
}

export const addLabelToPersonaStart = key => {
  return {
    type: actions.ADD_LABEL_TO_PERSONA_START,
    labelType: key
  }
}

export const addLabelToPersonaSuccess = (key, newData) => {
  return {
    type: actions.ADD_LABEL_TO_PERSONA_SUCCESS,
    labelType: key,
    newData
  }
}

export const addLabelToPersonaFail = (key, error) => {
  return {
    type: actions.ADD_LABEL_TO_PERSONA_FAIL,
    labelType: key,
    error
  }
}

export const addLabelToPersona = newLabelData => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(addLabelToPersonaStart(newLabelData.labelType));
    const firestore = getFirestore();
    let db;
    const curAcct = newLabelData.acctId;
    switch (newLabelData.labelType) {
      case 'interests':
        db = `${curAcct}_interests`;
        break;
      case 'challenges':
        db = `${curAcct}_challenges`;
        break;
      case 'solutions':
        db = `${curAcct}_solutions`;
        break;
      default:
        db = `${curAcct}_interests`;
    }
    const addLabelIsNew = newLabelData.label.isNew;
    const labelRef = addLabelIsNew ? firestore.collection(db).doc() : firestore.collection(db).doc(newLabelData.label.id)
    const setAddLabel = addLabelIsNew ?
      labelRef.set({
        accountId: newLabelData.acctId,
        value: newLabelData.label.value,
        label: newLabelData.label.label,
        description: newLabelData.description || null,
        parent: newLabelData.parent || null,
        createdOn: firestore.FieldValue.serverTimestamp(),
        lastUpdated: firestore.FieldValue.serverTimestamp(),
        deleted: false
      }) :
      labelRef.set({
        description: newLabelData.description || null,
        lastUpdated: firestore.FieldValue.serverTimestamp(),
      }, { merge: true });

    setAddLabel.then(() => {
      labelRef.get().then(doc => {
        if (doc.exists) {
          const addLabel = {...doc.data(), id: doc.id};
          // Apply label to persona/s
          const addLabelToPersonaBatch = firestore.batch();
          newLabelData.personas.forEach(persona => {
            if (persona.personaData && !find(persona.personaData[newLabelData.labelType], ['id', addLabel.id])) {
              // console.log(`Adding ${addLabel.label} to ${persona.label}`)
              addLabelToPersonaBatch.update(firestore.collection(`${curAcct}_personas`).doc(persona.value), {
                [newLabelData.labelType]: firestore.FieldValue.arrayUnion(addLabel),
                lastUpdated: firestore.FieldValue.serverTimestamp()
              });
            }
          });
          addLabelToPersonaBatch.commit()
          .then(() => {
            dispatch(addLabelToPersonaSuccess(newLabelData.labelType, addLabel));
            // Index
            logSearchIndex(curAcct, newLabelData.labelType);
            logSearchIndex(curAcct, 'personas');
          })
          .catch(err => {
            dispatch(addLabelToPersonaFail(newLabelData.labelType, err));
          })
        } else {
          dispatch(addLabelToPersonaFail(newLabelData.labelType, { message: 'Document does not exist' }));
        }
      }).catch(err => { dispatch(addLabelToPersonaFail(newLabelData.labelType, err)); })
    }).catch(err => { dispatch(addLabelToPersonaFail(newLabelData.labelType, err)); })
  }
}

export const labelOnDeletePrompt = ({
  labelId,
  account,
  labelType
}) => {
  return async (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    let personasApplyingLabel = labelType === 'solutions' ? [] : await getAffectedPersonas(labelId, account, labelType, firestore);
    let personasTaggingLabel = await getPersonasTaggingLabel(labelId, account, labelType, firestore);
    dispatch({
      type: actions.PROMPTED_DELETE_LABEL,
      labelId,
      personasAffected: uniq([...personasApplyingLabel, ...personasTaggingLabel]),
    });
  }
}

export const getAffectedDiscussions = async (labelId, account, labelType, firestore, persona = null) => {
  let discussionsTaggingLabel = [];
  await firestore.collection(`${account}_discussions`)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const discussion = doc.data();
        const labelIsTagged = find(discussion.tags[labelType], {'id': labelId})
        const isPersonaDiscussion = persona ? discussion.persona === persona : true
        if (labelIsTagged && isPersonaDiscussion) {
          discussionsTaggingLabel.push(doc.id)
        }
      })
    })
    .catch(error => {
      console.log('Error getting documents', error);
      discussionsTaggingLabel = null;
    })
  return discussionsTaggingLabel;
}

const getPersonasTaggingLabel = async (labelId, account, labelType, firestore) => {
  let personasTaggingLabel = [];
  await firestore.collection(`${account}_discussions`)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const discussion = doc.data();
        if (find(discussion.tags[labelType], {'id': labelId}) && discussion.persona !== 'unassigned') {
          personasTaggingLabel.push(discussion.persona)
        }
      })
    })
    .catch(error => {
      console.log('Error getting documents', error);
      personasTaggingLabel = null;
    })
  return personasTaggingLabel;
}

const getAffectedPersonas = async (labelId, account, labelType, firestore) => {
  let personasApplyingLabel = [];
  await firestore.collection(`${account}_personas`)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const persona = doc.data();
        if (find(persona[labelType], {'id': labelId})) {
          personasApplyingLabel.push(doc.id)
        }
      })
    })
    .catch(error => {
      console.log('Error getting documents', error);
      personasApplyingLabel = null;
    })

  return personasApplyingLabel;
}

const getChildrenLabels = async (labelId, labelCol, firestore) => {
  let childrenLabels = [];
  await firestore.collection(labelCol).where('parent.id', '==', labelId)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(childDoc => {
        childrenLabels.push(childDoc.id)
      })
    })
    .catch(error => {
      console.log('Error getting children labels of deleted.', error);
    })

  return childrenLabels;
}

export const deleteLabelStart = (id) => {
  return {
    type: actions.DELETE_LABEL_START,
    labelId: id
  }
}

export const deleteLabelSuccess = id => {
  return {
    type: actions.DELETE_LABEL_SUCCESS,
    labelId: id,
  }
}

export const deleteLabelFail = (id, err) => {
  return {
    type: actions.DELETE_LABEL_FAIL,
    labelId: id,
    error: err
  }
}

export const deleteLabel = ({
  labelId,
  account,
  labelType
}) => {
  return async (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(deleteLabelStart(labelId));
    const firestore = getFirestore();
    // Delete the label
    const labelCol = `${account}_${labelType}`;
    const personasCol = `${account}_personas`;
    const discussionsCol = `${account}_discussions`;
    const deletedLabel = firestore.collection(labelCol).doc(labelId);
    // Create batch
    const editAffectedElements = firestore.batch();

    // Get all affected
    // Children labels
    let childrenLabels = await getChildrenLabels(labelId, labelCol, firestore);
    childrenLabels.forEach(child => {
      editAffectedElements.update(firestore.collection(labelCol).doc(child), {
        lastUpdated: firestore.FieldValue.serverTimestamp(),
        parent: null,
      });
    })
    // Personas applying label 
    let personasApplyingLabel = labelType === 'solutions' ? [] : await getAffectedPersonas(labelId, account, labelType, firestore);

    const deleteLabelFromPersonas = async () => {
      await asyncForEach(personasApplyingLabel, async persona => {
        await firestore.collection(personasCol).doc(persona)
          .get()
          .then(doc => {
            if (doc.exists) {
              const affectedPersona = doc.data();
              editAffectedElements.update(firestore.collection(personasCol).doc(persona), {
                lastUpdated: firestore.FieldValue.serverTimestamp(),
                [labelType]: reject(affectedPersona[labelType], {'id': labelId}),
              });
            } else {
              console.log("No such persona document!");
            }
          })
          .catch(error => {
            console.log('Error getting this persona document.', error);
          })
      });
    }
    await deleteLabelFromPersonas();

    // Discussions tagging label
    let discussionsTaggingLabel = await getAffectedDiscussions(labelId, account, labelType, firestore);

    const deleteLabelFromDiscussion = async () => {
      await asyncForEach(discussionsTaggingLabel, async discussion => {
        await firestore.collection(discussionsCol).doc(discussion)
          .get()
          .then(doc => {
            if (doc.exists) {
              const affectedDiscussion = doc.data();
              editAffectedElements.update(firestore.collection(discussionsCol).doc(discussion), {
                lastUpdated: firestore.FieldValue.serverTimestamp(),
                [`tags.${labelType}`]: reject(affectedDiscussion.tags[labelType], {'id': labelId}),
              });
            } else {
              console.log("No such discussion document!");
            }
          })
          .catch(error => {
            console.log('Error getting this discussion document.', error);
          })
      })
    }

    await deleteLabelFromDiscussion();

    // Execute batch
    editAffectedElements.commit()
      .then(() => {
        deletedLabel.update({
          deleted: true,
          deletedAt: firestore.FieldValue.serverTimestamp()
         })
          .then(() => {
            // Dispatch success label delete
            dispatch(deleteLabelSuccess(labelId));
            // Index
            logSearchIndex(account, labelType);
            logSearchIndex(account, 'discussions');
            logSearchIndex(account, 'personas');
          })
          .then(() => {
            deletedLabel.get().then(labelDoc => {
              if (labelDoc.exists) {
                const state = getState();
                dispatch(addActivity({
                  account,
                  type: 'deleteLabel',
                  by: state.firebase.auth.uid,
                  activityData: {
                    label: {...labelDoc.data(), id: labelDoc.id},
                    labelType,
                    children: childrenLabels,
                    personas: personasApplyingLabel,
                    discussions: discussionsTaggingLabel
                  },
                  notify: true,
                  toNotify: Object.keys(state.firestore.data.users[account])
                }))
              }
            })
          })
          .catch(err => {
            dispatch(deleteLabelFail(labelId, err));
          })
      })
      .catch(err => {
        dispatch(deleteLabelFail(labelId, err));
      })
  }
}

export const undoDeleteLabelStart = id => {
  return {
    type: actions.UNDO_DELETE_LABEL_START,
    notifId: id
  }
}

export const undoDeleteLabelSuccess = id => {
  return {
    type: actions.UNDO_DELETE_LABEL_SUCCESS,
    notifId: id,
  }
}

export const undoDeleteLabelFail = (id, err) => {
  return {
    type: actions.UNDO_DELETE_LABEL_FAIL,
    notifId: id,
    error: err
  }
}

export const undoDeleteLabel = ({
  labelId,
  account,
  labelType,
  activityData,
  notifId
}) => {
  return (dispatch, getState, {getFirestore}) => {
    dispatch(undoDeleteLabelStart(notifId));
    const firestore = getFirestore();
    // Delete the label
    const labelCol = `${account}_${labelType}`;
    const personasCol = `${account}_personas`;
    const discussionsCol = `${account}_discussions`;
    const activitiesCol = `${account}_activities`;
    const deletedLabelRef = firestore.collection(labelCol).doc(labelId);
    // Update the label delete = false
    deletedLabelRef.update({
      deleted: false,
      lastUpdated: firestore.FieldValue.serverTimestamp()
    }).then(() => {
      deletedLabelRef.get().then(label => {
        if (label.exists) {
          const undeletedLabel = {...label.data(), id: label.id};
          // Create batch
          const restoreLabelToAffectedElements = firestore.batch();

          // If there are children
          activityData.children.forEach(child => {
            restoreLabelToAffectedElements.update(firestore.collection(labelCol).doc(child), {
              lastUpdated: firestore.FieldValue.serverTimestamp(),
              parent: undeletedLabel,
            });
          })

          // Affected Personas
          activityData.personas.forEach(persona => {
            restoreLabelToAffectedElements.update(firestore.collection(personasCol).doc(persona), {
              lastUpdated: firestore.FieldValue.serverTimestamp(),
              [labelType]: firestore.FieldValue.arrayUnion(undeletedLabel),
            });
          })

          // Affected Discussions
          activityData.discussions.forEach(discussion => {
            restoreLabelToAffectedElements.update(firestore.collection(discussionsCol).doc(discussion), {
              lastUpdated: firestore.FieldValue.serverTimestamp(),
              [`tags.${labelType}`]: firestore.FieldValue.arrayUnion(undeletedLabel),
            });
          })

          restoreLabelToAffectedElements.commit()
            .then(() => {
              // Make the activity inactive
              firestore.collection(activitiesCol).doc(notifId).update({
                active: false,
                lastUpdated: firestore.FieldValue.serverTimestamp(),
              }).then(() => {
                dispatch(undoDeleteLabelSuccess(notifId));
                // Index
                logSearchIndex(account, labelType);
                logSearchIndex(account, 'discussions');
                logSearchIndex(account, 'personas');
              }).catch(err => dispatch(undoDeleteLabelFail(notifId, err)))
            })
            .catch(err => {
              dispatch(undoDeleteLabelFail(notifId, err));
            })

        } else {
          dispatch(undoDeleteLabelFail(notifId, {message: 'No such label document!'}));
        }
      })
    }).catch(err => {
      dispatch(undoDeleteLabelFail(notifId, err));
    })
  }
}

export const importLabelsCSVStart = () => {
  return {
    type: actions.IMPORT_LABELS_START,
  }
}

export const importLabelsCSVSuccess = () => {
  return {
    type: actions.IMPORT_LABELS_SUCCESS,
  }
}

export const importLabelsCSVFail = msg => {
  return {
    type: actions.IMPORT_LABELS_FAIL,
    error: msg
  }
}

export const importLabelsCSV = ({
  url,
  curAcct
}) => {
  return (dispatch, getState, {getFirestore, getFirebase}) => {
    dispatch(importLabelsCSVStart());
    Papa.parse(url, {
      download: true,
      header: true,
      complete: async results => {
        const importData = results.data;
        const firestore = getFirestore();
        let collection;
        let contentType = [];
        // Create batch
        const importedLabelsBatch = firestore.batch();

        const saveImportedLabels = async () => {
          await asyncForEach(importData, async label => {
            if (!isEmpty(label.Name)) {
              switch (label.Type) {
                case 'Interest':
                  collection = `${curAcct}_interests`;
                  contentType = [...contentType, 'interests']
                  break;
                case 'Challenge':
                  collection = `${curAcct}_challenges`;
                  contentType = [...contentType, 'challenges']
                  break;
                case 'Solution':
                  collection = `${curAcct}_solutions`;
                  contentType = [...contentType, 'solutions']
                  break;
                default:
                  collection = `${curAcct}_interests`;
                  contentType = [...contentType, 'interests']
              }
              // First find and already existing
              const labelRef = firestore.collection(collection);
              await labelRef.where("label", "==", label.Name)
                .get()
                .then(querySnapshot => {
                  if (querySnapshot.empty) {
                    const labelData = {
                      accountId: curAcct,
                      value: camelize(label.Name),
                      label: label.Name,
                      description: label.Description || null,
                      parent: null,
                      createdOn: firestore.FieldValue.serverTimestamp(),
                      lastUpdated: firestore.FieldValue.serverTimestamp(),
                      deleted: false
                    }
                    importedLabelsBatch.set(labelRef.doc(), labelData);
                  }
                })
                .catch(error => {
                  console.log(`Error querying existence of ${label.Name}`, error.message);
                })
            }
          })
        }
        await saveImportedLabels();

        importedLabelsBatch.commit()
          .then(() => {
            dispatch(importLabelsCSVSuccess())
            // Index
            uniq(contentType).forEach(type => {
              logSearchIndex(curAcct, type);
            })
          })
          .catch(err => {
            dispatch(importLabelsCSVFail(err.message))
          })
      }
    });
  }
}
export const registerNegativeTag = ({acct, post, labels}) => {
  return dispatch => {
      dispatch({ 
          type: actions.REGISTER_NEGATIVE_TAG,
          acct,
          post,
          labels
      })
  }
}

export const saveAppliedLabels = ({labels, labelType, acct}) => {
  return dispatch => {
      dispatch({
          type: actions.SAVE_APPLIED_LABEL,
          labels,
          labelType,
          acct
      })
  }
}

export const saveNegativeTags = ({acct, tags, tagType}) => {
  return dispatch => {
      dispatch({ 
          type: actions.SAVE_NEGATIVE_TAGS,
          tags,
          tagType,
          acct
      })
  }
}