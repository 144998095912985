import { updateAccount, updateAccountConversationsCount, updateAccountPersonaCount } from './accountActions';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import plan from '../../config/plan';
import { getInitials, getMoment, asyncForEach } from '../../shared/utility';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { countArchivedPersonas, countDeletedPersonas } from './personaActions';

export const FETCH_SUBSCRIPTION_START = 'FETCH_SUBSCRIPTION_START',
	FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS',
	FETCH_SUBSCRIPTION_FAIL = 'FETCH_SUBSCRIPTION_FAIL',
	SET_SELECTED_UPGRADE_PLAN = 'SET_SELECTED_UPGRADE_PLAN',
	CHECKOUT_PLAN_SUBSCRIPTION_START = 'CHECKOUT_PLAN_SUBSCRIPTION_START',
	CHECKOUT_PLAN_SUBSCRIPTION_SUCCESS = 'CHECKOUT_PLAN_SUBSCRIPTION_SUCCESS',
	CHECKOUT_PLAN_SUBSCRIPTION_FAIL = 'CHECKOUT_PLAN_SUBSCRIPTION_FAIL',
	PROMPT_CHECKOUT_COMPLETED_SUCCESS = 'PROMPT_CHECKOUT_COMPLETED_SUCCESS',
	PROMPT_CHECKOUT_COMPLETED_FAIL = 'PROMPT_CHECKOUT_COMPLETED_FAIL',
	FETCH_CUSTOMER_PAYMENTMETHOD_START = 'FETCH_CUSTOMER_PAYMENTMETHOD_START',
	FETCH_CUSTOMER_PAYMENTMETHOD_FAIL = 'FETCH_CUSTOMER_PAYMENTMETHOD_FAIL',
	FETCH_CUSTOMER_PAYMENTMETHOD_SUCCESS = 'FETCH_CUSTOMER_PAYMENTMETHOD_SUCCESS',
	UPGRADE_LATER_START = 'UPGRADE_LATER_START',
	UPGRADE_LATER_SUCCESS = 'UPGRADE_LATER_SUCCESS',
	UPGRADE_LATER_FAIL = 'UPGRADE_LATER_FAIL',
	EDIT_BILLING_DETAILS_START = 'EDIT_BILLING_DETAILS_START',
	EDIT_BILLING_DETAILS_SUCCESS = 'EDIT_BILLING_DETAILS_SUCCESS',
	EDIT_BILLING_DETAILS_FAIL = 'EDIT_BILLING_DETAILS_FAIL',
	SELECT_ACCOUNT_TO_UPGRADE = 'SELECT_ACCOUNT_TO_UPGRADE',
	CHANGE_PLAN_START = 'CHANGE_PLAN_START',
	CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS',
	CHANGE_PLAN_FAIL = 'CHANGE_PLAN_FAIL';

const fetchSubscriptionStart = featureChecking => {
	return {
		type: FETCH_SUBSCRIPTION_START,
		featureChecking
	};
};

const fetchSubscriptionSuccess = acctSubscription => {
	return {
		type: FETCH_SUBSCRIPTION_SUCCESS,
		acctSubscription
	};
};

const fetchSubscriptionFail = error => {
	return {
		type: FETCH_SUBSCRIPTION_FAIL,
		error
	};
};

export const fetchAcctSubscription = ({ accountId, featureChecking, data = {} }) => {
	return async (dispatch, getState, { getFirestore }) => {
		dispatch(fetchSubscriptionStart(featureChecking));
		try {
			const firestore = getFirestore();
			const currentAccountRef = firestore.collection('accounts').doc(accountId);
			let currentAccount = await currentAccountRef.get().then(doc => ({ ...doc.data(), id: doc.id }));
			let trialInit = false,
				personaConvosData,
				archivedDeletedPersonasCount = {};
			if (!currentAccount.subscription) {
				const subscriptionData = {
					subscription: {
						trialStarted: firestore.FieldValue.serverTimestamp(),
						trialExpiration: moment()
							.add(14, 'days')
							.toISOString(),
						trialHasExpired: false,
						plan: 'egg',
						upgradeDate: null,
						pricingCode: process.env.REACT_APP_PRICING_CODE
					}
				};
				await updateAccount(firestore, subscriptionData, accountId);
				// const accountOwner = currentAccount.owners[0]
				// const userRef = firestore.collection('users').doc(accountOwner)
				// let currentAccountOwner = await userRef.get().then(doc => ({...doc.data(), id: doc.id}))
				// const billingInfoAccount = {
				//     accountId,
				//     email: currentAccountOwner.email,
				//     name: currentAccountOwner.screenName,
				//     frequency: 'monthly',
				//     address: null,
				//     nextBillingDate: null
				// }
				// await setBillingInfo(firestore, billingInfoAccount)
				trialInit = true;
			} else {
				if (moment().isAfter(getMoment(currentAccount.subscription.trialExpiration))) {
					// Trial expiration is up
					const subscriptionData = {
						subscription: {
							...currentAccount.subscription,
							trialHasExpired: true
						}
					};
					await updateAccount(firestore, subscriptionData, accountId);
				}
			}
			if (currentAccount.conversations === undefined) {
				await updateAccountConversationsCount(firestore, `${accountId}_discussions`, accountId);
			}
			if (currentAccount.personas === undefined) {
				await updateAccountPersonaCount(firestore, `${accountId}_personas`, accountId);
			}
			if (featureChecking === 'Add Conversation') {
				personaConvosData = await getPersonaConversationsData(firestore, accountId);
			}
			if (includes(['Restore Persona', 'Archive Persona', 'Delete Persona'], featureChecking)) {
				archivedDeletedPersonasCount = {
					archivedPersonas: await countArchivedPersonas(firestore, `${accountId}_personas`),
					deletedPersonas: await countDeletedPersonas(firestore, `${accountId}_personas`),
					...data
				};
			}
			// Get the account's subscription
			currentAccount = await currentAccountRef.get().then(doc => ({ ...doc.data(), id: doc.id }));
			if (currentAccount.subscription.plan) {
				const currentAccountSubscription = currentAccount.subscription;
				const currentUserId = getState().firebase.auth.uid;
				let currentAccountPlanFeatures =
					plan[currentAccountSubscription.pricingCode][currentAccountSubscription.plan];
				currentAccountPlanFeatures = {
					...currentAccountPlanFeatures,
					accountPersonas: currentAccount.personas,
					accountConvos: currentAccount.conversations,
					accountUsers: currentAccount.users.length,
					trialHasExpired: currentAccountSubscription.trialHasExpired,
					freeTrialHasExpired:
						currentAccountSubscription.plan === 'egg' && currentAccountSubscription.trialHasExpired,
					trialExpiresIn: getMoment(currentAccountSubscription.trialExpiration).fromNow(),
					plan: currentAccountSubscription.plan,
					billingFrequency: currentAccountSubscription.billingFrequency || 'monthly',
					trialInit,
					upgradeSuccessful: !!currentAccountSubscription.upgradeDate,
					accountId,
					pricingCode: currentAccountSubscription.pricingCode,
					personaConvosData,
					...archivedDeletedPersonasCount,
					currentUserIsAccountOwner: includes(currentAccount.owners, currentUserId)
				};
				return dispatch(fetchSubscriptionSuccess(currentAccountPlanFeatures));
			} else {
				throw new Error('There is no subscription for this account.');
			}
		} catch (error) {
			dispatch(fetchSubscriptionFail(error));
		}
	};
};

// const setBillingInfo = async (firestore, data, id = null) => {
//     const bill = id ? firestore.collection('billingInfo').doc(id) : firestore.collection('billingInfo').doc();
//     return await bill.set(data, {merge: true});
// }

const getPersonaConversationsData = async (db, acctId) => {
	const personasCol = `${acctId}_personas`,
		discussionCol = `${acctId}_discussions`;
	const personasRef = db.collection(personasCol);
	const personasSnap = await personasRef.get();
	let personas = ['unassigned'],
		data = {};
	personasSnap.forEach(persona => {
		personas.push(persona.id);
	});
	if (!isEmpty(personas)) {
		await asyncForEach(personas, async persona => {
			const discussionsRef = db.collection(discussionCol);
			const discussionQuery = discussionsRef.where('persona', '==', persona);
			const personaDiscussionsSnap = await discussionQuery.get();
			data[persona] = personaDiscussionsSnap.size;
		});
	}
	return data;
};

export const setSelectedUpgradePlan = (code, freq) => {
	return dispatch => {
		dispatch({
			type: SET_SELECTED_UPGRADE_PLAN,
			code,
			freq
		});
	};
};

const checkoutPlanSubscriptionStart = () => {
	return {
		type: CHECKOUT_PLAN_SUBSCRIPTION_START
	};
};

const checkoutPlanSubscriptionSuccess = () => {
	return {
		type: CHECKOUT_PLAN_SUBSCRIPTION_SUCCESS
	};
};

const checkoutPlanSubscriptionFail = error => {
	return {
		type: CHECKOUT_PLAN_SUBSCRIPTION_FAIL,
		error
	};
};

export const checkoutPlanSubscription = ({ stripe, checkout, successUrl, cancelUrl }) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch(checkoutPlanSubscriptionStart());
		try {
			const firestore = getFirestore();
			const firebase = getFirebase();
			const functions = firebase.functions();
			let subscription = checkout;

			if (subscription.premiumSubscription) {
				let accountId = subscription.accountId;
				// let accountName =
				if (subscription.account.isNew) {
					accountId = await createAccount(firestore, subscription.account, subscription.userId);
				}
				subscription = {
					...subscription,
					accountId
				};
				delete subscription.account;
			}

			const planCheckout = await savePlanCheckout(firestore, {
				...subscription,
				pricingCode: process.env.REACT_APP_PRICING_CODE,
				created: firestore.FieldValue.serverTimestamp()
			});

			const stripeCheckoutSession = functions.httpsCallable('stripeCheckoutSession');
			const stripeSession = await stripeCheckoutSession({
				checkoutId: planCheckout,
				successUrl: subscription.premiumSubscription
					? `${successUrl}/persona-file/${subscription.accountId}`
					: successUrl,
				cancelUrl
			});
			await stripe.redirectToCheckout({
				sessionId: stripeSession.data.id
			});
			dispatch(checkoutPlanSubscriptionSuccess());
		} catch (error) {
			console.log(error);
			dispatch(checkoutPlanSubscriptionFail(error));
		}
	};
};

const savePlanCheckout = async (firestore, data) => {
	const planCheckoutRef = firestore.collection('planCheckouts').doc();
	await planCheckoutRef.set(data);
	const planCheckout = await planCheckoutRef.get();
	return planCheckout.id;
};

const createAccount = async (firestore, account, userId) => {
	const createAccountRef = firestore.collection('accounts').doc();
	await createAccountRef.set({
		name: account.label,
		color: tinycolor.random().toHexString(),
		description: '',
		initials: getInitials(account.label).toUpperCase(),
		users: [userId],
		owners: [userId],
		creator: userId,
		createdAt: firestore.FieldValue.serverTimestamp(),
		conversations: 0,
		personas: 0
	});
	const acct = await createAccountRef.get();
	return acct.id;
};

export const checkoutCompleted = checkoutId => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		try {
			const planCheckoutRef = firestore.collection('planCheckouts').doc(checkoutId);
			await planCheckoutRef.update({
				justCheckedOut: false
			});
			dispatch({
				type: PROMPT_CHECKOUT_COMPLETED_SUCCESS
			});
		} catch (error) {
			dispatch({
				type: PROMPT_CHECKOUT_COMPLETED_FAIL,
				error
			});
		}
	};
};

const fetchCustomerPaymentMethodStart = () => {
	return {
		type: FETCH_CUSTOMER_PAYMENTMETHOD_START
	};
};

const fetchCustomerPaymentMethodSuccess = (paymentMethods, customerData) => {
	return {
		type: FETCH_CUSTOMER_PAYMENTMETHOD_SUCCESS,
		paymentMethods,
		customerData
	};
};

const fetchCustomerPaymentMethodFail = error => {
	return {
		type: FETCH_CUSTOMER_PAYMENTMETHOD_FAIL,
		error
	};
};

export const fetchCustomerPaymentMethod = customerId => {
	return async (dispatch, getState, { getFirebase }) => {
		dispatch(fetchCustomerPaymentMethodStart());
		const firebase = getFirebase();
		const functions = firebase.functions();
		try {
			const getCustomerPaymentMethods = functions.httpsCallable('getCustomerPaymentMethods');
			const customerPaymentMethods = await getCustomerPaymentMethods(customerId);
			const getCustomerData = functions.httpsCallable('getCustomerData');
			const customerData = await getCustomerData(customerId);
			dispatch(fetchCustomerPaymentMethodSuccess(customerPaymentMethods.data, customerData.data));
		} catch (error) {
			dispatch(fetchCustomerPaymentMethodFail(error));
		}
	};
};

const upgradeLaterStart = () => {
	return {
		type: UPGRADE_LATER_START
	};
};

const upgradeLaterSuccess = () => {
	return {
		type: UPGRADE_LATER_SUCCESS
	};
};

const upgradeLaterFail = error => {
	return {
		type: UPGRADE_LATER_FAIL,
		error
	};
};

export const upgradeLater = ({ uid, plan }) => {
	return async (dispatch, getState, { getFirestore }) => {
		dispatch(upgradeLaterStart());
		const db = getFirestore();
		try {
			const userRef = db.collection('users').doc(uid);
			await userRef.update({
				premiumIntent: {
					fulfilled: true,
					fulfilledAt: db.FieldValue.serverTimestamp(),
					plan,
					upgradeLater: true
				}
			});
			dispatch(upgradeLaterSuccess());
		} catch (error) {
			dispatch(upgradeLaterFail(error));
			console.log(error);
		}
	};
};

const editBillingDetailsStart = () => {
	return {
		type: EDIT_BILLING_DETAILS_START
	};
};

const editBillingDetailsSuccess = () => {
	return {
		type: EDIT_BILLING_DETAILS_SUCCESS
	};
};

const editBillingDetailsFail = error => {
	return {
		type: EDIT_BILLING_DETAILS_FAIL,
		error
	};
};

// export const editBillingProfile = (billingProfile) => {
// 	return async (dispatch, getState, { getFirebase, getFirestore }) => {
// 		dispatch(editBillingDetailsStart());
// 		const functions = firebase.functions();
// 		try {
// 		} catch (error) {}
// 	};
// };

export const editBillingDetails = (billingDetails, form, acct) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch(editBillingDetailsStart());
		const firebase = getFirebase();
		const db = getFirestore();
		const functions = firebase.functions();
		try {
			const createNewPaymentMethod = functions.httpsCallable('createNewPaymentMethod'),
				attachPaymentMethodToCustomer = functions.httpsCallable('attachPaymentMethodToCustomer'),
				detachPaymentMethod = functions.httpsCallable('detachPaymentMethod'),
				updateCustomer = functions.httpsCallable('updateCustomer');
			// If form is Billing Profile
			if (form === 'billing-profile') {
				await updateCustomer({
					cid: billingDetails.customerId,
					customerData: {
						name: billingDetails.cusName,
						email: billingDetails.cusEmail,
						phone: billingDetails.cusPhone
					}
				});
				await updateAccount(
					db,
					{
						'subscription.customerProfile': {
							name: billingDetails.cusName,
							email: billingDetails.cusEmail,
							contactNumber: billingDetails.cusPhone
						}
					},
					acct
				);
			} else {
				const newPaymentMethod = await createNewPaymentMethod({
					type: 'card',
					card: billingDetails.card,
					billing_details: billingDetails.billing_details
				});
				// console.log('newPaymentMethod', newPaymentMethod)
				await attachPaymentMethodToCustomer({
					pmId: newPaymentMethod.data.id,
					customerId: billingDetails.customerId
				});
				// console.log('attachPM', attachPM)
				await detachPaymentMethod(billingDetails.oldPaymentMethodId);
				// console.log('detachPM', detachPM)
				// await updateCustomer({
				// 	cid: billingDetails.customerId,
				// 	customerData: {
				// 		name: billingDetails.customerName,
				// 		email: billingDetails.billing_details.email
				// 	}
				// });
			}
			// console.log('updatedCustomerData', updatedCustomerData)
			dispatch(editBillingDetailsSuccess());
		} catch (error) {
			dispatch(editBillingDetailsFail(error));
		}
	};
};

export const chooseAccountToUpgrade = account => {
	return dispatch => {
		dispatch({
			type: 'SELECT_ACCOUNT_TO_UPGRADE',
			account
		});
	};
};

const changePlanStart = () => ({
	type: CHANGE_PLAN_START
});

const changePlanSuccess = () => ({
	type: CHANGE_PLAN_SUCCESS
});

const changePlanFail = error => ({
	type: CHANGE_PLAN_FAIL,
	error
});

export const changePlan = ({ uid, freq, plan }) => {
	return async (dispatch, getState, { getFirestore }) => {
		try {
			dispatch(changePlanStart());
			const db = getFirestore();
			const userRef = db.collection('users').doc(uid);
			await userRef.update({
				'premiumIntent.frequency': freq,
				'premiumIntent.plan': plan
			});
			dispatch(changePlanSuccess());
		} catch (error) {
			dispatch(changePlanFail(error));
		}
	};
};
