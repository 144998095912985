import * as actions from './actionTypes';
import tinycolor from 'tinycolor2';
import { functionsErrorHandling } from '../../shared/error';
import { actionTypes } from 'redux-firestore';
import { emailAdminOnNewUserCreated } from '../../shared/emailNotifications';

export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    dispatch({ type: actions.LOGIN_START });
    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(creds => {
      window.analytics.identify(creds.user.uid, {
        name: creds.user.displayName,
        email: creds.user.email
      });
      dispatch({ type: actions.LOGIN_SUCCESS });
    }).catch((err) => {
      dispatch({
        type: actions.LOGIN_ERROR,
        error: err
      });
    });

  }
}

export const signOut = email => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    firebase.logout().then(() => {
      dispatch({ type: actions.SIGNOUT_SUCCESS })
      dispatch({ type: actionTypes.CLEAR_DATA })
      window.analytics.track('User Logged Out', {email});
    });
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({ type:actions.SIGNUP_START });
    firebase.auth().createUserWithEmailAndPassword (
      newUser.email, newUser.password
    ).then(async user => {
      dispatch({ type:actions.SIGNUP_SUCCESS });
      // Add display name
      // const user = firebase.auth().currentUser;
      const unsubscribe = await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          const {
            fullName,
            phone,
            role,
            screenName,
            email
          } = newUser
          user.updateProfile({
            displayName: fullName
          }).then(() => {
            let premiumIntent = newUser.signupPlan ? {
              fulfilled: false,
              plan: newUser.signupPlan,
              frequency: newUser.signupPlanBillFreq
            } : null
            firebase.updateProfile({
              fullName,
              phone,
              role,
              screenName,
              email,
              referralId: Math.random().toString(36).substring(2, 7),
              referrerCode: newUser.referrerCode || null,
              color: tinycolor.random().toHexString(),
              premiumIntent,
            });
            dispatch({ type: actions.SET_USER_PROFILE_SUCCESS });
            // Mark the registration as "completed"
            firestore.collection('registrations').doc(newUser.registrationCode).update({
              completed: true,
              completedAt: firestore.FieldValue.serverTimestamp()
            }).then(() => {
              dispatch({ type: actions.REG_COMPLETED_SUCCESS })
              if (process.env.NODE_ENV === 'production') {
                emailAdminOnNewUserCreated(user.uid, newUser.registrationCode)
              }
            }).catch(err => {
              dispatch({ type: actions.REG_COMPLETED_FAILED, err })
            });
          }).catch(err => {
            dispatch({ type: actions.SET_USER_PROFILE_FAILED, err })
          });
          window.analytics.identify(user.uid, {
            name: user.displayName,
            email: user.email
          });
        }
      });
      unsubscribe();
    }).catch(err => {
      dispatch({ type: actions.SIGNUP_ERROR, error: err});
    });
  }
}

const forgotPasswordReqStart = () => {
  return {
    type: actions.FORGOT_PASSWORD_REQ_START
  }
}

const forgotPasswordReqSuccess = () => {
  return {
    type: actions.FORGOT_PASSWORD_REQ_SUCCESS
  }
}

const forgotPasswordReqFail = error => {
  return {
    type: actions.FORGOT_PASSWORD_REQ_FAIL,
    error
  }
}

export const forgotPasswordRequest = email => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    dispatch(forgotPasswordReqStart());
    const firebase = getFirebase();
    const functions = firebase.functions();
    const getPasswordResetLink = functions.httpsCallable('getPasswordResetLink');
    getPasswordResetLink({email})
    .then(result => {
      const { resetPasswordLink } = result.data;
      const resetParams = new URLSearchParams(resetPasswordLink);
      const resetCode = resetParams.get('oobCode');
      const resetPasswordReqLink = `${process.env.REACT_APP_DOMAIN}/reset-password/${resetCode}`
      const sendPasswordResetRequest = functions.httpsCallable('sendResetPasswordLink');
      sendPasswordResetRequest({
        email,
        resetPasswordLink: resetPasswordReqLink
      })
      .then(res => {
        dispatch(forgotPasswordReqSuccess())
      })
      .catch(error => {
        const errorMessage = functionsErrorHandling(error);
        dispatch(forgotPasswordReqFail(errorMessage))
      })
    })
    .catch(err => {
      const errorMessage = functionsErrorHandling(err);
      dispatch(forgotPasswordReqFail(errorMessage))
    })
  }
}

const verifyResetPassCodeStart = () => {
  return {
    type: actions.RESET_PASSCODE_VERIFY_START
  }
}

const verifyResetPassCodeSuccess = () => {
  return {
    type: actions.RESET_PASSCODE_VERIFY_SUCCESS
  }
}

const verifyResetPassCodeFail = error => {
  return {
    type: actions.RESET_PASSCODE_VERIFY_FAIL,
    error
  }
}

export const verifyResetPasswordCode = code => {
  return async (dispatch, getState, {getFirebase}) => {
    dispatch(verifyResetPassCodeStart())
    const firebase = getFirebase();
    const auth = firebase.auth();

    try {
      await verifyResetPassCode(auth, code);
      dispatch(verifyResetPassCodeSuccess());
    } catch (error) {
      dispatch(verifyResetPassCodeFail(error))
    }
  }
}

const verifyResetPassCode = async (auth, code) => {
  return await auth.verifyPasswordResetCode(code)
    .then(email => {
      return email
    })
    .catch(error => {
      throw error.message
    })
}

const passwordResetStart = () => {
  return {
    type: actions.PASSWORD_RESET_START
  }
}

const passwordResetSuccess = () => {
  return {
    type: actions.PASSWORD_RESET_SUCCESS
  }
}

const passwordResetFail = error => {
  return {
    type: actions.PASSWORD_RESET_FAIL,
    error
  }
}

const setSuccessfulPasswordReset = set => {
  return {
    type: actions.RESET_PASSWORD_SUCCESSFUL,
    set
  }
}

export const passwordReset = ({
  code,
  newPassword
}) => {
  return async (dispatch, getState, {getFirebase}) => {
    dispatch(passwordResetStart())
    const firebase = getFirebase();
    const auth = firebase.auth();

    try {
      const response = await verifyResetPassCode(auth, code);
      if (response) {
        auth.confirmPasswordReset(code, newPassword)
          .then(result => {
            dispatch(passwordResetSuccess())
            auth.signInWithEmailAndPassword(response, newPassword)
              .then(res => {
                dispatch(setSuccessfulPasswordReset(true))
                window.analytics.identify(res.user.uid, {
                  name: res.user.displayName,
                  email: res.user.email
                })
              })
              .catch(err => {
                dispatch(passwordResetFail({ code: 'login-failed', message: err.message }))
              })
          })
          .catch(error => {
            dispatch(passwordResetFail(error))
          })
      }
    } catch (error) {
      dispatch(passwordResetFail({ code: 'bad-code', message: error }))
    }
  }
}

export const closeSuccessfulPasswordResetModal = () => {
  return dispatch => {
    dispatch(setSuccessfulPasswordReset(false))
  }
}