import './styles/_appStyles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from './config/firebase';
import rrfConfig from './config/rrf';
import rootReducer from './store/reducers/rootReducer';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const store = createStore(rootReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
    reactReduxFirebase(fbConfig, rrfConfig),
    reduxFirestore(fbConfig)
  )
);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(app, document.getElementById('root'));
  // registerServiceWorker();
});
