import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import logoWhite from '../../../assets/images/personafx_logo-white.png';
import classes from './Brand.module.css';

const signIn = () => (
  <Navbar.Brand className={classes.Brand}>
    <Link to='/'>
      <img alt="Persona 𝘧𝘹" src={logoWhite} className="d-inline-block align-top"/>
    </Link>
  </Navbar.Brand>
);

export default signIn;