import {
    FETCH_ACCOUNTS_START, 
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAIL,
    ACCOUNTS_PAGE_LOADED,
    GET_ACCOUNT_DATA_START,
    GET_ACCOUNT_DATA_SUCCESS,
    GET_ACCOUNT_DATA_FAIL,
    GET_ACCOUNT_DATA_SKIP,
    MAKE_FREE_FLYING_START,
    MAKE_FREE_FLYING_SUCCESS,
    MAKE_FREE_FLYING_FAIL
  } from '../actions/accountsMgntActions'
import {updateObject} from '../../shared/utility'

const initState = {
    fetchingAccounts: false,
    fetchedAccounts: false,
    fetchAccountsError: null,
    accountsFetched: [],
    billingInfosFetched: [],
    customersData: [],
    accounts: {}
}

const accountsMgntReducer = ( state = initState, action ) => {
    let updatedAccounts
    switch (action.type) {
        case FETCH_ACCOUNTS_START:
            return {
                ...state,
                fetchingAccounts: true,
                fetchedAccounts: false,
                fetchAccountsError: null,
            }
        case FETCH_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingAccounts: false,
                fetchedAccounts: true,
                accountsFetched: action.accounts,
                customersData: action.customers, 
            }
        case FETCH_ACCOUNTS_FAIL:
            return {
                ...state,
                fetchingAccounts: false,
                fetchedAccounts: false,
                fetchAccountsError: action.error.message,
            }
        case ACCOUNTS_PAGE_LOADED:
            return {
                ...state,
                totalNumAccts: action.totalAccounts
            }
        case GET_ACCOUNT_DATA_START:
            updatedAccounts = updateObject( state.accounts, {
                [action.accountId]: updateObject( state.accounts[action.accountId], {
                    fetching: true,
                    fetched: false,
                    error: null
                })
            });
            return {
                ...state,
                accounts: updatedAccounts
            }
        case GET_ACCOUNT_DATA_SKIP:
                updatedAccounts = updateObject( state.accounts, {
                    [action.accountId]: updateObject( state.accounts[action.accountId], {
                        fetching: false,
                        fetched: true,
                        data: state.accounts[action.accountId].data,
                        error: null
                    })
                });
                return {
                    ...state,
                    accounts: updatedAccounts
                }
        case GET_ACCOUNT_DATA_SUCCESS:
            updatedAccounts = updateObject( state.accounts, {
                [action.accountId]: updateObject( state.accounts[action.accountId], {
                    fetching: false,
                    fetched: true,
                    data: action.data
                })
            });
            return {
                ...state,
                accounts: updatedAccounts
            }
        case GET_ACCOUNT_DATA_FAIL:
            updatedAccounts = updateObject( state.accounts, {
                [action.accountId]: updateObject( state.accounts[action.accountId], {
                    fetching: false,
                    fetched: false,
                    error: action.error.message
                })
            });
            return {
                ...state,
                accounts: updatedAccounts
            }
        case MAKE_FREE_FLYING_START:
                updatedAccounts = updateObject( state.accounts, {
                    [action.accountId]: updateObject( state.accounts[action.accountId], {
                        freeFlyingProcessing: true,
                        freeFlyingProcessed: false,
                        freeFlyingProcessError: null
                    })
                });
                return {
                    ...state,
                    accounts: updatedAccounts
                }
        case MAKE_FREE_FLYING_SUCCESS:
                updatedAccounts = updateObject( state.accounts, {
                    [action.accountId]: updateObject( state.accounts[action.accountId], {
                        freeFlyingProcessing: false,
                        freeFlyingProcessed: true,
                        freeFlyingProcessError: null
                    })
                });
                return {
                    ...state,
                    accounts: updatedAccounts
                }
        case MAKE_FREE_FLYING_FAIL:
                updatedAccounts = updateObject( state.accounts, {
                    [action.accountId]: updateObject( state.accounts[action.accountId], {
                        freeFlyingProcessing: false,
                        freeFlyingProcessed: false,
                        freeFlyingProcessError: action.error.message
                    })
                });
                return {
                    ...state,
                    accounts: updatedAccounts
                }
        default:
            return state
    }
}
  
export default accountsMgntReducer;