import {
    FETCH_SUBSCRIPTION_START,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_SUBSCRIPTION_FAIL,
    SET_SELECTED_UPGRADE_PLAN,
    CHECKOUT_PLAN_SUBSCRIPTION_START,
    CHECKOUT_PLAN_SUBSCRIPTION_SUCCESS,
    CHECKOUT_PLAN_SUBSCRIPTION_FAIL,
    FETCH_CUSTOMER_PAYMENTMETHOD_START,
    FETCH_CUSTOMER_PAYMENTMETHOD_FAIL,
    FETCH_CUSTOMER_PAYMENTMETHOD_SUCCESS,
    UPGRADE_LATER_FAIL,
    UPGRADE_LATER_SUCCESS,
    UPGRADE_LATER_START,
    EDIT_BILLING_DETAILS_START,
    EDIT_BILLING_DETAILS_SUCCESS,
    EDIT_BILLING_DETAILS_FAIL,
    SELECT_ACCOUNT_TO_UPGRADE,
    CHANGE_PLAN_START,
    CHANGE_PLAN_SUCCESS,
    CHANGE_PLAN_FAIL
} from '../actions/subscriptionActions'
import { CURRENT_ACCOUNT_RESET } from '../actions/actionTypes'

const initState = {
    fetching: false,
    fetched: false,
    error: null,
    data: null,
    featureChecking: null,
    selectedUpgradePlan: null,
    selectedBillingFreq: null,
    savingCheckout: false,
    savedCheckout: true,
    saveCheckoutError: null,
    fetchingCard: false,
    fetchedCard: false,
    fetchCardError: null,
    customerPaymentMethods: null,
    customer: null,
    upgrading: false,
    upgraded: false,
    upgradeError: null,
    editingBillingDetails: false,
    editedBillingDetails: false,
    editBillingDetailsError: null,
    selectedAccountToUpgrade: null,
    changingPlan: false,
    changedPlan: false,
    changePlanError: null
}

const subscriptionReducer = ( state = initState, action ) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTION_START:
            return {
                ...state,
                featureChecking: action.featureChecking,
                fetching: true,
                fetched: false,
            }
        case FETCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.acctSubscription
            }
        case FETCH_SUBSCRIPTION_FAIL:
            return {
                ...state,
                fetching: false,
                fetched: false,
                data: null,
                error: action.error.message
            }
        case CURRENT_ACCOUNT_RESET:
            return initState
        case SET_SELECTED_UPGRADE_PLAN:
            return {
                ...state,
                selectedUpgradePlan: action.code,
                selectedBillingFreq: action.freq
            }
        case CHECKOUT_PLAN_SUBSCRIPTION_START:
            return {
                ...state,
                savingCheckout: true
            }
        case CHECKOUT_PLAN_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                savingCheckout: false,
                savedCheckout: true,
            }
        case CHECKOUT_PLAN_SUBSCRIPTION_FAIL:
            return {
                ...state,
                savingCheckout: false,
                savedCheckout: false,
                saveCheckoutError: action.error.message
            }
        case FETCH_CUSTOMER_PAYMENTMETHOD_START:
            return {
                ...state,
                fetchingCard: true,
                fetchedCard: false,
                fetchCardError: null
            }
        case FETCH_CUSTOMER_PAYMENTMETHOD_SUCCESS:
            return {
                ...state,
                fetchingCard: false,
                fetchedCard: true,
                customerPaymentMethods: action.paymentMethods.data,
                customer: action.customerData
            }
        case FETCH_CUSTOMER_PAYMENTMETHOD_FAIL:
            return {
                ...state,
                fetchingCard: false,
                fetchedCard: false,
                fetchCardError: action.error.message
            }
        case UPGRADE_LATER_START:
            return {
                ...state,
                upgrading: true,
                upgraded: false,
                upgradeError: null
            }
        case UPGRADE_LATER_SUCCESS:
            return {
                ...state,
                upgrading: false,
                upgraded: true,
            }
        case UPGRADE_LATER_FAIL:
            return {
                ...state,
                upgrading: false,
                upgraded: false,
                upgradeError: action.error.message
            }
        case EDIT_BILLING_DETAILS_START:
            return {
                ...state,
                editingBillingDetails: true,
                editedBillingDetails: false,
                editBillingDetailsError: null
            }
        case EDIT_BILLING_DETAILS_SUCCESS:
            return {
                ...state,
                editingBillingDetails: false,
                editedBillingDetails: true
            }
        case EDIT_BILLING_DETAILS_FAIL:
            return {
                ...state,
                editingBillingDetails: false,
                editedBillingDetails: false,
                editBillingDetailsError: action.error.message
            }
        case SELECT_ACCOUNT_TO_UPGRADE:
            return {
                ...state,
                selectedAccountToUpgrade: action.account
            }
        case CHANGE_PLAN_START:
            return {
                ...state,
                changingPlan: true,
                changedPlan: false,
                changePlanError: null
            }
        case CHANGE_PLAN_SUCCESS:
            return {
                ...state,
                changingPlan: false,
                changedPlan: true,
            }
        case CHANGE_PLAN_FAIL:
            return {
                ...state,
                changingPlan: false,
                changedPlan: false,
                changePlanError: action.error.message
            }
        default:
            return state
    }
}

export default subscriptionReducer