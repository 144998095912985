import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

const firebaseEnv = process.env.REACT_APP_FIREBASE_ENV;
const config = firebaseEnv === 'production' ? {
  apiKey: "AIzaSyDepP2OBuFHlJVxIQI3bWEFHHTuyElFWx4",
  authDomain: "on-personaphile-com.firebaseapp.com",
  databaseURL: "https://on-personaphile-com.firebaseio.com",
  projectId: "on-personaphile-com",
  storageBucket: "on-personaphile-com.appspot.com",
  messagingSenderId: "186874344566",
  appId: "1:186874344566:web:ff891b0ebe47e1b565db57",
  measurementId: "G-1JVMZX27N6"
} : {
  apiKey: "AIzaSyAzckOAN5b5jzQuMy1r88iriUMych-KUC4",
  authDomain: "personaphile-staging.firebaseapp.com",
  databaseURL: "https://personaphile-staging.firebaseio.com",
  projectId: "personaphile-staging",
  storageBucket: "personaphile-staging.appspot.com",
  messagingSenderId: "563800936244",
  appId: "1:563800936244:web:fb52b4525da81751ea5f61",
  measurementId: "G-2JRNEGSQG6"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;