import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
  edit: {},
  add: {},
  selected: null,
  promptedDelete: {},
  delete: {},
  undoDelete: {},
  import: {},
  negative: {},
  applied: {},
  negativeTags: {}
}

const labelReducer = (state = initState, action) => {
  let updatedLabel, addedLabel, selectedLabel, promptedDeleteLabel, deletedLabel, undeletedLabel, negativeLabels,
    savedAppliedLabels, negativeTags;
  switch ( action.type ) {
    case actions.EDIT_LABEL_START:
      updatedLabel = updateObject( state.edit, {
        [action.labelId]: updateObject( state.edit[action.labelId], {
          editingDescription: true,
          editingDescriptionError: null
        })
      });
      return {
        ...state,
        edit: updatedLabel
      }
    case actions.EDIT_LABEL_SUCCESS:
      updatedLabel = updateObject( state.edit, {
        [action.labelId]: updateObject( state.edit[action.labelId], {
          editingDescription: false,
          editingDescriptionError: null
        })
      });
      return {
        ...state,
        edit: updatedLabel
      }
    case actions.EDIT_LABEL_FAIL:
      updatedLabel = updateObject( state.edit, {
        [action.labelId]: updateObject( state.edit[action.labelId], {
          editingDescription: false,
          editingDescriptionError: action.error.message
        })
      });
      return {
        ...state,
        edit: updatedLabel
      }
    case actions.SELECTED_POST_LABEL_SET:
      selectedLabel = updateObject( state.selected, {
        id: action.id,
        related: action.related,
        posts: action.posts,
        postId: action.postId,
        personaId: action.personaId,
        personas: action.personas,
        negative: action.isNegative
      })
      return {
        ...state,
        selected: selectedLabel,
      }
    case actions.SELECTED_POST_LABEL_RESET:
      return {
        ...state,
        selected: null
      }
    case actions.ADD_LABEL_TO_PERSONA_START:
      addedLabel = updateObject( state.add, {
        [action.labelType]: updateObject( state.add[action.labelType], {
          adding: true,
          added: false,
          error: null
        })
      });
      return {
        ...state,
        add: addedLabel
      }
    case actions.ADD_LABEL_TO_PERSONA_SUCCESS:
      addedLabel = updateObject( state.add, {
        [action.labelType]: updateObject( state.add[action.labelType], {
          adding: false,
          added: true,
          newLabel: action.newData,
          error: null
        })
      });
      return {
        ...state,
        add: addedLabel
      }
    case actions.ADD_LABEL_TO_PERSONA_FAIL:
      addedLabel = updateObject( state.add, {
        [action.labelType]: updateObject( state.add[action.labelType], {
          adding: false,
          added: false,
          error: action.error.message
        })
      });
      return {
        ...state,
        add: addedLabel
      }
    case actions.PROMPTED_DELETE_LABEL:
      promptedDeleteLabel = updateObject( state.promptedDelete, {
        [action.labelId]: updateObject( state.promptedDelete[action.labelId], {
          personas: action.personasAffected
        })
      });
      return {
        ...state,
        promptedDelete: promptedDeleteLabel
      }
    case actions.DELETE_LABEL_START:
      deletedLabel = updateObject( state.delete, {
        [action.labelId]: updateObject( state.delete[action.labelId], {
          deleting: true,
          deleted: false,
          error: null
        })
      });
      return {
        ...state,
        delete: deletedLabel
      }
    case actions.DELETE_LABEL_SUCCESS:
      deletedLabel = updateObject( state.delete, {
        [action.labelId]: updateObject( state.delete[action.labelId], {
          deleting: false,
          deleted: true,
          error: null
        })
      });
      return {
        ...state,
        delete: deletedLabel
      }
    case actions.DELETE_LABEL_FAIL:
      deletedLabel = updateObject( state.delete, {
        [action.labelId]: updateObject( state.delete[action.labelId], {
          deleting: false,
          deleted: false,
          error: action.error.message,
        })
      });
      return {
        ...state,
        delete: deletedLabel
      }
    case actions.UNDO_DELETE_LABEL_START:
      undeletedLabel = updateObject( state.undoDelete, {
        [action.notifId]: updateObject( state.undoDelete[action.notifId], {
          undeleting: true,
          undeleted: false,
          error: null
        })
      });
      return {
        ...state,
        undoDelete: undeletedLabel
      }
    case actions.UNDO_DELETE_LABEL_SUCCESS:
      undeletedLabel = updateObject( state.undoDelete, {
        [action.notifId]: updateObject( state.undoDelete[action.notifId], {
          undeleting: false,
          undeleted: true,
          error: null
        })
      });
      return {
        ...state,
        undoDelete: undeletedLabel
      }
    case actions.UNDO_DELETE_LABEL_FAIL:
      undeletedLabel = updateObject( state.undoDelete, {
        [action.notifId]: updateObject( state.undoDelete[action.notifId], {
          undeleting: false,
          undeleted: false,
          error: action.error.message,
        })
      });
      return {
        ...state,
        undoDelete: undeletedLabel
      }
    case actions.IMPORT_LABELS_START:
      return {
        ...state,
        import: {
          importing: true,
          imported: false,
          error: null
        }
      }
    case actions.IMPORT_LABELS_SUCCESS:
      return {
        ...state,
        import: {
          importing: false,
          imported: true,
          error: null
        }
      }
    case actions.IMPORT_LABELS_FAIL:
      return {
        ...state,
        import: {
          importing: false,
          imported: false,
          error: action.error
        }
      }
    case actions.REGISTER_NEGATIVE_TAG:
      negativeLabels = updateObject( state.negative, {
        [action.acct]: updateObject( state.negative[action.acct], {
          [action.post]: action.labels
        })
      });
      return {
        ...state,
        negative: negativeLabels
      }
    case actions.SAVE_APPLIED_LABEL:
      savedAppliedLabels = updateObject( state.applied, {
        [action.acct]: updateObject( state.applied[action.acct], {
          [action.labelType]: action.labels
        })
      });
      return {
        ...state,
        applied: savedAppliedLabels
      }
    case actions.SAVE_NEGATIVE_TAGS:
      negativeTags = updateObject( state.negativeTags, {
        [action.acct]: updateObject( state.negativeTags[action.acct], {
          [action.tagType]: action.tags
        })
      });
      return {
        ...state,
        negativeTags
      }
    default:
      return state;
  }
}

export default labelReducer;