import { actionTypes } from 'redux-firestore';
import isEmpty from 'lodash/isEmpty';

import { asyncForEach } from '../../shared/utility';
import { updateAccount } from './accountActions';
import { getInvitationsToAccount, addCustomerProfileToPayingAccounts } from '../../shared/accounts';

export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START',
	FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS',
	FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL',
	ACCOUNTS_PAGE_LOADED = 'ACCOUNTS_PAGE_LOADED',
	GET_ACCOUNT_DATA_START = 'GET_ACCOUNT_DATA_START',
	GET_ACCOUNT_DATA_SUCCESS = 'GET_ACCOUNT_DATA_SUCCESS',
	GET_ACCOUNT_DATA_FAIL = 'GET_ACCOUNT_DATA_FAIL',
	GET_ACCOUNT_DATA_SKIP = 'GET_ACCOUNT_DATA_SKIP',
	MAKE_FREE_FLYING_START = 'MAKE_FREE_FLYING_START',
	MAKE_FREE_FLYING_SUCCESS = 'MAKE_FREE_FLYING_SUCCESS',
	MAKE_FREE_FLYING_FAIL = 'MAKE_FREE_FLYING_FAIL';

const fetchAccountsStart = () => {
	return {
		type: FETCH_ACCOUNTS_START
	};
};

const fetchAccountsSuccess = (accounts, customers, pages) => {
	return {
		type: FETCH_ACCOUNTS_SUCCESS,
		accounts,
		customers,
		pages
	};
};

const fetchAccountsFail = (err) => {
	return {
		type: FETCH_ACCOUNTS_FAIL,
		err
	};
};

export const loadAccountsPage = () => (dispatch) => {
	dispatch({ type: actionTypes.CLEAR_DATA });
};

export const fetchAccounts = () => async (dispatch, getState, { getFirebase, getFirestore }) => {
	dispatch(fetchAccountsStart());
	const firebase = getFirebase();
	const db = getFirestore();
	const functions = firebase.functions();
	const accountsRef = db.collection('accounts');
	try {
		let accounts = [],
			customers = [];
		const accountsQuery = accountsRef.orderBy('createdAt');
		const accountsSnap = await accountsQuery.get();
		await asyncForEach(accountsSnap.docs, async (doc) => {
			accounts.push(doc.id);
			const account = doc.data();
			const userRef = db.collection('users').doc(account.owners[0]);
			const owner = await userRef.get().then((doc) => ({ ...doc.data(), id: doc.id }));
			let customerEmail = owner.email;
			customers.push({
				accountId: doc.id,
				email: customerEmail
			});

			// If paying accounts do not have Customer Profile (customerProfile inside subscription), give it one
			const subscription = account.subscription;
			if (subscription && subscription.customerId && !subscription.customerProfile) {
				addCustomerProfileToPayingAccounts(subscription.customerId, doc.id, account.name, functions, db);
			}
		});
		dispatch(fetchAccountsSuccess(accounts, customers));
	} catch (error) {
		// console.log(error);
		dispatch(fetchAccountsFail(error));
	}
};

const getAccountDataStart = (accountId) => {
	return {
		type: GET_ACCOUNT_DATA_START,
		accountId
	};
};

const getAccountDataSkip = (accountId) => {
	return {
		type: GET_ACCOUNT_DATA_SKIP,
		accountId
	};
};

const getAccountDataSuccess = (accountId, data) => {
	return {
		type: GET_ACCOUNT_DATA_SUCCESS,
		accountId,
		data
	};
};

const getAccountDataFail = (accountId, error) => {
	return {
		type: GET_ACCOUNT_DATA_FAIL,
		accountId,
		error
	};
};

export const getAccountData = ({ accountId, customer, subscriptionId, currentPlan, switchingPlan, checkoutId }) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		dispatch(getAccountDataStart(accountId));
		const accountData = getState().accountManagement.accounts[accountId];
		const firebase = getFirebase();
		const firestore = getFirestore();
		const functions = firebase.functions();
		try {
			if (accountData && !isEmpty(accountData.data && !switchingPlan)) {
				dispatch(getAccountDataSkip(accountId));
				return;
			}
			let subscription = 'egg',
				paidInvoices,
				upcomingInvoice,
				paymentMethods,
				checkout;
			if (currentPlan !== 'egg' && customer && subscriptionId) {
				const getSubscription = functions.httpsCallable('fetchAccountSubscription');
				const subscriptionData = await getSubscription({ subscriptionId, all: true });
				subscription = subscriptionData.data;

				const countAllPaidCustomerInvoices = functions.httpsCallable('listAllPaidCustomerInvoices');
				const paidInvoicesData = await countAllPaidCustomerInvoices({ customer });
				paidInvoices = paidInvoicesData.data;

				const fetchUpcomingCustomerInvoice = functions.httpsCallable('fetchUpcomingCustomerInvoice');
				const upcomingInvoiceData = await fetchUpcomingCustomerInvoice({ customer });
				upcomingInvoice = upcomingInvoiceData.data;

				const getCustomerPaymentMethods = functions.httpsCallable('getCustomerPaymentMethods');
				const customerPaymentMethods = await getCustomerPaymentMethods(customer);
				paymentMethods = customerPaymentMethods.data;

				if (!checkoutId) {
					// This is before changes are made in the subscription data of account docs
					// Before customerProfile & checkoutId is implemented
					// Query for account ID in planCheckouts collection
					const planCheckoutsRef = firestore.collection('planCheckouts');
					const planCheckoutsQuery = planCheckoutsRef
						.where('accountId', '==', accountId)
						.orderBy('created', 'desc')
						.limit(1);
					const planCheckoutsSnap = await planCheckoutsQuery.get();
					planCheckoutsSnap.forEach((checkoutDoc) => {
						checkout = { id: checkoutDoc.id, ...checkoutDoc.data() };
					});
				}
			}

			const invitations = await getInvitationsToAccount(accountId, firestore);

			dispatch(
				getAccountDataSuccess(accountId, {
					subscription,
					paidInvoicesNum: paidInvoices,
					upcomingInvoice: upcomingInvoice,
					paymentMethods,
					invitations,
					checkout
				})
			);
		} catch (error) {
			dispatch(getAccountDataFail(accountId, error));
		}
	};
};

const makeFreeFlyingStart = (accountId) => {
	return {
		type: MAKE_FREE_FLYING_START,
		accountId
	};
};

const makeFreeFlyingSuccess = (accountId) => {
	return {
		type: MAKE_FREE_FLYING_SUCCESS,
		accountId
	};
};

const makeFreeFlyingFail = (error, accountId) => {
	return {
		type: MAKE_FREE_FLYING_FAIL,
		accountId,
		error
	};
};

export const makeFreeFlyingPlan = ({ subscriptionId, accountId }) => async (
	dispatch,
	getState,
	{ getFirebase, getFirestore }
) => {
	try {
		dispatch(makeFreeFlyingStart(accountId));
		const firebase = getFirebase();
		const firestore = getFirestore();
		const functions = firebase.functions();
		const freeFlyingPlanId = process.env.REACT_APP_FREE_FLYING_ID;
		let freeFlyingSubscription;

		if (subscriptionId !== 'egg') {
			const switchSubscriptionPlan = functions.httpsCallable('switchSubscriptionPlan');
			freeFlyingSubscription = await switchSubscriptionPlan({ subscriptionId, planId: freeFlyingPlanId });
		}
		// Update account subscription
		await updateAccount(
			firestore,
			{
				'subscription.billingFrequency': 'yearly',
				'subscription.plan': 'flying',
				'subscription.upgradeDate': firestore.FieldValue.serverTimestamp(),
				'subscription.freeFlying': true,
				'subscription.trialExpiration': null,
				'subscription.trialStarted': null
			},
			accountId
		);

		dispatch(
			getAccountData({
				accountId,
				customer: subscriptionId !== 'egg' ? freeFlyingSubscription.data.customer : freeFlyingSubscription,
				subscriptionId,
				currentPlan: 'flying',
				switchingPlan: true
			})
		);
		dispatch(makeFreeFlyingSuccess(accountId));
	} catch (error) {
		dispatch(makeFreeFlyingFail(error));
	}
};
