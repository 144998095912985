import * as actions from '../actions/actionTypes';
import isEqual from 'lodash/isEqual';

export const addActivity = ({
    account,
    type,
    by,
    activityData,
    notify, // Boolean
    toNotify, // array of users to be notified
}) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        const activityCol = `${account}_activities`;

        firestore.collection(activityCol).add({
            type,
            by,
            activityData,
            asNotification: notify || false,
            toNotify: toNotify || 'all',
            seenBy: [],
            active: true,
            createdOn: firestore.FieldValue.serverTimestamp(),
            lastUpdated: firestore.FieldValue.serverTimestamp(),
        }).then(doc => {
            dispatch({
                type: actions.ADD_ACTIVITY,
                activityType: type
            })
        }).catch(err => {
            console.log(`Error adding activity: ${type}`, err.message)
        })
    }
}

export const notificationSeen = ({
    activityId,
    account,
    seenBy,
    type
}) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        const activityCol = `${account}_activities`;
        const notifRef = firestore.collection(activityCol).doc(activityId);
        notifRef.get().then(doc => {
            if (doc.exists) {
                const activityDoc = doc.data();
                notifRef.update({
                    seenBy: firestore.FieldValue.arrayUnion(seenBy),
                    active: !isEqual(activityDoc.toNotify, [...activityDoc.seenBy, seenBy]),
                    lastUpdated: firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    dispatch({
                        type: actions.EDIT_ACTIVITY,
                        activityType: type
                    })
                })
            }
        })
    }
}