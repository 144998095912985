import React from 'react';
import { Textfit } from 'react-textfit';
import tinycolor from 'tinycolor2';

import './Avatar.css';
import { getInitials, joinClasses, getColorByString } from '../../../shared/utility';


const avatar = ({size, name, initials, color, src, className, style}) => {
  const imageStyle = {
    display: 'block',
    borderRadius: '100%'
  };

  const newSize = src ? size : size * 0.8;
  const padding = src ? size : size * 0.2;

  const innerStyle = {
    lineHeight: newSize + 'px',
    textAlign: 'center',
    borderRadius: '100%'
  };

  if (size) {
    innerStyle.padding = src ? 0 : padding/2+'px';
    innerStyle.boxSizing = 'content-box';
    imageStyle.width = innerStyle.width = innerStyle.maxWidth = newSize+'px';
    imageStyle.height = innerStyle.height = innerStyle.maxHeight = newSize+'px';
    // innerStyle.width = '100%';
    // innerStyle.height = 'auto';
  }

  let inner, classes = [className, 'UserAvatar'], text = initials || getInitials(name);

  if (src) {
    inner = <img className="UserAvatar--img" style={imageStyle} src={src} alt={name} />
  }
  else {
    let background;
    if (color) {
      background = color;
    }
    else {
      background = tinycolor(getColorByString(name || text));
    }

    innerStyle.backgroundColor = background;

    if (innerStyle.backgroundColor) {
      classes.push(`UserAvatar--${tinycolor(innerStyle.backgroundColor).isDark() ? 'dark' : 'light'}`);
    }

    inner = <Textfit mode="single" forceSingleModeWidth={false}>{text}</Textfit>;
  }

  return (
    <div aria-label={name} className={joinClasses(classes)} style={style}>
      <div className="UserAvatar--inner" style={innerStyle}>
        {inner}
      </div>
    </div>
  )
}

export default avatar;