import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Logo from '../../../components/UI/Logo/Logo';
import classes from './AltLayout.module.scss';
import { joinClasses } from '../../../shared/utility';

const altLayout = ( props ) => {
	return (
			<Container fluid>
				<Row className={joinClasses([classes.AltLayout, 'align-items-center justify-content-center'])}>
					<Col></Col>
					<Col sm={12} md={7} lg={props.wide ? 10 : 6} className="d-flex flex-wrap flex-column mb-3">
						<Logo className='align-self-center' />
						<main className='align-self-center d-flex flex-wrap flex-column'>
							{props.children}
						</main>
					</Col>
					<Col></Col>
				</Row>
			</Container>
		)
}

export default altLayout;