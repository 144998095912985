export const functionsErrorHandling = err => {
    let response;
    try {
        const rawResponse = JSON.parse(((err.message.split(/Raw server response:/)[1]).trim()).replace(/^"(.*)"$/, '$1'));
        response = rawResponse.error.message
    } catch (error) {
        console.log('Can\'t extract error message: ', err.message);
        response = 'Internal error. Contact support.';
    }

    return response;
}