import React, { Component } from 'react';

import classes from './MenuToggle.module.css';
import { joinClasses } from '../../../shared/utility'

class MenuToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(e);
  }

  render() {
    return (
      <span onClick={this.handleClick} className={joinClasses([
        classes.MenuToggle,
        this.props.dark ? 'text-primary' : null,
        this.props.light ? 'text-white' : null
      ])}>
        {this.props.children}
      </span>
    );
  }
}

export default MenuToggle;