import React, { Component, Fragment } from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import classes from './ErrorBoundary.module.css'
import Navbar from 'react-bootstrap/Navbar'

import Brand from '../../Nav/NavBar/Brand';
import cls from '../../Nav/NavBar/NavBar.module.scss';

class ErrorBoundary extends Component {
    state = { 
        error: null, 
        errorInfo: null 
    }
    

    componentDidCatch(error, info) {
        this.setState({
            error: error,
            errorInfo: info
        // }, () => setTimeout(() => { window.location = "/" }, 2000))
        })
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Fragment>
                    <Navbar variant="dark" className={cls.Navbar}>
                        <Brand />
                    </Navbar>
                    <Jumbotron fluid className={classes.ErrorMessage}>
                        <Container>
                            <h1>Sorry, something went wrong.</h1>
                            <h5 className="my-3">There was an unexpected error. We are reloading the page for you.</h5>
                            <hr className="my-4"/>
                            <p className="lead font-weight-bold">Error: {this.state.error.message}</p>
                        </Container>
                    </Jumbotron>
                </Fragment>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary
