import firebase from '../config/firebase';
import moment from 'moment';

export const logSearchIndex = async (acctId, type) => {
    try {
        const db = firebase.firestore()
        let searchIndexData;
        let searchIndexRef = db.collection("search_indices")
        const query = searchIndexRef.where("account", "==", acctId).where("contentType", "==", type)
        const searchIndexRes = await query.get().then(querySnapshot => querySnapshot)
        if (searchIndexRes.empty) {
            searchIndexRef = db.collection("search_indices").doc()
            searchIndexData = {
                account: acctId,
                contentType: type,
                indexedAt: null,
                lastTypeActivity: firebase.firestore.FieldValue.serverTimestamp(),
            }
        } else {
            searchIndexRes.forEach(doc => {
                searchIndexRef = db.collection("search_indices").doc(doc.id)
                searchIndexData = {
                    lastTypeActivity: firebase.firestore.FieldValue.serverTimestamp(),
                }
            })
        }
        await searchIndexRef.set(searchIndexData, { merge: true })
        // console.log(`Search index for ${type} logged.`)
    } catch (error) {
        console.error('We have error:', error.message)
    }
}

export const getIndexableContents = async acctId => {
    try {
        const db = firebase.firestore()
        let searchDataToIndex = [];
        let indices = [];
        let searchIndexRef = db.collection("search_indices")
        const query = searchIndexRef.where("account", "==", acctId)
        const searchIndexRes = await query.get().then(querySnapshot => querySnapshot)
        if (!searchIndexRes.empty) {
            searchIndexRes.forEach(doc => {
                const searchIndex = doc.data();
                const lastTypeActivity = searchIndex.lastTypeActivity;
                const indexedAt = searchIndex.indexedAt;
                const contentType = searchIndex.contentType;
                indices = [...indices, contentType];
                // console.log(`${contentType}: Last activity: ${moment(lastTypeActivity.toDate())} | Indexed at: ${moment(indexedAt.toDate())}`)
                if (!indexedAt) {
                    searchDataToIndex = [...searchDataToIndex, contentType]
                } else if (moment(lastTypeActivity.toDate()).isAfter(moment(indexedAt.toDate()))) {
                    searchDataToIndex = [...searchDataToIndex, contentType]
                }
            })
        }
        return {
            count: searchIndexRes.size,
            indicesToUpdate: searchDataToIndex,
            indices
        }
    } catch (error) {
        console.error('We have error:', error.message)
        return {
            error: error.message
        }
    }
}

export const updateSearchIndexLog = async (acctId, type) => {
    try {
        const db = firebase.firestore()
        let searchIndexData;
        let searchIndexRef = db.collection("search_indices")
        const query = searchIndexRef.where("account", "==", acctId).where("contentType", "==", type)
        const searchIndexRes = await query.get().then(querySnapshot => querySnapshot)
        searchIndexRes.forEach(doc => {
            searchIndexRef = db.collection("search_indices").doc(doc.id)
            searchIndexData = {
                indexedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
        })
        await searchIndexRef.set(searchIndexData, { merge: true })
        // console.log(`Search index for ${type} updated.`)
    } catch (error) {
        console.error('We have error:', error.message)
    }
}

export const searchableAttributes = {
    'personas': [
        'name',
        'description',
        'gender',
        'location',
        'roles',
        'religion',
        'goals',
        'work',
        'interests',
        'challenges',
        'householdComposition',
        'relationshipStatus',
        'highestLevelOfEducation',
    ],
    'discussions': [
        'tags'
    ],
    'interests': [
        'label',
        'value',
        'description',
    ],
    'challenges': [
        'label',
        'value',
        'description',
    ],
    'solutions': [
        'label',
        'value',
        'description',
    ],
    'contexts': [
        'value',
    ],
    'actions': [
        'value',
    ],
    'thoughts': [
        'value',
    ],
    'influences': [
        'value',
    ],
    'comments': [
        'comment'
    ]
}