import * as actions from '../actions/messageActions';

const initState = {
    registrationInvalid: false
}

const messageReducer = (state = initState, action) => {
    switch ( action.type ) {
        case actions.SHOW_REGISTRATION_EXPIRED:
            return {
                ...state,
                registrationInvalid: action.set
            }
        default:
            return state;
    }
}

export default messageReducer;