import React from 'react';
import classes from './Logo.module.scss';

import logoColor from '../../../assets/images/personafx_logo-color.png';

const logo = () => (
  <span className={classes.Logo}>
    <img alt="Persona 𝘧𝘹™" src={logoColor} className="d-inline-block align-top"/>
  </span>
);

export default logo;