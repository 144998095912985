import * as actions from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
    index: {},
    searchFilterOpen: false,
    search: {},
}

const searchInit = {
    searchKeyword: '',
    filters: {
        any: [],
        required: [],
        exclude: [],
        personaStatus: 'active',
        includeChildren: false
    },
    searching: false,
    searched: false,
    result: [],
    hasSearched: false
}

const searchReducer = (state = initState, action) => {
    let updatedAccountIndex, updatedSearch;
    switch ( action.type ) {
        case actions.INDEX_ACCOUNT_DATA_START:
            updatedAccountIndex = updateObject( state.index, {
                [action.acct]: updateObject( state.index[action.acct], {})
            });
            return {
                ...state,
                index: updatedAccountIndex
            }
        case actions.INDEX_ACCOUNT_DATA_SUCCESS:
            updatedAccountIndex = updateObject( state.index, {
                [action.acct]: updateObject( state.index[action.acct], {
                    ...action.data
                })
            });
            return {
                ...state,
                index: updatedAccountIndex
            }
        case actions.INDEX_ACCOUNT_DATA_FAIL:
            updatedAccountIndex = updateObject( state.index, {
                [action.acct]: updateObject( state.index[action.acct], {
                    error: action.error
                })
            });
            return {
                ...state,
                index: updatedAccountIndex
            }
        case actions.INITIALIZE_ACCT_SEARCH:
            updatedSearch = updateObject( state.search, {
                [action.acct]: searchInit
            });

            return {
                ...state,
                search: updatedSearch,
                hasSearched: false
            }
        case actions.TOGGLE_SEARCH_FILTER:
            return {
                ...state,
                searchFilterOpen: !state.searchFilterOpen
            }
        case actions.CLOSE_SEARCH_FILTER:
            return {
                ...state,
                searchFilterOpen: false
            }
        case actions.INPUT_SEARCH_KEYWORD:
            updatedSearch = updateObject( state.search, {
                [action.acct]: updateObject( state.search[action.acct], {
                    searchKeyword: action.keyword,
                })
            });
            return {
                ...state,
                search: updatedSearch
            }
        case actions.INPUT_SEARCH_FILTER:
            updatedSearch = updateObject( state.search, {
                [action.acct]: updateObject( state.search[action.acct], {
                    filters: updateObject(state.search[action.acct].filters, {
                        [action.field]: action.filters
                    })
                })
            });
            return {
                ...state,
                search: updatedSearch
            }
        case actions.SEARCH_START:
            updatedSearch = updateObject( state.search, {
                [action.acct]: updateObject( state.search[action.acct], {
                    searching: true,
                    searched: false,
                })
            });
            return {
                ...state,
                search: updatedSearch
            }
        case actions.SEARCH_SUCCESS:
            updatedSearch = updateObject( state.search, {
                [action.acct]: updateObject( state.search[action.acct], {
                    searching: false,
                    searched: true,
                    result: action.searchResult,
                    hasSearched: true
                })
            });
            return {
                ...state,
                search: updatedSearch,
                searchFilterOpen: false,
            }
        default:
            return state;
    }
}

export default searchReducer;