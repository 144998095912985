import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/authActions';
import { withRouter, Switch, Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { ThreeBounce } from 'better-react-spinkit';
import { PhoneScreen, PhoneScreenHidden } from 'react-responsive-redux';
import isBlank from 'lodash/isEmpty';

import Brand from './Brand';
import classes from './NavBar.module.scss';
import Avatar from '../../UI/Avatar/Avatar';
import Wrap from '../../../hoc/Wrap/Wrap';
import MenuToggle from '../../UI/MenuToggle/MenuToggle';
import { joinClasses } from '../../../shared/utility';
import { userIsOwnerOrEditor, userIsAccountOwner } from '../../../shared/userManagement';
import UserMgntLink from './UserMngtLink';
import LabelsMngtLink from './LabelsMngtLink';
import ManageFeaturesLink from './ManageFeaturesLink';
import './NavBar.styles.scss'

const menuPreloader = () => (
  <Nav className="ml-auto">
    <Nav.Item>
      <ThreeBounce color="#aaa" />
    </Nav.Item>
  </Nav>
)

const label = (name, initials, color, pic) => (
  <Wrap>
    <Avatar name={name} initials={initials} size={28} color={color} src={pic} />
    <PhoneScreenHidden>
      <span className="ml-2 text-light">{name}</span>
    </PhoneScreenHidden>
  </Wrap>
);

const accountLabel = ({name, initials, id, color, pictureURL, pictureFilename}) => {
  const pic = isBlank(pictureFilename) ? null : pictureURL
  return (
    <Switch>
      <Route
        path="/persona-file/:id/"
        strict
        render={ ({history, match}) => (
          <span onClick={() => history.push(`/persona-file/${match.params.id}`)}
            className={joinClasses([classes.AccountLabelBack, 'd-flex align-items-center'])}>
            { label(name, initials, color, pic) }
          </span>
        )} />
      <Route
        path="/persona-file/:id"
        render={ () => (
          label(name, initials, color, pic)
        )} />
    </Switch>
  );
}

class NavBar extends Component {
  state = {
    userMenuIsOpen: false
  }

  userMenuToggled = isOpen => this.setState({userMenuIsOpen: isOpen});

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.state.userMenuIsOpen) {
        this.setState({ userMenuIsOpen: false })
      }
    }
  }

  inAccountPage = () => this.props.match.path !== '/' && this.props.match.path !== '/accounts'
  
  render() {
    let account;
    let toolbar;
    if (this.props.notReady) {
      toolbar = menuPreloader();
    } else if (!this.props.currentAccount && this.inAccountPage()) {
      toolbar = menuPreloader();
    } else if (!this.props.auth.isLoaded) {
      toolbar = menuPreloader();
    } else {
      const userIsAdmin = userIsOwnerOrEditor(this.props.auth.uid, this.props.currentAccount);
      const userIsOwner = userIsAccountOwner(this.props.auth.uid, this.props.currentAccount)
      account = this.inAccountPage() ? accountLabel(this.props.currentAccount) : null;

      toolbar = (
        <Nav className="ml-auto">
          <Nav.Item className={['d-flex align-items-center mr-sm-1 mr-md-3']}>
            { account }
          </Nav.Item>
          <PhoneScreen>
            <Nav.Item className={['d-flex align-items-center mr-1']}>
              <NavLink to='/' className="nav-link d-flex align-items-center">
                <span><FontAwesomeIcon icon="home" size="lg"/></span>
              </NavLink>
            </Nav.Item>
          </PhoneScreen>
          <PhoneScreenHidden>
            <Nav.Item className={['d-flex align-items-center mr-3']}>
              <NavLink to='/' className="nav-link d-flex align-items-center">
                <span style={{fontSize:'1.25rem'}} className='mr-2'>
                  <FontAwesomeIcon icon="home" size="sm"/>
                </span>
                Dashboard
              </NavLink>
            </Nav.Item>
          </PhoneScreenHidden>
          <Nav.Item className={['d-flex align-items-center']}>
            <Dropdown
              onToggle={(isOpen) => this.userMenuToggled(isOpen)}
              show={this.state.userMenuIsOpen}>
              <Dropdown.Toggle as={MenuToggle} id="user-menu-dropdown" light>
                <FontAwesomeIcon icon="cog" size="lg"/>
              </Dropdown.Toggle>
              { this.state.userMenuIsOpen ?
                <Dropdown.Menu alignRight>
                  <Dropdown.Item as="div" className={joinClasses(['d-flex align-items-center', classes.UserNameMenu])}>
                    <Avatar
                      name={ this.props.profile.screenName || this.props.auth.email }
                      size={50}
                      color={this.props.profile.color} />
                    <span className="ml-2">{this.props.profile.screenName || this.props.auth.email }</span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <ManageFeaturesLink
                     isOwner={userIsOwner}
                     match={{...this.props.match}}/>
                  <UserMgntLink
                     isAdmin={userIsAdmin}
                     match={{...this.props.match}}/>
                  <LabelsMngtLink
                    isAdmin={userIsAdmin}
                    match={{...this.props.match}}/>
                  { this.props.currentAccount && <Dropdown.Divider />}
                  { this.props.profile.superadmin &&
                    <Dropdown.Item 
                      as={NavLink} 
                      to="/accounts" 
                      className={joinClasses([
                        classes.DropdownMenuItem, 'nav-link'
                      ])}>Manage Accounts
                    </Dropdown.Item>
                  }
                  <Dropdown.Item as={Nav.Link} onClick={() => this.props.signOut(this.props.auth.email)} className={classes.DropdownMenuItem}>Log out</Dropdown.Item>
                </Dropdown.Menu> : null 
              }
            </Dropdown>
          </Nav.Item>
        </Nav>
      );
    }
    return (
      <Navbar variant="dark" sticky="top" className={classes.Navbar}>
        <Brand />
        {this.props.activePersona && <span className={classes.ActivePersona}>{this.props.activePersona}</span>}
        {toolbar}
      </Navbar>
    );
  }
}

const mapStateToProps = ( state ) => {
  try {
    const currentAcct = state.account.account;
    const activePersona = state.persona.active ? state.firestore.data.personas[state.persona.active] : {name: null}
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      currentAccount: currentAcct ? state.firestore.data.accountActive : currentAcct,
      color: state.auth.color,
      activePersona: activePersona.name
    }
  } catch (error) {
    return {
      notReady: true
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: email => dispatch(signOut(email))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));