const plan = {
    20191009: {
        egg: {
            name: 'Egg',
            trialDays: 14,
            ccReq: false,
            price: 0,
            usersLimit: 3,
            personasLimit: 3,
            archivedPersonasLimit: 0,
            convosLimit: 6,
            deletePersona: false,
            archivePersona: false,
            restorePersona: false,
            historyLimitDays: 0,
            yearly: {
                price: 0
            }
        },
        fledging: {
            name: 'Fledging',
            trialDays: 14,
            ccReq: true,
            price: 70,
            usersLimit: 8,
            personasLimit: 12,
            archivedPersonasLimit: 0,
            convosLimit: 120,
            convosPerPersona: 10,
            deletePersona: true,
            archivePersona: false,
            restorePersona: false,
            historyLimitDays: 45,
            planId: process.env.REACT_APP_FLEDGING_ID,
            yearly: {
                planId: process.env.REACT_APP_FLEDGING_ANNUAL_ID,
                price: 59
            },
            // free: process.env.REACT_APP_FREE_FLYING_ID
        },
        flying: {
            name: 'Flying',
            trialDays: 14,
            ccReq: true,
            price: 180,
            usersLimit: 28,
            personasLimit: 30,
            archivedPersonasLimit: 45,
            convosLimit: 1200,
            convosPerPersona: 40,
            deletePersona: true,
            archivePersona: true,
            restorePersona: true,
            historyLimitDays: false,
            planId: process.env.REACT_APP_FLYING_ID,
            yearly: {
                planId: process.env.REACT_APP_FLYING_ANNUAL_ID,
                price: 153
            },
            free: process.env.REACT_APP_FREE_FLYING_ID
        },
        soaring: {
            name: 'Soaring',
            trialDays: 0,
            ccReq: false,
            price: null,
            usersLimit: 999999999,
            personasLimit: 999999999,
            archivedPersonasLimit: 999999999,
            convosLimit: 999999999,
            deletePersona: true,
            archivePersona: true,
            restorePersona: true,
            historyLimitDays: false,
            yearly: {
                price: null
            }
        }
    },
    'test': {
        egg: {
            name: 'Egg',
            trialDays: 14,
            ccReq: false,
            price: 0,
            usersLimit: 3,
            personasLimit: 3,
            archivedPersonasLimit: 0,
            convosLimit: 6,
            deletePersona: false,
            archivePersona: false,
            restorePersona: false,
            historyLimitDays: 0,
            yearly: {
                price: 0
            }
        },
        fledging: {
            name: 'Fledging',
            trialDays: 14,
            ccReq: true,
            price: 70,
            usersLimit: 4,
            personasLimit: 4,
            archivedPersonasLimit: 0,
            convosLimit: 8,
            convosPerPersona: 2,
            deletePersona: true,
            archivePersona: false,
            restorePersona: false,
            historyLimitDays: 45,
            planId: process.env.REACT_APP_FLEDGING_ID,
            yearly: {
                planId: process.env.REACT_APP_FLEDGING_ANNUAL_ID,
                price: 59
            }
        },
        flying: {
            name: 'Flying',
            trialDays: 14,
            ccReq: true,
            price: 180,
            usersLimit: 5,
            personasLimit: 5,
            archivedPersonasLimit: 1,
            convosLimit: 15,
            convosPerPersona: 3,
            deletePersona: true,
            archivePersona: true,
            restorePersona: true,
            historyLimitDays: false,
            planId: process.env.REACT_APP_FLYING_ID,
            yearly: {
                planId: process.env.REACT_APP_FLYING_ANNUAL_ID,
                price: 153
            },
            free: process.env.REACT_APP_FREE_FLYING_ID

        },
        soaring: {
            name: 'Soaring',
            trialDays: 0,
            ccReq: false,
            price: null,
            usersLimit: 999999999,
            personasLimit: 999999999,
            convosLimit: 999999999,
            archivedPersonasLimit: 999999999,
            deletePersona: true,
            archivePersona: true,
            restorePersona: true,
            historyLimitDays: false,
            yearly: {
                price: null
            }
        }
    }
}

export default plan;